import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import PromoCodeTable from "./section/PromoCodeTable";
import AddPromoCode from "./section/AddPromoCode";
import connection from '../auth/api';

const PromoCodePage = () => {

    const [promoCodes, setPromoCodes] = useState([]);

    useEffect(() => {
        connection.get('/get/promoCodes').then(res => {
            setPromoCodes(res.data.data)
        })
    }, []);


    const useStyles = makeStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .table-con': {
                width: '58%',
            },
            '& .input-con': {
                width: '35%',
            },
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={'table-con'}>
                <PromoCodeTable promoCodes={promoCodes} setPromoCodes={setPromoCodes} />
            </div>
            <div className="input-con">
                <AddPromoCode setPromoCodes={setPromoCodes} />
            </div>
        </div>
    );
};

export default PromoCodePage;
