import React, {useEffect, useState} from 'react';
import {makeStyles, useMediaQuery} from "@material-ui/core";
import HomeSideDrawer from "./sections/HomeSideDrawer";
import AppContainer from "./sections/AppContainer";
import {Drawer} from "antd";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setOrders} from "../../redux/slice/OrderSlice";

const Home = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    // const is430pxBelow = useMediaQuery('(max-width:430px)')
    // const is380pxBelow = useMediaQuery('(max-width:380px)')

    const drawerWidth = is1025pxBelow ? (is770pxBelow ? 300 : 240) : 280;

    const [drawerOpen, setDrawerOpen] = useState(true);

    const useStyles = makeStyles(() => ({
        container: {
            '& .side-drawer':{
                position: 'fixed',
                left: drawerOpen ? 0 : -drawerWidth,
                width: drawerWidth,
                height: '100%',
                transition: 'all .5s',
            },
            '& .app-container':{
                transition: 'all .5s',
                marginLeft: !is770pxBelow ? (drawerOpen ? drawerWidth : 0) : 0,
                width: `calc(100% - ${drawerWidth})`,
            },
        }
    }))

    const classes = useStyles();

    const onDrawerClose = () => {
      setDrawerOpen(false)
    }



    return (
        <div className={classes.container}>
            {
                !is770pxBelow ?
                    <div className={'side-drawer'}>
                        <HomeSideDrawer setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen}/>
                    </div> :
                    <Drawer
                        placement={'left'}
                        closable={false}
                        onClose={onDrawerClose}
                        bodyStyle={{padding: '0px'}}
                        visible={drawerOpen}
                        key={'left'}
                    >
                        <HomeSideDrawer setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen}/>
                    </Drawer>

            }
            <div className={'app-container'}>
                <AppContainer setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen}/>
            </div>

        </div>
    );
};

export default Home;
