import React, { useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { AiOutlineEye, BiCheck, IoCloseSharp, RiDeleteBin6Line } from "react-icons/all";
import TablePagination from "@material-ui/core/TablePagination";
import { useHistory } from "react-router-dom";
import { getDeliveryStatus, getDeliveryStatusColor } from "../../../helpers/HelperFunctions";

const OrdersTable = ({ filteredData, orders, page, setPage, rowsPerPage, setRowsPerPage }) => {

    const columns = [
        {
            name: 'S.No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Email',
            align: 'center',
        },
        {
            name: 'Phone Number',
            align: 'center',
        },
        {
            name: 'Ordered Date',
            align: 'center',
        },
        {
            name: 'Status',
            align: 'center',
        },
        {
            name: 'Address',
            align: 'center',
        },
        {
            name: 'View',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '250px',
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            '& .view-icon': {
                fontSize: is430pxBelow ? '18px' : '24px',
                color: Colors.darkText,
            },
        },
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const displayingOrders = filteredData.length !== 0 ? filteredData : []

    const history = useHistory();
    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayingOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const orderedAt = new Date(row.orderedAt)
                            const address = row?.shippingAddress === "random address" ? "-" : `${row?.shippingAddress?.doorNumber} ${row.shippingAddress?.streetName}, ${row.shippingAddress?.landMark}, ${row.shippingAddress?.cityName} - ${row.shippingAddress?.pincode}, ${row.shippingAddress?.stateName}`
                            console.log(address, "addressaddressaddress");
                            return (
                                <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{page * rowsPerPage + index + 1}</h6>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{row.shippingAddress.name}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 style={{ margin: '0 auto' }} className={classes.rowTextStyle}>{row.shippingAddress.email}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 style={{ margin: '0 auto' }} className={classes.rowTextStyle}>{row.shippingAddress.contactNumber}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 style={{ margin: '0 auto' }} className={classes.rowTextStyle}>{orderedAt.toDateString()} {orderedAt.toLocaleTimeString()}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 style={{ margin: '0 auto', color: getDeliveryStatusColor(row.deliveryStatus) }} className={classes.rowTextStyle}>{getDeliveryStatus(row.deliveryStatus)}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 style={{ margin: '0 auto' }} className={classes.rowTextStyle}>{address ? address : '-'}</h6>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <div className={classes.actionContainer}>
                                            <AiOutlineEye className={'view-icon'} onClick={() => history.push(`/order/${row._id}`)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                displayingOrders.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={displayingOrders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Paper>
    );
};

export default OrdersTable;
