import React from 'react';
import pageLoader from 'assets/gifs/PageLoader.gif'
import {makeStyles} from "@material-ui/core/styles";

const PageLoader = () => {

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100%',
            '& img':{
                width: '350px'
            }
        }
    }))

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <img src={pageLoader} alt={'Page Loader'}/>
        </div>
    );
};

export default PageLoader;
