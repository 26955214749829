import React from 'react';

const Sales = () => {
    return (
        <div>
            Sales
        </div>
    );
};

export default Sales;