import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { FiCheck, FiImage, MdAdd } from "react-icons/all";
import Paper from "@material-ui/core/Paper";
import { Colors } from "../../../helpers/Color";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ProductsTable = ({
    filteredData,
    dbProducts,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
    setLoading
}) => {
    console.log(filteredData, "filteredDatassss", dbProducts);

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Manufacturer Name',
            align: 'center',
        },
        {
            name: 'Total Stocks',
            align: 'center',
        },
        {
            name: 'MRP',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setCurrentPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(+event.target.value);
        setCurrentPage(1);
    };

    const dbProductsRedux = useSelector((state) => state.Products.value)

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 620,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px'
        },
        tableImage: {
            height: is430pxBelow ? '60px' : '75px'
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .add-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary,
                cursor: 'pointer'
            },
            '& .check-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.success,
                cursor: 'pointer'
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();
    const history = useHistory();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const products = filteredData.length !== 0 ? filteredData : dbProductsRedux

    console.log(products, "productsproducts");

    const Row = ({ row, index }) => {
        const [isAdded, setIsAdded] = useState(false);

        useEffect(() => {
            let isAddedTemp = false;
            dbProducts.forEach(item => {
                if (item.name === row.ProductName) {
                    isAddedTemp = true
                }
            })
            setIsAdded(isAddedTemp)
        }, [row.ProductName, dbProducts])

        return (
            <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row.ProductName}>
                <TableCell align={'left'}>
                    <h6 className={classes.rowTextStyle}>{index + 1}</h6>
                </TableCell>
                <TableCell align={'left'}>
                    <h6 className={classes.rowTextStyle}>{row.ProductName}</h6>
                </TableCell>
                <TableCell align={'center'}>
                    <h6 className={classes.rowTextStyle} style={{ marginLeft: 'auto', marginRight: 'auto' }}>{row.ManufacturerName}</h6>
                </TableCell>
                <TableCell align={'center'}>
                    <h6 className={classes.rowTextStyle}>{row.TotalStock}</h6>
                </TableCell>
                <TableCell align={'center'}>
                    <h6 className={classes.rowTextStyle}>{row.MRP}</h6>
                </TableCell>
                <TableCell align={'right'}>
                    <div className={classes.actionContainer}>
                        {
                            isAdded ?
                                <FiCheck className={'check-icon'} />
                                :
                                <MdAdd onClick={() => { history.push(`/add-product/${row.ProductName}`) }} className={'add-icon'} />
                        }
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <Row row={row} index={index} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                products.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={products.length}
                rowsPerPage={limit}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default ProductsTable;
