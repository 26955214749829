import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import UsersFilter from "./section/UsersFilter";
import UsersTable from "./section/UsersTable";
import connection from '../auth/api';

const Users = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))
    const classes = useStyles()
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        connection.get('/get/users',).then(res => {
            setUsers(res.data.data)
        })
    }, [])

    return (
        <div className={classes.container}>
            <UsersFilter users={users} setFilteredData={setFilteredData} />
            <UsersTable users={users} setFilteredData={setFilteredData} filteredData={filteredData} setUsers={setUsers} />
        </div>
    );
};

export default Users;
