import React, { useState } from 'react';
import { Switch, useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { BiEdit, } from "react-icons/all";
import TablePagination from "@material-ui/core/TablePagination";
import { useHistory } from "react-router-dom";


function PageBannerTable({ PageBanner, setPageBanner, onVisibleChange }) {

    const columns = [

        {
            name: 'Title',
            align: 'left',
        },

        {
            name: 'Descrption',
            align: 'center',
        },

        {
            name: 'Banner Page',
            align: 'center',
        },
        {
            name: 'Enable',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];



    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '250px'
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: Colors.light,
            padding: '10px',
            border: `1px solid ${Colors.lightBorder}`,
            height: is430pxBelow ? '55px' : '70px',
            width: is430pxBelow ? '55px' : '70px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
            '& .edit-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();
    const history = useHistory()

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {PageBanner.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>


                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{row.title}</h6>
                                    </TableCell>

                                    <TableCell style={{ width: '300px' }} align={'center'}>
                                        <h6 className={classes.rowTextStyle}>{row.description}</h6>
                                    </TableCell>
                                    <TableCell style={{ width: '300px' }} align={'center'}>
                                        <h6 className={classes.rowTextStyle}>{row.page}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Switch checked={row.isEnabled} color='primary' onClick={(e) => onVisibleChange(row, index, e.target.checked)} />

                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <div className={classes.actionContainer}>
                                            <BiEdit className={'edit-icon'} onClick={() => history.push(`/page-banner/edit/${row._id}`)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                PageBanner.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={PageBanner.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};
export default PageBannerTable