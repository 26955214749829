import React, { useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import CustomToast from "../../../helpers/Toast";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import { Box, useMediaQuery } from "@material-ui/core";
import JoditEditor from "jodit-react";
import VarientPage from './varientPage';
import connection from 'components/app/auth/api';

const AddShortAndLongDescription = ({ product, setProduct }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    let reactQuillRef = useRef();

    const useStyles = makeStyles(() => ({
        container: {
            marginBottom: '100px',
            '& .quill-con': {
                width: is430pxBelow ? '100%' : '50%',
                '& h5': {
                    color: Colors.primary,
                    fontSize: '20px',
                    margin: '60px 0 20px'
                },
                '& .quill': {
                    height: '150px',
                }
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& div': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: '90%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                }
            }
        }
    }))

    const classes = useStyles()

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);


    const handleShortDescriptionChange = (value) => {
        setProduct({ ...product, shortListDescription: value })
    }

    const handleLongDescriptionChange = (value) => {
        setProduct({ ...product, longListDescription: value })
    }

    const handleInstructionsChange = (value) => {
        setProduct({ ...product, instructions: value })
    }

    const handleSideEffectsChange = (value) => {
        setProduct({ ...product, sideEffects: value })
    }

    const history = useHistory();

    const onUploadClick = () => {
        const { name, description, thumbnail, galleryImages, generalCategory, generalSubCategory, salesPrice,
            specialityCategory, specialitySubCategory, purchasedPrice, departmentName, discount, unitPrice, pair, manufacturerName,
            isGeneralProduct, totalStocks, shortListDescription, longListDescription, instructions, sideEffects, isPrescriptionNeeded,
            tags, isColdChainNeeded, saltComposition, metaDescription, metaTitle, metaAltText, brand, packageSize, stockAlertThreshold, attributes, variants, productType, group, urlSlug } = product


        const isCategorySelected = isGeneralProduct ? generalCategory !== "" : specialityCategory !== ""

        if (thumbnail !== null && group !== '' && isCategorySelected) {

            // const editor = reactQuillRef.getEditor();

            // if (editor.getLength() < 320){
            setOpen(true)
            // let formData = new FormData()
            // formData.append('thumbnail', thumbnail)
            // galleryImages.forEach(gImage => {
            //     formData.append('galleryImages', gImage)
            // })

            const variantdData = variants.map(item => ({
                ...item,
                displayValues: {
                    ...item.displayValues,
                    galleryImages: item.displayValues.galleryImages.map(image => image.id)
                }
            }));



            const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
            const finalName = name.replace(regex, '');

            let data = {
                name: finalName, thumbnailUrl: thumbnail, galleryImages: galleryImages, description, purchasedPrice, isGeneralProduct, instructions, sideEffects, pair, manufacturerName, salesPrice,
                departmentName, discount, unitPrice, totalStocks, shortListDescription, longListDescription, isPrescriptionNeeded, tags, saltComposition, isColdChainNeeded, metaDescription,
                metaTitle, metaAltText, brand, packageSize, stockAlertThreshold: parseInt(stockAlertThreshold), attributes, variants: variantdData, productType, urlSlug, group
            }


            if (isGeneralProduct) {
                data = { ...data, generalCategory }
                if (generalSubCategory !== "") {
                    data = { ...data, generalSubCategory }
                }
            } else {
                data = { ...data, specialityCategory }
                if (specialitySubCategory !== "") {
                    data = { ...data, specialitySubCategory }
                }
            }
            // formData.append('data', JSON.stringify(data))
            connection.post('/post/product', { data: data }).then(res => {
                if (res.data.key === 'success') {
                    history.goBack()
                } else {
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                }
                setOpen(false)
            }).catch(err => {
                setErrorMessage('Something went wrong')
                setOpenError(true)
                setOpen(false)
            })
            // }else {
            //     setErrorMessage('Short Description is too Long')
            //     setOpenError(true)
            // }
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    const checkCharacterCount = (event) => {
        const editor = reactQuillRef.getEditor();
        if (editor.getLength() > 320 && event.key !== 'Backspace')
            event.preventDefault();
    };

    const config = useMemo(() => ({
        readonly: false,
        allowTabNavigation: true,
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false
    }), [])

    return (
        <div className={classes.container}>
            <div style={{ display: 'flex', flex: 1, gap: '45px' }}>
                <div className={'quill-con'} style={{ flex: 1 }}>
                    <h5>Short Description</h5>
                    {/*<ReactQuill*/}
                    {/*    onKeyDown={checkCharacterCount}*/}
                    {/*    ref={el => reactQuillRef = el}*/}
                    {/*    styles={{maxHeight: '200px'}}*/}
                    {/*    value={product.shortListDescription}*/}
                    {/*    onChange={handleShortDescriptionChange} />*/}
                    <JoditEditor
                        value={product.shortListDescription}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleShortDescriptionChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleShortDescriptionChange(newContent)}
                    />
                </div>
                <div className={'quill-con'}>
                    <h5 >Long Description</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}}*/}
                    {/*            value={product.longListDescription}*/}
                    {/*            onChange={handleLongDescriptionChange} />*/}
                    <JoditEditor
                        value={product.longListDescription}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleLongDescriptionChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleLongDescriptionChange(newContent)}
                    />
                </div>

            </div>
            <div style={{ display: 'flex', flex: 1, gap: '45px' }}>
                <div className={'quill-con'}>
                    <h5 >Instructions</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}}*/}
                    {/*            value={product.instructions}*/}
                    {/*            onChange={handleInstructionsChange} />*/}
                    <JoditEditor
                        value={product.instructions}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleInstructionsChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleInstructionsChange(newContent)}
                    />
                </div>
                <div className={'quill-con'}>
                    <h5 >Side Effects</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}}*/}
                    {/*            value={product.sideEffects}*/}
                    {/*            onChange={handleSideEffectsChange} />*/}
                    <JoditEditor
                        value={product.sideEffects}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleSideEffectsChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleSideEffectsChange(newContent)}
                    />
                </div>
            </div>
            <h5>SEO</h5>

            <div className={'input-container'}>
                <div style={{ display: 'flex', flex: 1, }}>

                    <div style={{ flex: 1, }}>
                        <h6>Meta Title</h6>
                        <input value={product.metaTitle} name={'metaTitle'} onChange={e => setProduct({ ...product, metaTitle: e.target.value })} />
                    </div>
                    <div style={{ flex: 1, }}>
                        <h6>Meta Description</h6>
                        <input value={product.metaDescription} name={'metaDescription'} onChange={e => setProduct({ ...product, metaDescription: e.target.value })} />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, }}>

                    <div style={{ flex: 1, }}>
                        <h6>Meta Alt Text</h6>
                        <input value={product.metaAltText} name={'metaAltText'} onChange={e => setProduct({ ...product, metaAltText: e.target.value })} />
                    </div>
                    <div style={{ flex: 1, }}>
                        <h6>Keywords</h6>
                        <input value={product.keywords} name={'keywords'} onChange={e => setProduct({ ...product, keywords: e.target.value })} />
                    </div>
                </div>
            </div>
            <VarientPage product={product} setProduct={setProduct} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
                <div className={'upload'} onClick={onUploadClick} style={{ width: '20%' }}>
                    Upload
                </div>
            </Box>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div >
    );
};

export default AddShortAndLongDescription;
