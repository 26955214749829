import axios from "axios";
import { cloudUrl } from "../../../config";
import jwtDecode from "jwt-decode";
import Store from "components/redux/Store";
import { clearProducts } from "components/redux/slice/ProductSlice";
import { logoutUser } from "components/redux/slice/IsLoginSlice";

const connection = axios.create({
    baseURL: cloudUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
    formHeaders: { "Content-Type": "multipart/form-data" },
});

connection.interceptors.request.use(
    async (config) => {
        if (await validateToken()) {
            const storeToken = localStorage.getItem('Access_Token');
            if (storeToken) {
                if (config?.data?.data instanceof FormData) {
                    config.headers = { Authorization: `JWT ${storeToken}`, ...config.formHeaders }
                } else {
                    config.headers = { Authorization: `JWT ${storeToken}`, ...config.headers }

                }
            }
        }

        return config;
    }, error => {
        return Promise.reject(error);
    });


connection.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default connection;

export async function validateToken() {
    const token = localStorage.getItem('Access_Token');
    if (!token) {
        localStorage.clear();
        Store.dispatch(clearProducts())
        Store.dispatch(logoutUser())
        return false;
    }

    const [status] = await decodeWithPub(token);
    if (status) {
        return true;
    }
    else {
        localStorage.clear();
        Store.dispatch(clearProducts())
        Store.dispatch(logoutUser())

    }
}


export async function decodeWithPub(jwtToken) {

    try {

        const payload = jwtDecode(jwtToken)
        if (payload.exp < Math.floor(Date.now() / 1000) + 1 * 60) {
            return [false, "Token will expire in 10 minutes"];
        } else {
            return [true, payload];
        }

    } catch (err) {
        if (err) {
            if (err.message === "Token Not Found") {
                return [false, "Token Expired"];
            } else {
                if (err[0] === 401) {
                    localStorage.clear();
                    Store.dispatch(clearProducts())
                    Store.dispatch(logoutUser())
                }
                return [false, err.message];
            }
        }
    }
}
