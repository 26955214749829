import React, { useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { ImSearch, IoMdClose } from "react-icons/all";
import CustomToast from "../../../helpers/Toast";
import Papa from 'papaparse'
import axios from "axios";
import connection from 'components/app/auth/api';

const PincodeHeader = ({ setFilteredData, pinCodes, setLoading, setPinCodes, handleClickOpen }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 25px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .search-bar': {
                display: 'flex',
                width: is430pxBelow ? '100%' : '50%',
                margin: is430pxBelow ? '0 0 15px' : '0',
                alignItems: 'center',
                border: `1px solid ${Colors.primary}`,
                borderRadius: '5px',
                padding: '10px',
                background: Colors.primaryLight,
                '& .icon': {
                    fontSize: '22px',
                    color: Colors.darkText
                },
                '& input': {
                    width: '100%',
                    '&:focus': {
                        outline: 0
                    },
                    margin: '0 10px',
                    border: `0`,
                    background: Colors.primaryLight,
                }
            },
            '& .btn-con': {
                display: 'flex',
                '& .add-button': {
                    background: Colors.primary,
                    color: Colors.light,
                    padding: '7px 15px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginLeft: '8px',
                    borderRadius: '5px'
                }
            }
        }
    }))

    const classes = useStyles()
    const [searchValue, setSearchValue] = useState('');

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const filteredData = pinCodes.filter(item => item.pincode.includes(searchValue))
            if (filteredData.length === 0) {
                setFilteredData([]);
                setErrorMessage('Item Not Found')
                setOpenError(true)
            } else {
                setFilteredData(filteredData);
            }
        }
    }

    const onClearClick = () => {
        setFilteredData([])
        setSearchValue('')
    }

    const onPincodeImportChange = (e) => {
        const files = e.target.files;
        if (files) {
            Papa.parse(files[0], {
                complete: onPincodeFileUploaded,
                header: true,
                skipEmptyLines: true,
            }
            )
        }
    }

    const onPincodeFileUploaded = (results) => {

        let hasError = false;
        let pincodeResults = [];

        results.data.forEach(data => {
            if (data.hasOwnProperty('Pincodes')) {
                if (data.Pincodes.length !== 6) {
                    hasError = true;
                } else {
                    pincodeResults.push({ pincode: data.Pincodes })
                }
            } else {
                hasError = true;
            }
        })

        if (!hasError) {
            setLoading(true)
            console.log('no error')
            connection.post('/post/pincodes', { data: pincodeResults }).then(res => {
                console.log(res.data)
                if (res.data.key === 'success') {
                    connection.get('/get/pincode').then(res => {
                        setPinCodes(res.data)
                        setLoading(false)
                    })
                } else {
                    console.log('failed')
                    setLoading(false)
                    setErrorMessage(res.data.message)
                    setOpenError(true)
                }
            }).catch(err => {
                console.log(err)
                setLoading(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('File is not properly formatted')
            setOpenError(true)
        }
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={'search-bar'}>
                    <ImSearch className={'icon'} />
                    <input onChange={e => setSearchValue(e.target.value)} value={searchValue} onKeyDown={handleKeyDown} placeholder={'Type And Enter'} />
                    <IoMdClose className={'icon'} onClick={onClearClick} />
                </div>
                <div className={'btn-con'}>
                    <div className={'add-button'} onClick={handleClickOpen}>
                        Add Pincode
                    </div>
                    <input type="file"
                        id={'bulk-import'} hidden
                        accept=".csv,.xlsx,.xls" className={'add-button'} onChange={onPincodeImportChange} />
                    {/*Import Pincode*/}
                    <label className={'add-button'} htmlFor={'bulk-import'}>Import Pincode</label>
                </div>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
        </div>
    );
};

export default PincodeHeader;
