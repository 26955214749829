import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import ProductRequestFilter from "./section/ProductRequestFilter";
import ProductRequestTable from "./section/ProductRequestTable";
import connection from '../auth/api';

const ProductRequestPage = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()
    const [filteredData, setFilteredData] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({ page: 1, limit: 20, searchText: "" })
    const [totalCount, setToalCount] = useState(0)

    useEffect(() => {
        fetchData()
    }, [params])

    const fetchData = () => {
        connection.get(`/get/productRequests?page=${params?.page}&limit=${params?.limit}&searchText=${params?.searchText}`
        ).then(res => {
            setProducts(res.data.data)
            setToalCount(res?.data?.totalCount)
            setLoading(false)
        })
    }

    const handleChangePage = (event, newPage) => {
        setLoading(true)
        setParams({ ...params, page: newPage + 1 })
    };

    const handleChangeRowsPerPage = (event) => {
        setLoading(true)
        setParams({ page: 1, limit: +event.target.value });
    };

    let tableProps = {
        filteredData,
        setFilteredData,
        products,
        setProducts,
        params,
        totalCount,
        setParams,
        handleChangeRowsPerPage,
        handleChangePage
    }

    return (
        <div className={classes.container}>
            <ProductRequestFilter
                // setFilteredData={setFilteredData}
                // products={products}
                setParams={setParams}
                params={params}
                setLoading={setLoading}
            />
            <ProductRequestTable {...tableProps} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default ProductRequestPage;
