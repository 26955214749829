import React from 'react';
import {makeStyles, useMediaQuery} from "@material-ui/core";
import email from '../../../../../../assets/dashBoard/email.svg'
import order from '../../../../../../assets/dashBoard/order.svg'
import visitor from '../../../../../../assets/dashBoard/visitor.svg'
import totalOrderImg from '../../../../../../assets/dashBoard/totalorders.svg'
import completedOrderImg from '../../../../../../assets/dashBoard/compeleteorders.svg'
import pendingOrderImg from '../../../../../../assets/dashBoard/pendingorders.svg'
import todayOrderImg from '../../../../../../assets/dashBoard/todayorders.svg'
import {Colors} from "../../../../../helpers/Color";
import {useHistory} from "react-router-dom";

const DetailsCard = ({completedOrders, orders, todayOrders, pendingOrders}) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is340pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            display: 'grid',
            height: '100%',
            gridGap: '15px',
            gridTemplateColumns: '1fr 1fr',
            '& .card': {
                position: 'relative',
                padding: is340pxBelow ? '10px' : '15px',
                height: is770pxBelow ? (is430pxBelow ? '100%' : '180px') : '100%',
                borderRadius: '10px',
                // background: 'linear-gradient(#ff916c, #ff784a)',
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(to right,#ff916c, #ff784a)',
                '& .title': {
                    fontSize: is430pxBelow ? '16px' : '18px',
                    color: Colors.light,
                    margin: '0 0 5px',
                },
                '& .count': {
                    fontSize: is430pxBelow ? '16px' : '18px',
                    fontWeight: 'bold',
                    color: Colors.light,
                    margin: '0 0 10px',
                },
                '& .progress': {
                    height:  is430pxBelow ? '6px' : '8px',
                    width: '100%',
                    borderRadius: '10px',
                    background: '#702d3a',
                    transition: 'all 1.4s',
                    '& .progress-inner': {
                        width: '0%',
                        height: '100%',
                        borderRadius: '10px',
                        transition: 'all 1.4s',
                        background: Colors.light,
                    },
                },
                '& .description': {
                    width: is1025pxBelow ? '75%' : '60%',
                    fontSize:  is430pxBelow ? '12px' : '14px',
                    color: Colors.light,
                    margin: '10px 0 0',
                },
                '& img': {
                    position: 'absolute',
                    width: is430pxBelow ? '70px' : '100px',
                    bottom: 0,
                    right: 8,
                },
                '& button': {
                    marginTop: 'auto',
                    width: '100px',
                    border: 0,
                    color: '#ff784a',
                    fontWeight: 'bold',
                    background: Colors.light,
                    fontSize: '12px',
                    letterSpacing: '1px',
                    fontFamily: 'inherit',
                    padding: '5px',
                    borderRadius: '5px',
                    '&:focus': {
                        outline: 0
                    }
                }
            }
        }
    })

    const classes = useStyles()

    function generatePercentageValue(total, value) {
        const discount = (parseInt(value) / parseInt(total) * 100)
        return `${discount}%`
    }

    const history = useHistory();

    const onViewAllClick = (name) => {
      history.push('/orders', {filter: name})
    }

    return (
        <div className={classes.container}>
            <div className={'card'}>
                <h6 className={'title'}>Total Orders</h6>
                <h6 className={'count'}>{orders.length}</h6>
                <div className={'progress'}>
                    <div className={'progress-inner'} style={{width: generatePercentageValue(orders.length, orders.length)}} />
                </div>
                <h6 className={'description'}>here is the count of total Confirmed Order</h6>
                <button onClick={() => onViewAllClick('')}>View All</button>
                {
                    !is430pxBelow &&
                    <img src={totalOrderImg} alt="Order"/>
                }
            </div>
            <div className={'card'} style={{ background: 'linear-gradient(to right,#e67489, #e15772)'}}>
                <h6 className={'title'}>Completed Orders</h6>
                <h6 className={'count'}>{completedOrders.length}</h6>
                <div className={'progress'}>
                    <div className={'progress-inner'} style={{width: generatePercentageValue(orders.length, completedOrders.length)}} />
                </div>
                <h6 className={'description'}>here is the count of total Confirmed Order</h6>
                <button onClick={() => onViewAllClick('delivered')} style={{color: '#e15772'}}>View All</button>
                {
                    !is430pxBelow &&
                    <img src={completedOrderImg} alt="Email"/>
                }
            </div>
            <div className={'card'} style={{ background: 'linear-gradient(to right,#0cd096, #0aac7b)'}}>
                <h6 className={'title'}>Today Orders</h6>
                <h6 className={'count'}>{todayOrders.length}</h6>
                <div className={'progress'}>
                    <div className={'progress-inner'} style={{width: generatePercentageValue(orders.length, todayOrders.length)}}/>
                </div>
                <h6 className={'description'}>here is the count of total Confirmed Order</h6>
                <button onClick={() => onViewAllClick('')} style={{color: '#0aac7b'}}>View All</button>
                {
                    !is430pxBelow &&
                    <img src={todayOrderImg} alt="Visitor"/>
                }
            </div>
            <div className={'card'} style={{ background: 'linear-gradient(to right, #a979f8, #8946f5)'}}>
                <h6 className={'title'}>Pending Orders</h6>
                <h6 className={'count'}>{pendingOrders.length}</h6>
                <div className={'progress'}>
                    <div className={'progress-inner'} style={{width: generatePercentageValue(orders.length, pendingOrders.length)}}/>
                </div>
                <h6 className={'description'}>here is the count of total Confirmed Order</h6>
                <button onClick={() => onViewAllClick('pending')} style={{color: '#8946f5'}}>View All</button>
                {
                    !is430pxBelow &&
                    <img src={pendingOrderImg} alt="Visitor"/>
                }
            </div>
        </div>
    );
};

export default DetailsCard;
