import { createSlice } from '@reduxjs/toolkit'

export const OrderSlice = createSlice({
    name: 'Orders',
    initialState: {
        value: []
    },
    reducers: {
        setOrders: (state, action) => {
            state.value = action?.payload
        },
    },
})

export const { setOrders } = OrderSlice?.actions

export default OrderSlice?.reducer
