import React from 'react';
import {makeStyles, useMediaQuery} from "@material-ui/core";
import {Colors} from "../../../../../helpers/Color";
import {FaShoppingBag, FaWallet} from "react-icons/all";
import image from '../../../../../../assets/dashBoard/dashboard.svg'

const BigBanner = ({totalEarnings}) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is340pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            position: 'relative',
            background: Colors.temp1,
            padding: is340pxBelow ? '12px' : '15px',
            height: is430pxBelow ? '100%' : '400px',
            borderRadius: '10px',
            '& .title': {
                fontSize: is430pxBelow ? '16px' : '20px',
                color: Colors.lightBorder,
                margin: '0 0 12px',
            },
            '& .price': {
                fontSize:  is430pxBelow ? '18px' : '22px',
                color: Colors.light,
                fontWeight: 'bold',
                margin: '0 0 12px',
            },
            '& .description': {
                fontSize: is430pxBelow ? '13px' : '14px',
                color: Colors.lightBorder,
                margin: '0 0 15px',
            },
            '& .line': {
                width: '50%',
                height: '0.5px',
                background: Colors.darkBorder,
            },
            '& .shop': {
                margin: '15px 0',
                '& .icon': {
                    fontSize: '28px',
                    color: Colors.temp2,
                    margin: '0 0 6px',
                },
                '& .title': {
                    fontSize: '14px',
                    color: Colors.lightBorder,
                    margin: '0 0 6px',
                },
                '& .price': {
                    fontSize: '14px',
                    color: Colors.light,
                    fontWeight: 'bold',
                    margin: '0',
                }
            },
            '& button': {
                marginTop: '15px',
                width: is430pxBelow ? '150px' : '180px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                background: Colors.secondary,
                border: 0,
                padding: is340pxBelow ? '8px 0' : '10px 0',
                borderRadius: '20px',
                '&:focus': {
                  outline: 0
                },
                '& .icon': {
                    fontSize: is430pxBelow ? '12px' : '16px',
                    color: Colors.darkText,
                    margin: '0 8px 0 0',
                },
                '& h6': {
                    fontSize: is430pxBelow ? '12px' : '14px',
                    color: Colors.darkText,
                    margin: '0',
                }
            },
            '& img': {
                width: '40%',
                position: 'absolute',
                bottom: '30px',
                right: '30px',
            }
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <h5 className={'title'}>Total Earnings</h5>
            <h6 className={'price'}>₹{totalEarnings}</h6>
            <h6 className={'description'}>here is the total earning by all the vendors</h6>
            <div className={'line'}/>
            <div className={'shop'}>
                <FaShoppingBag className={'icon'}/>
                <h6 className={'title'}>Total Sale</h6>
                <h6 className={'price'}>₹{totalEarnings}</h6>
            </div>
            <div className={'line'}/>
            <button>
                <FaWallet className={'icon'}/>
                <h6>Download Report</h6>
            </button>
            {
                !is430pxBelow &&
                <img src={image} alt="SVG"/>
            }
        </div>
    );
};

export default BigBanner;
