import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../../helpers/Color";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { IoMdCloudUpload } from "react-icons/all";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";
import connection from 'components/app/auth/api';

const EditSpecialitySubCategory = () => {
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: `1px solid ${Colors.primary}`,
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                },
                '& .upload-logo': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon': {
                            color: Colors.primary
                        },
                        '& h6': {
                            color: Colors.primary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .logo-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '100px',
                    margin: '0 10px',
                    background: Colors.primary,
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            }
        }
    }))

    const classes = useStyles();

    const [subCategory, setSubCategory] = useState({ logo: null, name: '', description: '', parentCategory: { name: '', _id: '' } });

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(true);

    const [parentCategoryOptions, setParentCategoryOptions] = useState([]);

    const [isLogoChanged, setIsLogoChanged] = useState(false)
    const [logoUrl, setLogoUrl] = useState(null)
    const [groups, setGroups] = useState([])

    const { id } = useParams();

    useEffect(() => {
        connection.get('/get/specialityCategories?limit=250').then(catRes => {
            setParentCategoryOptions(catRes.data.data)
            connection.get(`/get/specialitySubCategory?id=${id}`).then(res => {
                setSubCategory(res.data.data)
                setLogoUrl(res.data.data?.logoUrl?.url)
                console.log(res.data.data, "es.data.data?.logoUrl?.url");
                setOpen(false)
            })
        })
        connection.get('/get/groups?limit=250').then(res => {
            setGroups(res?.data?.data)
        })
    }, [id])

    const handleDataChange = (e) => {
        setSubCategory({ ...subCategory, [e.target.name]: e.target.value })
    }

    const onUploadLogoClick = (e) => {
        setIsLogoChanged(true)
        setOpen(true)
        let formData = new FormData()
        formData.append('media', e.target.files[0])
        connection.post(`/post/fileUpload`, formData)
            .then((res) => {
                setSubCategory({ ...subCategory, logo: res?.data.id });
                setLogoUrl(res?.data?.url)
                setOpen(false)
            })
    }

    const history = useHistory();

    const selectRef = useRef();

    const onUpdateClick = () => {
        const { _id, name, description, logo, parentCategory, parentGroup } = subCategory
        console.log(subCategory)
        if (name !== '' && description !== '' && logo !== null && parentCategory !== '') {
            setOpen(true)
            // const data = new FormData()
            // if (isLogoChanged) {
            //     data.append('logo', logo)
            // }
            // data.append('data', JSON.stringify(subCategory))
            let params = {
                data: {
                    name,
                    description,
                    logoUrl: logo,
                    parentCategory,
                    parentGroup,
                    _id
                }
            }
            connection.put('/update/specialitySubCategory', params).then(res => {
                if (res.data.key === 'success') {
                    setOpen(false)
                    history.goBack()
                } else {
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                }
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }


    const onParentCategoryChange = (e) => {
        let cat = e.target.value.split("-")
        setSubCategory({ ...subCategory, parentCategory: cat[0], parentGroup: cat[1] })
    }

    return (
        <div className={classes.container}>
            <h5>Edit Speciality Subcategory Information</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Name</h6>
                    <input value={subCategory.name} name={'name'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input value={subCategory.description} name={'description'} onChange={handleDataChange} />
                </div>
                <div className={'select-container'}>
                    <h6>Parent category</h6>
                    <select ref={selectRef} name={'parentCategory'} value={`${subCategory.parentCategory}-${subCategory.parentGroup}`} onChange={onParentCategoryChange} >
                        <option value="" disabled={true} >Parent category</option>
                        {
                            parentCategoryOptions.map((pCOptions, index) => (
                                <option key={index} value={`${pCOptions.name}-${pCOptions.parentGroup}`}>{pCOptions.name} - {pCOptions.parentGroup}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={'upload-logo'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'logo-picker'}><h6>UPLOAD THUMBNAIL</h6></label>
                        <input id={'logo-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            name={'logo'}
                            multiple={false}
                            onChange={onUploadLogoClick} />
                    </div>
                </div>
                {
                    logoUrl !== null ?
                        <div className={'logo-container'}>
                            <img alt={'logo'} src={logoUrl} />
                        </div> : <></>
                }
            </div>
            <div className={'upload'} onClick={onUpdateClick}>
                Update
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default EditSpecialitySubCategory;
