import React, { useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import background from '../../../assets/auth/background.jpg'
import vector from '../../../assets/auth/vector.png'
import logoWhite from '../../../assets/logoWhite.png'
import { Colors } from "../../helpers/Color";
import { AiOutlineUser, AiOutlineMessage } from "react-icons/ai";
import { HiOutlineLockClosed } from "react-icons/hi";
import { FiEye, FiEyeOff } from "react-icons/all";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setProduct } from "../../redux/slice/ProductSlice";
import { loginUser, logoutUser } from "../../redux/slice/IsLoginSlice";
import { backendUrl, etabxServerUrl } from "../../../index";
import CustomToast from "../../helpers/Toast";
import pageLoader from "../../../assets/gifs/PageLoader.gif";
import { useMediaQuery } from "@material-ui/core";

const AuthPageTwo = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            position: 'relative',
            height: '100vh',
            '& .image-con': {
                // display: is430pxBelow ? 'none' : 'block',
                width: is430pxBelow ? '100%' : '40%',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    resizeMode: 'cover',
                    maxWidth: '100%',
                }
            },
            '& .fixed-con': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .card-container': {
                    width: is1025pxBelow ? (is380pxBelow ? '85%' : '75%') : '60%',
                    height: '75%',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '5px',
                    display: 'flex',
                    '& .left-con': {
                        width: is1025pxBelow ? '37%' : '33%',
                        height: '100%',
                        display: is430pxBelow ? 'none' : 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& .logo': {
                            width: '50%',
                        },
                        '& .vector': {
                            width: '70%',
                        },
                        '& div': {
                            '& h6': {
                                color: Colors.light,
                                textAlign: 'center',
                                fontSize: '20px',
                                margin: '0 0 5px 0',
                            },
                            '& .bold': {
                                fontSize: '19px',
                                fontWeight: 'bold'
                            },
                        }
                    },
                    '& .login-con': {
                        width: is1025pxBelow ? (is430pxBelow ? '100%' : '63%') : '67%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        background: is430pxBelow ? Colors.light : 'transparent',
                        borderRadius: is430pxBelow ? '5px' : 0,
                        alignItems: 'center',
                        '& .login-box': {
                            width: is430pxBelow ? '80%' : '60%',
                            '& .heading': {
                                marginBottom: '25px',
                                '& h6': {
                                    color: Colors.darkText,
                                    marginBottom: '5px'
                                },
                                '& h5': {
                                    color: Colors.primary,
                                    fontWeight: 'bold'
                                }
                            },
                            '& .input-con': {
                                border: `1px solid ${Colors.darkBorder}`,
                                borderRadius: '5px',
                                transition: 'all .5s',
                                padding: is430pxBelow ? '6px' : '10px',
                                marginBottom: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                '&:focus-within': {
                                    transition: 'all .5s',
                                    border: `1px solid ${Colors.primary}`,
                                },
                                '& .icon': {
                                    fontSize: '22px',
                                    color: Colors.darkText,
                                },
                                '& input': {
                                    width: '80%',
                                    marginLeft: '5px',
                                    border: 0,
                                    '&:focus': {
                                        outline: 0
                                    }
                                },
                                '& .eye-icon': {
                                    fontSize: '20px',
                                    marginLeft: 'auto',
                                    color: Colors.darkText,
                                },
                            },
                            '& button': {
                                background: Colors.secondary,
                                color: Colors.light,
                                fontWeight: 'bold',
                                fontFamily: 'inherit',
                                border: 0,
                                borderRadius: '5px',
                                width: '100%',
                                padding: is430pxBelow ? '6px' : '8px',
                            }
                        }
                    }
                }
            }
        },
        pageLoaderContainer: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })

    const classes = useStyles();

    const etabxApiUrl = `${etabxServerUrl}/ShopaidService.svc`;

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const [data, setData] = useState({ email: '', password: '' });

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [showPage, setShowPage] = useState({ mailPage: true, otpPage: false })
    const [otp, setOtp] = useState('')
    const dispatch = useDispatch();


    console.log(successMessage, "successMessage");

    const onLoginClick = () => {
        const { email, password } = data;
        if (email !== '' && password !== '') {
            // setLoading(true)
            // axios.defaults.baseURL = '';
            axios.post(backendUrl + '/post/admin', { email, password }).then(res => {
                if (res.data.key === 'success') {
                    // localStorage.setItem('Access_Token', res.data.token)
                    // dispatch(loginUser())
                    // setLoading(false)
                    setSuccessMessage(res.data.message)
                    setOpenSuccess(true)
                    setShowPage({ mailPage: false, otpPage: true })
                } else {
                    setErrorMessage(res?.data?.message ? res?.data?.message : "Something Went Wrong Try Again Later")
                    setOpenError(true)
                    setLoading(false)
                    // axios.defaults.baseURL = backendUrl;
                }
            }).catch(err => {
                setLoading(false)
                setErrorMessage(err.message)
                setOpenError(true)
                axios.defaults.baseURL = backendUrl;
            })
        } else {
            setErrorMessage("Field Should not be empty")
            setOpenError(true)
        }
    }

    const onVerifyOtp = () => {
        const { email } = data;

        if (otp !== '') {
            setLoading(true)
            // axios.defaults.baseURL = '';
            axios.post(backendUrl + '/post/adminVerifyOtp', { email, otp }).then(res => {
                if (res.data.key === 'success') {
                    localStorage.setItem('Access_Token', res.data.token)
                    localStorage.setItem('Refresh_token', res.data.refresh)
                    dispatch(loginUser())
                    setLoading(false)
                    setSuccessMessage(res.data.message)
                    setOpenSuccess(true)
                    setShowPage({ mailPage: false, otpPage: true })
                    setData({ email: '', password: '' })
                    setOtp('')
                } else {
                    setErrorMessage(res.data.message)
                    setOpenError(true)
                    setLoading(false)
                    // axios.defaults.baseURL = backendUrl;
                }
            }).catch(err => {
                setLoading(false)
                setErrorMessage(err.message)
                setOpenError(true)
                axios.defaults.baseURL = backendUrl;
            })
        } else {
            setErrorMessage("Field Should not be empty")
            setOpenError(true)
        }
    }

    const usernameRef = useRef(null)
    const passwordRef = useRef(null)
    const otpRef = useRef(null)


    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onLoginClick()
        }
        if (e.key === 'ArrowUp') {
            usernameRef.current.focus();
        }
        if (e.key === 'ArrowDown') {
            passwordRef.current.focus();
        }
    }



    const onDataChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const onOtpChange = (e) => {
        setOtp(e.target.value)
    }



    const { mailPage, otpPage } = showPage
    if (!loading) {
        return (
            <div className={classes.container}>
                <div className={'image-con'}>
                    <img src={background} alt="Auth Background" />
                </div>
                <div className={'fixed-con'}>
                    <div className={'card-container'}>
                        <div className={'left-con'}>
                            <img className={'logo'} src={logoWhite} alt="Logo" />
                            <img className={'vector'} src={vector} alt="Vector" />
                            <div>
                                <h6>Welcome To</h6>
                                <h6 className={'bold'}>ADMIN DASHBOARD</h6>
                            </div>
                        </div>
                        <div className={'login-con'}>
                            <div className={'login-box'}>
                                <div className={'heading'}>
                                    <h6>Log In To Your</h6>
                                    <h5>ADMIN ACCOUNT</h5>
                                </div>
                                {mailPage && <div>
                                    <div className={'input-con'}>
                                        <AiOutlineUser className={'icon'} />
                                        <input ref={usernameRef} name={'email'} onKeyDown={onKeyDown} value={data.email}
                                            onChange={onDataChange} type="text" placeholder={'Enter Your Username'} />
                                    </div>
                                    <div className={'input-con'}>
                                        <HiOutlineLockClosed className={'icon'} />
                                        <input ref={passwordRef} name={'password'} onKeyDown={onKeyDown}
                                            value={data.password} onChange={onDataChange}
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            placeholder={'Enter Your Password'} />
                                        {
                                            !isPasswordVisible ?
                                                <FiEyeOff className={'eye-icon'}
                                                    onClick={() => setIsPasswordVisible(true)} /> :
                                                <FiEye className={'eye-icon'} onClick={() => setIsPasswordVisible(false)} />
                                        }
                                    </div>
                                    <button onClick={onLoginClick}>Send OTP</button>
                                </div>}
                                {
                                    otpPage &&
                                    <div>
                                        <div className={'input-con'}>
                                            <AiOutlineMessage className={'icon'} />
                                            <input name={'otp'} value={otp}
                                                onChange={onOtpChange} type="text" placeholder={'Enter Your OTP'} />
                                        </div>
                                        <button onClick={onVerifyOtp}>Submit</button>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
                <CustomToast type={'success'} openToast={openSuccess} setOpenToast={setOpenSuccess} message={successMessage} />
            </div>
        );
    } else {
        return (
            <div className={classes.pageLoaderContainer}>
                <img width={'350px'} src={pageLoader} alt={'Page Loader'} />
            </div>
        )
    }
};

export default AuthPageTwo;
