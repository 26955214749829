import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import DynamicCategoriesHeader from "./section/DynamicCategoriesHeader";
import DynamicCategoriesTable from "./section/DynamicCategoriesTable";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import DynamicCategoriesModel from "./section/DynamicCategoriesModel";
import connection from '../auth/api';

const DynamicCategories = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [categories, setCategories] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [open, setOpen] = useState(false);
    const [specialityCategories, setSpecialityCategories] = useState([]);
    const [generalCategories, setGeneralCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Promise.all([getDynamicCategories(), getGeneralCategories(), getSpecialityCategories()]).then(results => {
            setCategories(results[0].data.data)
            setGeneralCategories(results[1].data.data)
            setSpecialityCategories(results[2].data.data)
            setLoading(false)
        })
    }, [])

    function getGeneralCategories() {
        return connection.get('/get/generalCategories?limit=250')
    }

    function getSpecialityCategories() {
        return connection.get('/get/specialityCategories?limit=250')
    }

    function getDynamicCategories() {
        return connection.get('/get/dynamicCategories')
    }

    return (
        <div className={classes.container}>
            <DynamicCategoriesHeader categories={categories} setFilteredData={setFilteredData} setOpen={setOpen} />
            <DynamicCategoriesTable categories={categories} setFilteredData={setFilteredData}
                filteredData={filteredData} setCategories={setCategories} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
            <DynamicCategoriesModel open={open} setOpen={setOpen} dynamicCategories={categories} setLoading={setLoading}
                generalCategories={generalCategories} specialityCategories={specialityCategories} setCategories={setCategories} />
        </div>
    );
};

export default DynamicCategories;
