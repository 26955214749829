import React, { useState, useEffect } from 'react';
import { Dialog, makeStyles, Slide, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../helpers/Color";
import StateHeader from "./section/StateHeader";
import CustomToast from "../../helpers/Toast";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import Axios from 'axios';
import { AiOutlineDelete, FiEdit, MdDelete } from "react-icons/all";
import connection from '../auth/api';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StatePage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        },
        pincodeContainer: {
            display: 'grid',
            gridTemplateColumns: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)') : 'repeat(6, 1fr)') : 'repeat(8, 1fr)',
            gridGap: is430pxBelow ? '10px' : '15px',
            margin: '40px  0',
            '& .pincode': {
                border: `1px solid ${Colors.darkBorder}`,
                display: 'flex',
                borderRadius: '5px',
                padding: '15px',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& h6': {
                    margin: 0,
                    color: Colors.darkText
                },
                '& .action': {
                    '& .icon': {
                        marginLeft: '2px'
                    }
                }
            }
        },
        dialogStyle: {
            padding: '15px',
            '& h6': {
                color: Colors.darkText,
                margin: '0 0 0 5px',
                fontSize: '18px'
            },
            '& .input-container': {
                margin: '15px 0',
                '& input': {
                    border: `1px solid ${Colors.darkBorder}`,
                    '&:focus': {
                        outline: 0
                    },
                    padding: '10px',
                    width: '100%',
                    borderRadius: '5px'
                }
            },
            '& .action-container': {
                display: 'flex',
                justifyContent: 'flex-end',
                '& .cancel': {
                    background: Colors.error,
                    color: Colors.light,
                    borderRadius: '5px',
                    marginRight: '7px',
                    cursor: 'pointer',
                    padding: '4px 15px'
                },
                '& .add': {
                    background: Colors.success,
                    color: Colors.light,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    padding: '4px 18px'
                }
            }
        }
    }))

    const classes = useStyles()

    const [filteredData, setFilteredData] = useState([])

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [states, setStates] = useState([]);
    const [stateName, setStateName] = useState('');
    const [shippingFees, setShippingFees] = useState('');
    const [reload, setReload] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        connection.get('/get/statenames').then(res => {
            setStates(res.data.data)
        })
    }, [reload])

    const onPincodeDeleteClick = (pincode) => {
        setLoading(true)
        connection.delete('/delete/deletestate', { data: { id: pincode._id } }).then(res => {
            connection.get('/get/statenames').then(res => {
                setStates(res.data.data)
                setFilteredData([])
                setLoading(false)
            })
        })
    }

    const onAddClick = () => {
        if (stateName === '' || shippingFees === '') {
            setOpenError(true);
            setErrorMessage('All field required!');
        } else {
            setLoading(true);
            connection.post('/post/addState', { stateName, shippingFees }).then((res) => {
                setLoading(false);
                setOpen(false);
                setReload(!reload);
            }).catch(error => {
                setOpenError(true);
                setErrorMessage('Something went wrong');
            })
        }

    }

    return (
        <div className={classes.container}>
            <StateHeader setFilteredData={setFilteredData} handleClickOpen={handleClickOpen} states={states} />
            <div className={classes.pincodeContainer}>
                {
                    (filteredData?.length !== 0 ? filteredData : states || []).map(p => (
                        <div className={'pincode'}>
                            <h6>{p?.stateName}
                                <br />
                                <span style={{ color: 'red' }} >(₹{p?.shippingFees})</span>
                            </h6>

                            <div className={'action'}>
                                {/* <FiEdit className={'icon'} fontSize={18} color={Colors.primary} /> */}
                                <AiOutlineDelete className={'icon'} onClick={() => onPincodeDeleteClick(p)}
                                    fontSize={20} color={Colors.error} />
                            </div>
                        </div>
                    ))
                }
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className={classes.dialogStyle}>
                    <h6>Add State</h6>
                    <div className={'input-container'}>
                        <input placeholder={'Enter State Name'} type={'text'} onChange={e => setStateName(e.target.value)} value={stateName} />
                    </div>
                    <div className={'input-container'}>
                        <input placeholder={'Enter Shipping Price'} type={'number'} onChange={e => setShippingFees(e.target.value)} value={shippingFees} />
                    </div>
                    <div className={'action-container'}>
                        <div className={'cancel'} onClick={handleClose}>
                            CANCEL
                        </div>
                        <div className={'add'} onClick={onAddClick}>
                            ADD
                        </div>
                    </div>
                </div>
            </Dialog>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress setOpen={setLoading} open={loading} />

        </div>
    );
};

export default StatePage;
