import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import logo from 'assets/logo1.png'
import { BsEnvelopeFill, FaGlobe, ImPhone } from "react-icons/all";

const OrderInvoice = ({ order, invoiceRef }) => {

    const useStyles = makeStyles(({
        container: {
            // height: '100vh',
        },
        invoiceContainer: {
            position: 'relative',
            '& header': {
                background: Colors.primary,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px',
                '& .heading': {
                    width: '35%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    '& h2': {
                        color: Colors.light,
                        margin: '0'
                    }
                },
                '& .details': {
                    width: '63%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    '& img': {
                        width: '100px',
                    },
                    '& .address': {
                        color: Colors.light,
                        margin: '10px 0',
                        fontSize: '12px'
                    },
                    '& .icon-container': {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '& .item': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .icon': {
                                color: Colors.secondary,
                                background: Colors.light,
                                borderRadius: '20px',
                                fontSize: '24px',
                                padding: '5px'
                            },
                            '& h6': {
                                color: Colors.light,
                                margin: '8px 0 8px 5px',
                                fontSize: '12px'
                            }
                        }
                    }
                }
            },
            '& main': {
                '& .invoice-detail': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '25px 0',
                    width: '100%',
                    '& .invoice-address': {
                        width: '45%',
                        paddingLeft: '40px',
                        '& h6': {
                            fontSize: '13px',
                            color: Colors.darkText,
                            fontWeight: 'bold'
                        },
                        '& h5': {
                            fontSize: '15px',
                            color: Colors.darkText,
                            fontWeight: 'bold',
                            margin: '5px 0'
                        },
                        '& p': {
                            fontSize: '12px',
                            color: Colors.darkText,
                            fontWeight: 'bold',
                            margin: '0'
                        }
                    },
                    '& .invoice-date': {
                        width: '50%',
                        '& .secondary-line': {
                            height: '5px',
                            width: '100%',
                            background: Colors.secondary,
                            margin: '0 0 10px'
                        },
                        '& h6': {
                            fontSize: '11px',
                            margin: '6px 0',
                            fontWeight: 'bold',
                            color: Colors.darkText,
                        }
                    }
                },
                '& .product-table': {
                    margin: '25px 0',
                    '& .product-item': {
                        display: 'flex',
                        padding: '12px 0',
                        '& .description': {
                            width: '40%',
                            fontWeight: 'bold',
                            marginLeft: '40px',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .price': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .quantity': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .total': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                    },
                    '& .product-item:nth-child(even)': {
                        display: 'flex',
                        padding: '12px 0',
                        background: Colors.lightBorder,
                        '& .description': {
                            width: '40%',
                            marginLeft: '40px',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .price': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .quantity': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                        '& .total': {
                            width: '20%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '12.5px',
                            color: Colors.darkText
                        },
                    }
                },
                '& .payment-methods': {
                    paddingLeft: '40px',
                    width: '100%',
                    '& .title': {
                        fontSize: '12px',
                        color: Colors.darkText,
                        fontWeight: 'bold',
                        marginBottom: '5px'
                    },
                    '& .flex-container': {
                        display: 'flex',
                        '& .methods': {
                            width: '60%',
                            '& .secondary-line': {
                                height: '4px',
                                width: '100%',
                                background: Colors.secondary,
                                margin: '0 0 5px'
                            },
                            '& h6': {
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: Colors.darkText,
                                '& span': {
                                    fontWeight: 'normal'
                                }
                            }
                        },
                        '& .grand-total': {
                            // width: '40%',
                            display: 'flex',
                            '& div': {
                                width: '50%',
                                background: Colors.secondary,
                                display: 'flex',
                                height: '40px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& h6': {
                                    color: Colors.light,
                                    margin: 0,
                                    fontSize: '13px'
                                }
                            }
                        }
                    },
                    '& .doctor-info': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        margin: '15px 0',
                        '& .name-label': {
                            textAlign: 'center',
                            width: '40%',
                            margin: '0 0 4px',
                            fontSize: '13px',
                            color: Colors.darkText,
                        },
                        '& .doctor-name': {
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '13px',
                            color: Colors.darkText,
                            width: '40%',
                        }
                    }
                },
                '& .thank-you': {
                    width: '40%',
                    margin: '20px 0 20px 40px',
                    '& h5': {
                        color: Colors.darkText,
                        fontSize: '16px'
                    },
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '12px',
                        marginBottom: '4px'
                    },
                }
            },
            '& .action-container': {
                top: -10,
                left: 0,
                background: '#fff',
                position: 'absolute',

            }
        }
    }))

    const classes = useStyles()

    const ExclusiveGSTCalculation = (OriginalCost, GST, isNetPrice) => {
        let GSTAmount = OriginalCost - (OriginalCost * (100 / (100 + parseInt(GST))))
        let NetPrice = OriginalCost - GSTAmount;
        if (isNetPrice) {
            return NetPrice;
        } else {
            return GSTAmount;
        }
    }

    const { shippingAddress } = order

    return (
        <div ref={invoiceRef} className={classes.container}>
            <div className={classes.invoiceContainer}>
                <header>
                    <div className={'heading'}>
                        <h2>INVOICE</h2>
                    </div>
                    <div className={'details'}>
                        <img src={logo} alt={'Logo'} style={{ width: '60%', height: 'auto', marginBottom: '10px' }} />
                        <h6 className={'address'}>
                            No. 30, Thiruvalluvar Street, Perungudi OMR, Chennai - 600096
                        </h6>
                        <div className={'icon-container'}>
                            <div className={'item'}>
                                <ImPhone className={'icon'} />
                                <h6>+918925322230</h6>
                            </div>
                            <div className={'item'}>
                                <BsEnvelopeFill className={'icon'} />
                                <h6>support@etabx.com</h6>
                            </div>
                            <div className={'item'}>
                                <FaGlobe className={'icon'} />
                                <h6>www.etabx.com</h6>
                            </div>
                        </div>
                    </div>
                </header>
                <main>
                    <div className={'invoice-detail'}>
                        <div className={'invoice-address'}>
                            <h6>Invoice to</h6>
                            <h5>{shippingAddress.name}</h5>
                            <p>
                                {shippingAddress.doorNumber} {shippingAddress.streetName} {' '}
                                {shippingAddress.cityName}
                            </p>
                            <p>
                                Ph: {shippingAddress.contactNumber}
                            </p>
                            <p>
                                Email: {shippingAddress.email}
                            </p>
                        </div>
                        <div className={'invoice-date'}>
                            <div className={'secondary-line'} />
                            <h6>INVOICE NO: 0001234</h6>
                            <h6>INVOICE DATE: {new Date().toDateString()}</h6>
                        </div>
                    </div>
                    <div className={'product-table'}>
                        <div className={'product-item'}>
                            <div className={'description'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                ITEM DESCRIPTION
                            </div>
                            <div className={'price'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                PRICE
                            </div>
                            {
                                order.shippingAddress.stateName === 'Tamil Nadu' ? (
                                    <>
                                        <div className={'price'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                            CGST 9%
                                        </div>
                                        <div className={'price'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                            SGST 9%
                                        </div>
                                    </>
                                ) : (
                                    <div className={'price'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                        IGST 18%
                                    </div>
                                )
                            }

                            <div className={'quantity'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                QUANTITY
                            </div>
                            <div className={'total'} style={{ color: Colors.primary, fontSize: '14px' }}>
                                TOTAL
                            </div>
                        </div>
                        {
                            order.products.map(product => (
                                <div className={'product-item'} key={product._id}>
                                    <div className={'description'}>
                                        {product.name}
                                    </div>
                                    <div className={'price'}>
                                        {/* {parseInt(product.salesPrice).toFixed(2)} */}
                                        ₹{new Number(ExclusiveGSTCalculation(product.salesPrice, 18, true)).toFixed(2).toLocaleString("en-IN")}
                                    </div>
                                    {
                                        order.shippingAddress.stateName === 'Tamil Nadu' ? (
                                            <>
                                                <div className={'price'}>
                                                    ₹{new Number(ExclusiveGSTCalculation(product.salesPrice, 18, false) / 2).toFixed(2).toLocaleString("en-IN")}
                                                </div>
                                                <div className={'price'}>
                                                    ₹{new Number(ExclusiveGSTCalculation(product.salesPrice, 18, false) / 2).toFixed(2).toLocaleString("en-IN")}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={'price'}>
                                                    ₹{new Number(ExclusiveGSTCalculation(product.salesPrice, 18, false)).toFixed(2).toLocaleString("en-IN")}
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className={'quantity'}>
                                        {product.quantity}
                                    </div>
                                    <div className={'total'}>
                                        {parseInt(product.salesPrice * product.quantity).toFixed(2)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={'payment-methods'}>
                        <h6 className={'title'}>Payment Methods</h6>
                        <div className={'flex-container'}>
                            <div className={'methods'}>
                                <div className={'secondary-line'} />
                                {/* <h6>Paypal: <span>mercyitcorp@paypal.com</span></h6>
                                <h6>Gpay: <span>+911234567890</span></h6> */}
                                <h6>{order.isCashOnDelivery ? 'Cash On Delivery' : 'Online Mode'}</h6>
                            </div>
                            {/* <div className={'grand-total'}>
                                <div>
                                    <h6>GRAND TOTAL</h6>
                                </div>
                                <div>
                                    <h6>{order.totalPrice}</h6>
                                </div>
                            </div> */}
                            <div style={{ width: '40%' }} >
                                <div className={'grand-total'} >
                                    <div>
                                        <h6>
                                            SUB TOTAL
                                        </h6>
                                    </div>
                                    <div>
                                        <h6>
                                            ₹{new Number(ExclusiveGSTCalculation(order.totalPrice, 18, true)).toFixed(2).toLocaleString("en-IN")}

                                        </h6>
                                    </div>
                                </div>
                                <div className={'grand-total'} >
                                    <div>
                                        <h6>
                                            TAX
                                        </h6>
                                    </div>
                                    <div>

                                        <h6>
                                            ₹{new Number(ExclusiveGSTCalculation(order.totalPrice, 18, false)).toFixed(2).toLocaleString("en-IN")}
                                        </h6>
                                    </div>
                                </div>
                                <div className={'grand-total'} >
                                    <div>
                                        <h6>
                                            Delivery Charge
                                        </h6>
                                    </div>
                                    <div>

                                        <h6>
                                            ₹{order.deliveryCharge}
                                        </h6>
                                    </div>
                                </div>
                                <div className={'grand-total'} >
                                    <div>
                                        <h6>
                                            <b>
                                                GRAND TOTAL
                                            </b>
                                        </h6>
                                    </div>
                                    <div>
                                        <h6>
                                            <b>{parseInt(order.totalPrice + parseInt(order.deliveryCharge)).toFixed(2)}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'doctor-info'}>
                            <h6 className={'name-label'}>Doctor Name</h6>
                            <h6 className={'doctor-name'}>{order?.doctorInfo?.doctorName === '' ? 'Not Mentioned' : order?.doctorInfo?.doctorName}</h6>
                        </div>
                    </div>
                    <div className={'thank-you'}>
                        <h5>Thank You For Your Business</h5>
                        <h6>Terms & Conditions</h6>
                        <h6>Lorem ipsum dolor sit amet, consectetur veniam</h6>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default OrderInvoice;
