import React, { useEffect, useState } from 'react';
import {
    Switch,
    createTheme,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CustomToast from "../../../../../helpers/Toast";
import FullScreenProgress from "../../../../../helpers/FullScreenProgress";
import { Colors } from "../../../../../helpers/Color";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import connection from 'components/app/auth/api';

const EditPageBanner = () => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },

            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            }

        },
        checkBoxContainer: {
            margin: '20px 0 15px',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
        }
    }))

    const classes = useStyles();
    const { id } = useParams();
    console.log(id, "sfsdfsdfds");


    const [pageBanner, setPageBanner] = useState({});

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(true);


    useEffect(() => {
        Promise.all([getGeneralCategory(),]).then(result => {
            setPageBanner(result[0].data.data)
            setOpen(false)
        })
    }, [])

    console.log(pageBanner?.isEnabled, "asdasda");


    function getGeneralCategory() {
        return connection.get(`/get/pageBanner?id=${id}`);
    }



    const handleDataChange = (e) => {

        if (e.target.name === "isEnabled") {
            setPageBanner({ ...pageBanner, [e.target.name]: e.target.checked })
        }
        else {
            setPageBanner({ ...pageBanner, [e.target.name]: e.target.value })
        }
    }
    const history = useHistory();

    const onUploadClick = () => {
        const { title, description, isEnabled, _id } = pageBanner
        if (title !== '' && description !== '') {
            setOpen(true)
            let data = {
                data: {
                    pageBannerId: _id,
                    title,
                    description,
                    isEnabled
                }
            }
            connection.put('/update/pageBanner', data).then(res => {
                if (res.data.key === 'success') {
                    setOpen(false)
                    history.goBack()
                } else {
                    setErrorMessage(res.data.message)
                    setOpen(false)
                    setOpenError(true)
                }
            }).catch(err => {
                console.log(err)
                setOpen(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }



    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    return (
        <div className={classes.container}>
            <h5>Edit Page Banner</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Title</h6>
                    <input value={pageBanner?.title} name={'title'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input value={pageBanner?.description} name={'description'} onChange={handleDataChange} />
                </div>


            </div>
            <h6>Page Banner Enable</h6>

            <Switch
                checked={pageBanner?.isEnabled ? true : false}
                onChange={handleDataChange}
                inputProps={{ 'aria-label': 'controlled' }}
                color='primary'
                name='isEnabled'
            />
            <div className={'upload'} onClick={onUploadClick}>
                Upload
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default EditPageBanner;
