import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Colors } from "../../helpers/Color";
import { useHistory } from "react-router-dom";
import NotificationTable from "./section/NotificationTable";
import AddNewNotification from "./section/addNewNotification";
import axios from "axios";
import connection from '../auth/api';

const NotificationPage = () => {

    const useStyles = makeStyles(({
        container: {
            margin: '15px',
            '& .notification-container': {
                margin: '15px 0',
                '& .header': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& h5': {
                        margin: '0',
                        color: Colors.darkText,
                    },
                    '& .add-notification': {
                        border: `1px solid ${Colors.primary}`,
                        color: Colors.primary,
                        padding: '6px 12px',
                        fontWeight: 'bold',
                        background: Colors.light,
                        transition: 'all .3s',
                        '&:hover': {
                            transition: 'all .3s',
                            background: Colors.primary,
                            color: Colors.light,
                        },
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }
                },
            },
            '& .notification': {
                display: 'grid',
                gridTemplateColumns: '3fr 2fr',
                marginTop: '40px'
            }
        },
        tableContainer: {

        },
        addNotificationContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
    }))

    const classes = useStyles();

    const history = useHistory();

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        connection.get('/get/pushNotifications').then(res => {
            setNotifications(res.data.data)
        })
    }, []);

    return (
        <div className={classes.container}>
            <div className={'notification-container'}>
                <div className={'header'}>
                    <h5>Notifications</h5>
                    {/*<div className={'add-notification'} onClick={() => {history.push('/push-notification/add-new-notification')}}>*/}
                    {/*    New Notification*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={'notification'}>
                <div className={classes.tableContainer}>
                    <NotificationTable notifications={notifications} setNotifications={setNotifications} />
                </div>
                <div className={classes.addNotificationContainer}>
                    <AddNewNotification setNotifications={setNotifications} />
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;
