

export const getDeliveryStatusColor = status => {
    switch (status) {
        case 'pending':
            return '#FFAE00';
        case 'confirmed':
            return '#127500';
        case 'pickedUp':
            return '#00ABA2';
        case 'onTheWay':
            return '#303030';
        case 'delivered':
            return '#007A12';
    }
};

export const getDeliveryStatus = status => {
    switch (status) {
        case 'pending':
            return 'Pending';
        case 'confirmed':
            return 'Confirmed';
        case 'pickedUp':
            return 'Picked Up';
        case 'onTheWay':
            return 'On The Way';
        case 'delivered':
            return 'Delivered';
    }
};




