import React, { useEffect, useState } from 'react';
import axios from "axios";
import { CircularProgress, createTheme, makeStyles, MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../helpers/Color";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/all";
import { notification } from "antd";
import connection from 'components/app/auth/api';


const SingleCategory = ({ category, categories, setCategories, classes }) => {

    const [isAdded, setIsAdded] = useState(false);
    const [loading, setLoading] = useState(true);

    const [actionLoading, setActionLoading] = useState(false);

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    useEffect(() => {
        const filteredCategory = categories.filter(cat => cat._id === category._id)
        if (filteredCategory.length !== 0) {
            setIsAdded(true)
        } else {
            setIsAdded(false)
        }
        setLoading(false)
    }, [categories, category._id]);


    const onAddCategoryClick = () => {

        if (categories.length < 7) {
            if (!loading && !actionLoading) {
                setActionLoading(true)
                connection.post('/post/mainBarCategories', { data: category }).then(res => {
                    if (res.data.key === 'success') {
                        connection.get('/get/mainBarCategories').then(barRes => {
                            setCategories(barRes.data)
                            setActionLoading(false)
                        })
                    }
                })
            }
        } else {
            notification.warning({
                message: 'Warning',
                description: 'Maximum Categories Reached'
            })
        }

    }

    const onRemoveCategoryClick = () => {
        if (!loading && !actionLoading) {
            setActionLoading(true)
            connection.delete('/delete/mainBarCategories', { data: { id: category._id } }).then(res => {
                if (res.data.key === 'success') {
                    connection.get('/get/mainBarCategories').then(barRes => {
                        setCategories(barRes.data)
                        setActionLoading(false)
                    })
                }
            })
        }
    }

    const theme = createTheme({
        palette: {
            primary: {
                light: '#fff',
                main: '#fff',
                dark: '#000'
            },
        },
    });

    return (
        <div className={classes.categoryContainer}>
            <div className={'details'}>
                <div className={'image-container'}
                    style={{ backgroundColor: category.isGeneralCategory ? Colors.light : Colors.primary }}>
                    <img alt={category.name} src={category.logoUrl} />
                </div>
                <div className={'data'}>
                    <h6>{category.name}</h6>
                    <h6>{category.isGeneralCategory ? 'General Category' : 'Speciality Category'}</h6>
                </div>
            </div>
            <MuiThemeProvider theme={theme}>
                <div className={'actions'}>
                    {
                        !isAdded ?
                            <div className={'add-button'} onClick={onAddCategoryClick}>
                                <h6>Add Category</h6>
                                {
                                    actionLoading ? <CircularProgress size={17} /> : <AiOutlinePlus className={'icon'} />
                                }
                            </div> :
                            <div className={'remove-button'} onClick={onRemoveCategoryClick}>
                                <h6>Remove Category</h6>
                                {
                                    actionLoading ? <CircularProgress size={17} /> :
                                        <AiOutlineDelete className={'icon'} />
                                }
                            </div>
                    }
                </div>
            </MuiThemeProvider>
        </div>
    )
}


const AllCategories = ({ allCategories, categories, setCategories }) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(({
        container: {
            display: 'grid',
            gridTemplateColumns: is430pxBelow ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
            gridGap: '15px'
        },
        categoryContainer: {
            backgroundColor: Colors.primaryLight,
            // border: `1px solid ${Colors.darkBorder}`,
            padding: '10px',
            borderRadius: '5px',
            '& .details': {
                display: 'flex',
                alignItems: 'center',
                '& .data': {
                    '& h6': {
                        fontSize: is1025pxBelow ? '13px' : 'auto',
                        color: Colors.darkText,
                        margin: '5px 10px'
                    }
                },
                '& .image-container': {
                    width: '70px',
                    height: '70px',
                    padding: '6px',
                    borderRadius: '5px',
                    border: `1px solid ${Colors.darkBorder}`,
                    '& img': {
                        maxWidth: '100%',
                        maxHeight: '100%'
                    }
                }
            },
            '& .actions': {
                marginTop: '10px',
                '& .add-button': {
                    backgroundColor: Colors.primary,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6px 0 8px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    '& h6': {
                        fontSize: '13px',
                        color: Colors.light,
                        margin: '0 8px 0 0',
                    },
                    '& .icon': {
                        color: Colors.light,
                        fontSize: 17,
                    }
                },
                '& .remove-button': {
                    backgroundColor: Colors.errorLight,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '6px 0 8px',
                    borderRadius: '5px',
                    '& h6': {
                        fontSize: '13px',
                        color: Colors.light,
                        margin: '0 8px 0 0',
                    },
                    '& .icon': {
                        color: Colors.light,
                        fontSize: 17,
                    }
                },
            }
        }
    }))

    const classes = useStyles()

    return (
        <div>
            <div className={classes.container}>
                {
                    allCategories.map(category => (
                        <SingleCategory key={category._id} category={category} classes={classes}
                            categories={categories} setCategories={setCategories} />
                    ))
                }
            </div>
        </div>
    );
};

export default AllCategories;
