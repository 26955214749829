import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { IoCloseCircle, IoMdCloudUpload } from "react-icons/all";
import { useMediaQuery } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import axios from 'axios';
import CustomToast from 'components/helpers/Toast';
import connection from 'components/app/auth/api';

const AddProductImages = ({ product, setProduct }) => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px',
                marginTop: '55px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0 0 0',
                flexDirection: 'column',
                alignItems: 'flex-start',
                '& .upload-thumbnail': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon': {
                            color: Colors.primary
                        },
                        '& h6': {
                            color: Colors.primary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .upload-gallery': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '150px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.secondary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.secondary}`,
                        '& .top-icon': {
                            color: Colors.secondary
                        },
                        '& h6': {
                            color: Colors.secondary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.secondary,
                    border: `2px solid ${Colors.secondary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .thumb-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '5px',
                    margin: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
                '& .gallery': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& .gallery-container': {
                        border: `1px solid ${Colors.lightBorder}`,
                        borderRadius: '5px',
                        position: 'relative',
                        margin: '20px 10px 0',
                        height: '100px',
                        width: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .update-delete': {
                            position: 'absolute',
                            display: 'flex',
                            top: -10,
                            right: -10,
                            '& .delete': {
                                fontSize: '18px',
                                color: Colors.error,
                                margin: '0 3px'
                            },
                            '& .update': {
                                fontSize: '18px',
                                color: Colors.primary,
                                margin: '0 3px'
                            },
                        },
                        '& img': {
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }
                    }
                }
            }
        }
    }))

    const classes = useStyles();

    const onUploadThumbnailClick = (e) => {
        let formData = new FormData()
        formData.append('media', e.target.files[0])
        connection.post(`/post/fileUpload`, formData)
            .then((res) => {
                setProduct({ ...product, thumbnail: res?.data.id });
                setThumbnailImageUrl(res?.data?.url)
            })

    }

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState(null);
    const [galleryImageUrl, setGalleryImageUrl] = useState([]);
    let galleryImg = []


    const onChange = (imageList) => {
        const tempArr = [];
        imageList.forEach(data => {
            tempArr.push(data.file);
        });
        let formData = new FormData()
        tempArr.length > 0 && tempArr?.map((val) => {
            formData.append('media', val)
        })

        connection.post(`/post/fileUpload`, formData)
            .then((res) => {
                galleryImg = galleryImageUrl
                galleryImg.push(res?.data)
                setGalleryImageUrl([...galleryImg])
                setProduct({ ...product, galleryImages: [...galleryImg]?.map((data) => data?.id) });
            })

    };
    return (
        <div className={classes.container}>
            <h5>Product Images</h5>
            <div className={'input-container'}>
                <div className={'upload-thumbnail'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'thumbnail-picker'}><h6>UPLOAD THUMBNAIL</h6></label>
                        <input id={'thumbnail-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            multiple={false}
                            onChange={onUploadThumbnailClick} />
                    </div>
                </div>
                {
                    thumbnailImageUrl !== null ?
                        <div className={'thumb-container'}>
                            <img alt={'Thumbnail'} src={thumbnailImageUrl} />
                        </div> : <></>
                }


                <ImageUploading
                    multiple
                    value={galleryImageUrl}
                    onChange={(e) => { onChange(e) }}
                    maxNumber={6}
                    dataURLKey="url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        dragProps,
                    }) => (
                        <div>


                            <div className={'upload-gallery'} onClick={onImageUpload} {...dragProps}>
                                <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                                <div>
                                    <label><h6>UPLOAD GALLERY</h6></label>
                                </div>
                            </div>
                            <div className={'gallery'}>
                                {imageList?.map((image, index) => (
                                    <div key={index} className={'gallery-container'}>
                                        <img alt={'Thumbnail'} src={image['url']} />
                                        <div className={'update-delete'}>
                                            <IoCloseCircle onClick={() => { setGalleryImageUrl(galleryImageUrl.filter(val => val.id !== image?.id)); }} className={'delete'} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </ImageUploading>
            </div>
        </div>
    );
};

export default AddProductImages;
