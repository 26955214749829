import { createSlice } from '@reduxjs/toolkit'

export const IsLoginSlice = createSlice({
    name: 'IsLogin',
    initialState: {
        value: localStorage.getItem('Access_Token') ? true : false
    },
    reducers: {
        loginUser: (state) => {
            state.value = true
        },
        logoutUser: (state) => {
            state.value = false
        },
    },
})

export const { loginUser, logoutUser } = IsLoginSlice.actions

export default IsLoginSlice.reducer
