import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TopCards from "./section/TopCards";
import TodCardsProMax from "./section/topCard";
import {Colors} from "../../helpers/Color";

const DashBoard = () => {

    const useStyles = makeStyles(() => ({
        container: {
            height: '90vh'
        }
    }))

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <TodCardsProMax />
        </div>
    );
};

export default DashBoard;
