import React, { useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { BiEdit, RiDeleteBin6Line } from "react-icons/all";
import TablePagination from "@material-ui/core/TablePagination";
import connection from 'components/app/auth/api';

const SubCategoriesTable = ({ filteredData, setFilteredData, subCategories, setSubCategories }) => {

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Logo',
            align: 'center',
        },
        {
            name: 'Description',
            align: 'center',
        },
        {
            name: 'Parent Category',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '250px'
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: Colors.light,
            padding: '10px',
            borderRadius: '100px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '55px' : '70px',
            width: is430pxBelow ? '55px' : '70px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
            '& .edit-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const displayingProducts = filteredData.length !== 0 ? filteredData : subCategories

    const history = useHistory();

    const onEditProductClick = (id) => {
        history.push(`/general-sub-categories/edit/${id}`)
    }

    const onDeleteProductClick = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                connection.delete('/delete/generalSubCategory', { data: { subCategoryId: id } }).then(res => {
                    console.log(res.data)
                    setFilteredData([])
                })
                Swal.fire(
                    'Deleted!',
                    'Subcategory has been deleted.',
                    'success'
                ).then(() => {
                    connection.get('/get/generalSubCategories?limit=250').then(res => {
                        setSubCategories(res.data.data)
                    })
                })
            }
        })
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayingProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{index + 1}</h6>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{row.name}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <div className={classes.tableImage}>
                                            <img src={row.logoUrl?.id ? row.logoUrl?.url : row.logoUrl} alt={row.name} />
                                        </div>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 className={classes.rowTextStyle} style={{ margin: '0 auto' }}>{row.description}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 className={classes.rowTextStyle} style={{ margin: '0 auto' }}>{row.parentCategory}</h6>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <div className={classes.actionContainer}>
                                            <RiDeleteBin6Line onClick={() => onDeleteProductClick(row._id)} className={'delete-icon'} />
                                            <BiEdit className={'edit-icon'} onClick={() => onEditProductClick(row._id)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                displayingProducts.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={displayingProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default SubCategoriesTable;
