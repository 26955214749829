import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import SubCategoriesHeader from "./section/SubCategoriesHeader";
import axios from "axios";
import SubCategoriesTable from "./section/SubCategoriesTable";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const SubCategories = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [subCategories, setSubCategories] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/generalSubCategories?limit=250').then(res => {
            setSubCategories(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <SubCategoriesHeader subCategories={subCategories} setFilteredData={setFilteredData} />
            <SubCategoriesTable subCategories={subCategories} setFilteredData={setFilteredData}
                filteredData={filteredData} setSubCategories={setSubCategories} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default SubCategories;
