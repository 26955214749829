import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import 'react-quill/dist/quill.snow.css';
import 'sweetalert2/dist/sweetalert2.css'
import axios from "axios";
import { Provider } from "react-redux";
import Store from "./components/redux/Store";
import { cloudUrl } from 'config';


export const etabxServerUrl = cloudUrl

export const backendUrl = cloudUrl;

axios.defaults.baseURL = backendUrl;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
