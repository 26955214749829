import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../helpers/Color";

const UserDetails = ({user}) => {

    const useStyles = makeStyles({
        container: {
            '& .details1': {
                '& div': {
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    '& .label': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: 0,
                        width: '30%',
                    },
                    '& .value': {
                        color: Colors.darkText,
                        fontWeight: 'bold',
                        width: '65%',
                        fontSize: '16px',
                        margin: 0,
                    }
                }
            },
            '& .details2': {
                display: 'flex',
                alignItems: 'center',
                '& div': {
                    width: '30%',
                    marginBottom: '20px',
                    '& .label': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: 0,
                    },
                    '& .value': {
                        color: Colors.darkText,
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        margin: '5px 0 0 0',
                    }
                }
            },
            '& .prescription-con': {
                '& h6': {
                    color: Colors.darkText,
                    fontSize: '16px',
                    margin: 0,
                },
                '& .grid-con': {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    marginTop: '25px',
                    gridGap: '15px',
                    '& div': {
                        width: '100%',
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px solid ${Colors.lightBorder}`,
                        '& img': {
                            width: '80%',
                            maxWidth: '80%',
                            maxHeight: '80%',
                        }
                    },
                },
            }
        }
    })

    const classes = useStyles();

    const {username, email, mobileNumber, prescriptions, bloodGroup, gender, createAt, dateOfBirth, shippingAddresses} = user

    return (
        <div className={classes.container}>
            <div className={'details1'}>
                <div>
                    <h6 className={'label'}>Username:</h6>
                    <h6 className={'value'}>{username}</h6>
                </div>
                <div>
                    <h6 className={'label'}>Email:</h6>
                    <h6 className={'value'}>{email}</h6>
                </div>
                <div>
                    <h6 className={'label'}>DOB:</h6>
                    <h6 className={'value'}>{dateOfBirth !== null ? new Date(dateOfBirth).toDateString() : 'Not Added'}</h6>
                </div>
                <div>
                    <h6 className={'label'}>Mobile Number:</h6>
                    <h6 className={'value'}>{mobileNumber}</h6>
                </div>
            </div>
            <div className={'details2'}>
                <div>
                    <h6 className={'label'}>Gender</h6>
                    <h6 className={'value'}>{gender !== null ? gender : 'Not Added'}</h6>
                </div>
                <div>
                    <h6 className={'label'}>Blood Group</h6>
                    <h6 className={'value'}>{bloodGroup !== null ? bloodGroup : 'Not Added'}</h6>
                </div>
                <div>
                    <h6 className={'label'}>Created At</h6>
                    <h6 className={'value'}>{new Date(createAt).toDateString()}</h6>
                </div>
            </div>
            {
                shippingAddresses.length !== 0 &&
                <div className={'details1'}>
                    <div style={{alignItems: 'flex-start'}}>
                        <h6 className={'label'}>Shipping Address One:</h6>
                        <h6 className={'value'}>{shippingAddresses[0].doorNumber},
                            {shippingAddresses[0].streetName}, {shippingAddresses[0].landMark},
                            {shippingAddresses[0].cityName} - {shippingAddresses[0].pincode}</h6>
                    </div>
                    {
                        shippingAddresses.length !== 1 &&
                        <div style={{alignItems: 'flex-start'}}>
                            <h6 className={'label'}>Shipping Address Two:</h6>
                            <h6 className={'value'}>{shippingAddresses[1].doorNumber},
                                {shippingAddresses[1].streetName}, {shippingAddresses[1].landMark},
                                {shippingAddresses[1].cityName} - {shippingAddresses[1].pincode}</h6>
                        </div>
                    }
                </div>
            }
            {
                prescriptions.length !== 0 &&
                <div className={'prescription-con'}>
                    <h6>Prescription: </h6>
                    <div className={'grid-con'}>
                        {
                            prescriptions.map(pres => (
                                <div>
                                    <img src={pres.prescriptionImageUrl} alt={'prescription'}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default UserDetails;
