import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import SpecialitySubCategoryHeader from "./section/SpecialitySubCategoryHeader";
import SpecialitySubCategoryTable from "./section/SpecialitySubCategoryTable";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const SpecialitySubCategory = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [subCategories, setSubCategories] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/specialitySubCategories?limit=250').then(res => {
            setSubCategories(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <SpecialitySubCategoryHeader subCategories={subCategories} setFilteredData={setFilteredData} />
            <SpecialitySubCategoryTable setFilteredData={setFilteredData} filteredData={filteredData}
                setSubCategories={setSubCategories} subCategories={subCategories} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default SpecialitySubCategory;
