import React, { useEffect, useState } from 'react';
import { Dialog, makeStyles, Slide, useMediaQuery } from "@material-ui/core";
import axios from "axios";
import PincodeHeader from "./section/PincodeHeader";
import { Colors } from "../../helpers/Color";
import { AiOutlineDelete, FiEdit, MdDelete } from "react-icons/all";
import CustomToast from "../../helpers/Toast";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Pincode = () => {


    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        },
        pincodeContainer: {
            display: 'grid',
            gridTemplateColumns: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)') : 'repeat(6, 1fr)') : 'repeat(8, 1fr)',
            gridGap: is430pxBelow ? '10px' : '15px',
            margin: '40px  0',
            '& .pincode': {
                border: `1px solid ${Colors.darkBorder}`,
                display: 'flex',
                borderRadius: '5px',
                padding: '15px',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& h6': {
                    margin: 0,
                    color: Colors.darkText
                },
                '& .action': {
                    '& .icon': {
                        marginLeft: '2px'
                    }
                }
            }
        },
        dialogStyle: {
            padding: '15px',
            '& h6': {
                color: Colors.darkText,
                margin: '0 0 0 5px',
                fontSize: '18px'
            },
            '& .input-container': {
                margin: '15px 0',
                '& input': {
                    border: `1px solid ${Colors.darkBorder}`,
                    '&:focus': {
                        outline: 0
                    },
                    padding: '10px',
                    width: '100%',
                    borderRadius: '5px'
                }
            },
            '& .action-container': {
                display: 'flex',
                justifyContent: 'flex-end',
                '& .cancel': {
                    background: Colors.error,
                    color: Colors.light,
                    borderRadius: '5px',
                    marginRight: '7px',
                    cursor: 'pointer',
                    padding: '4px 15px'
                },
                '& .add': {
                    background: Colors.success,
                    color: Colors.light,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    padding: '4px 18px'
                }
            }
        }
    }))

    const classes = useStyles()

    const [pinCodes, setPinCodes] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        connection.get('/get/pincode').then(res => {
            setPinCodes(res.data.data)
        })
    }, [])

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pincode, setPincode] = useState('');
    const [deliveryCharge, setDeliveryCharge] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onAddClick = () => {
        if (pincode.length !== 6) {
            setErrorMessage('Enter a valid pincode')
            setOpenError(true)
        } else {
            setLoading(true)
            connection.post('/post/pincode', { pincode, deliveryCharge }).then(res => {
                if (res.data.key === 'success') {
                    connection.get('/get/pincode').then(res => {
                        setPinCodes(res.data.data)
                        setLoading(false)
                        setPincode('')
                        setDeliveryCharge('')
                        handleClose()
                    })
                } else {
                    setLoading(false)
                    setErrorMessage(res.data.message)
                    setOpenError(true)
                }
            })
        }
    }

    const onPincodeDeleteClick = (pincode) => {
        setLoading(true)
        connection.delete('/delete/pincode', { data: { id: pincode._id } }).then(res => {
            connection.get('/get/pincode').then(res => {
                setPinCodes(res.data.data)
                setFilteredData([])
                setLoading(false)
            })
        })
    }

    return (
        <div className={classes.container}>
            <PincodeHeader setFilteredData={setFilteredData}
                setLoading={setLoading}
                pinCodes={pinCodes} setPinCodes={setPinCodes}
                handleClickOpen={handleClickOpen} />
            <div className={classes.pincodeContainer}>
                {
                    (filteredData.length !== 0 ? filteredData : pinCodes).map(p => (
                        <div className={'pincode'}>
                            <h6>{p.pincode}
                                <br />
                                {/* <span style={{color : 'red'}} >(₹{p.deliveryCharge})</span> */}
                            </h6>

                            <div className={'action'}>
                                {/*<FiEdit className={'icon'} fontSize={18} color={Colors.primary}/>*/}
                                <AiOutlineDelete className={'icon'} onClick={() => onPincodeDeleteClick(p)}
                                    fontSize={20} color={Colors.error} />
                            </div>
                        </div>
                    ))
                }
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className={classes.dialogStyle}>
                    <h6>Add Pincode</h6>
                    <div className={'input-container'}>
                        <input placeholder={'Enter a pincode'} type={'number'} value={pincode}
                            onChange={e => setPincode(e.target.value)} />
                    </div>
                    {/* <div className={'input-container'}>
                        <input placeholder={'Enter a delivery charge'} type={'number'} value={deliveryCharge}
                               onChange={e => setDeliveryCharge(e.target.value)}/>
                    </div> */}
                    <div className={'action-container'}>
                        <div className={'cancel'} onClick={handleClose}>
                            CANCEL
                        </div>
                        <div className={'add'} onClick={onAddClick}>
                            ADD
                        </div>
                    </div>
                </div>
            </Dialog>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress setOpen={setLoading} open={loading} />
        </div>
    );
};

export default Pincode;
