import React from 'react';
import {CircularProgress, Dialog} from "@material-ui/core";

const FullScreenProgress = ({open, setOpen}) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <div style={{width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'transparent', height: '100px'}}>
                    <CircularProgress />
                </div>
            </Dialog>
        </div>
    );
};

export default FullScreenProgress;
