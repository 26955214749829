import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import NewProductRequestFilter from "./section/NewProductRequestFilter";
import NewProductRequestTable from "./section/NewProductRequestTable";
import connection from '../auth/api';

const NewProductRequestsPage = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()
    const [filteredData, setFilteredData] = useState([]);
    const [newProductRequests, setNewProductRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/newProductRequests').then(res => {
            setNewProductRequests(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <NewProductRequestFilter setFilteredData={setFilteredData} newProductRequests={newProductRequests} />
            <NewProductRequestTable filteredData={filteredData}
                setFilteredData={setFilteredData}
                newProductRequests={newProductRequests} setNewProductRequests={setNewProductRequests} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default NewProductRequestsPage;
