import React, { useEffect, useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from 'react-select';
import axios from "axios";
import CustomToast from "../../../helpers/Toast";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import connection from 'components/app/auth/api';

const AddPromoCode = ({ setPromoCodes }) => {

    const [allProducts, setAllProducts] = useState([]);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('')


    useEffect(() => {
        let params = {
            searchText: searchValue
        }
        connection.get('/get/allProducts', { params }).then(res => {
            const allProductsTemp = [];
            res?.data?.data && res?.data?.data?.forEach(p => {
                allProductsTemp.push({ ...p, value: p.name, label: p.name });
            })
            console.log(res?.data?.data, "res?.data?.data");
            setAllProducts(allProductsTemp)
        })
    }, [searchValue]);

    const defaultValue = { name: '', maxLimit: '', discountPercentage: '', expireDate: new Date(), products: [] }

    const [promoCode, setPromoCode] = useState(defaultValue);


    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& div': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '70%',
                        borderRadius: '5px',
                        padding: '10px',
                        margin: '5px 0'
                    }
                },
                '& .text-field-container': {
                    '& h6': {
                        margin: '8px 0',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& .date-picker': {
                        border: `1px solid ${Colors.primary}`,
                        padding: '0 10px',
                        background: Colors.light,
                        width: is430pxBelow ? '100%' : '70%',
                        borderRadius: '5px',
                        '& input': {
                            padding: '0',
                            border: 0,
                        },
                        '& .MuiIconButton-root': {
                            padding: '0 !important'
                        },
                        '& .MuiSvgIcon-root': {
                            // color: '#bfbfbf',
                            marginLeft: 'auto'
                        },
                        '& .MuiFormControl-marginNormal': {
                            marginTop: '8px !important',
                            marginBottom: '8px !important',
                        },
                        '& ..MuiInputBase-input': {
                            // padding: '0 !important'
                        }
                    }
                },
                '& .select-container': {
                    margin: '10px 0 0',
                    width: is430pxBelow ? '100%' : '70%',
                    '& .sel': {
                        margin: '10px 0 0',
                    }
                }
            }
        },
        uploadButton: {
            color: Colors.light,
            border: `1px solid ${Colors.primary}`,
            borderRadius: '5px',
            background: Colors.primary,
            width: is430pxBelow ? '100%' : '70%',
            fontWeight: 'bold',
            transition: 'all .4s',
            padding: '6px',
            '&:hover': {
                transition: 'all .4s',
                background: Colors.light,
                color: Colors.primary,
            }
        }
    }))

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            // border: `1px solid ${Colors.primary}`,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            border: `1px solid ${Colors.primary}`,
            padding: '3px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? Colors.darkText : Colors.primary,
            width: '100%',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        }
    }

    const classes = useStyles();

    const onSelectChange = (value) => {
        console.log(value, "sdjkaskjdjsaj");
        setPromoCode({ ...promoCode, products: value })
    }

    const onDataChange = (e) => {
        setPromoCode({ ...promoCode, [e.target.name]: e.target.value })
    }

    const handleChange = (value) => {
        console.log(value, "65656566");
        setSearchValue(value)
    }

    const onUploadCodeClick = () => {
        const { name, maxLimit, discountPercentage, products } = promoCode
        if (name !== '' && maxLimit !== '' && discountPercentage !== '' && products.length !== 0) {
            setOpen(true)
            connection.post('/post/promoCode', { data: promoCode }).then(res => {
                if (res.data.key === 'success') {
                    connection.get('/get/promoCodes').then(res => {
                        setOpen(false)
                        setPromoCode(defaultValue)
                        setPromoCodes(res?.data?.data)
                    })
                } else {
                    setOpen(false)
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                }
            }).catch(() => {
                setOpen(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.container}>
                <h5>Promo Code Information</h5>
                <div className={'input-container'}>
                    <div>
                        <h6>Name</h6>
                        <input value={promoCode.name} name={'name'} onChange={onDataChange} />
                    </div>
                    <div>
                        <h6>Max Limit</h6>
                        <input type={'number'} value={promoCode.maxLimit} name={'maxLimit'} onChange={onDataChange} />
                    </div>
                    <div>
                        <h6>Discount Percentage</h6>
                        <input type={'number'} value={promoCode.discountPercentage} name={'discountPercentage'} onChange={onDataChange} />
                    </div>
                    <div className={'text-field-container'}>
                        <h6>Expire At</h6>
                        <div className={'date-picker'}>
                            <DatePicker
                                margin="normal"
                                style={{ width: '100%' }}
                                format="dd/MM/yyyy"
                                minDate={new Date()}
                                value={promoCode.expireDate}
                                onChange={(date) => setPromoCode({ ...promoCode, expireDate: date })}
                                InputProps={{
                                    disableUnderline: true,
                                    readOnly: true
                                }}
                            />
                        </div>
                    </div>
                    <div className={'select-container'}>
                        <h6>Products to Apply</h6>
                        <div className={'sel'}>
                            <Select options={allProducts}
                                value={promoCode.products}
                                width={'100%'}
                                styles={customStyles} isMulti
                                onChange={onSelectChange}
                                onInputChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <button className={classes.uploadButton} onClick={onUploadCodeClick}>Upload Code</button>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </MuiPickersUtilsProvider>
    );
};

export default AddPromoCode;
