import React, {useState} from 'react';
import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../helpers/Color";
import {IoMdCloudUpload} from "react-icons/all";

const EditBlogImage = ({blog, setBlog}) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isImageEdited, setIsImageEdited] = useState(false);

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5':{
                color: Colors.primary,
                fontSize: '20px',
                marginTop: '55px'
            },
            '& .input-container':{
                display: 'flex',
                margin: '20px 0 0 0',
                flexDirection: 'column',
                alignItems: 'flex-start',
                '& .upload-thumbnail':{
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover':{
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon':{
                            color: Colors.primary
                        },
                        '& h6':{
                            color: Colors.primary
                        }
                    },
                    '& .top-icon':{
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input':{
                        display: 'none'
                    },
                    '& label':{
                        margin: 0,
                        cursor: 'pointer',
                        '& h6':{
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .upload-gallery':{
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '150px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    transition: 'all .4s',
                    '&:hover':{
                        background: Colors.light,
                        color: Colors.secondary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.secondary}`,
                        '& .top-icon':{
                            color: Colors.secondary
                        },
                        '& h6':{
                            color: Colors.secondary
                        }
                    },
                    '& .top-icon':{
                        color: Colors.light
                    },
                    background: Colors.secondary,
                    border: `2px solid ${Colors.secondary}`,
                    '& input':{
                        display: 'none'
                    },
                    '& label':{
                        margin: 0,
                        cursor: 'pointer',
                        '& h6':{
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .thumb-container':{
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '5px',
                    margin: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img':{
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
                '& .gallery':{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& .gallery-container':{
                        border: `1px solid ${Colors.lightBorder}`,
                        borderRadius: '5px',
                        position: 'relative',
                        margin: '20px 10px 0',
                        height: '100px',
                        width: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .update-delete':{
                            position: 'absolute',
                            display: 'flex',
                            top: -10,
                            right: -10,
                            '& .delete':{
                                fontSize: '18px',
                                color: Colors.error,
                                margin: '0 3px'
                            },
                            '& .update':{
                                fontSize: '18px',
                                color: Colors.primary,
                                margin: '0 3px'
                            },
                        },
                        '& img':{
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }
                    }
                }
            }
        }
    }))

    const onUploadImageClick = (e) => {
        setIsImageEdited(true)
        setImageUrl(e.target.files[0])
        setBlog({...blog, image: e.target.files[0]});
    }

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <h5>Block Cover Image</h5>
            <div className={'input-container'}>
                <div className={'upload-thumbnail'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19}/>
                    <div>
                        <label htmlFor={'thumbnail-picker'}><h6>UPLOAD IMAGE</h6></label>
                        <input id={'thumbnail-picker'} type={'file'} size={'60'}
                               accept={'image/*'}
                               multiple={false}
                               onChange={onUploadImageClick}/>
                    </div>
                </div>
                {
                    isImageEdited ? (
                        imageUrl !== null ?
                            <div className={'thumb-container'}>
                                <img alt={'Thumbnail'} src={URL.createObjectURL(imageUrl)}/>
                            </div> : <></>
                    ) : (
                        <div className={'thumb-container'}>
                            <img alt={'Thumbnail'} src={blog.imageUrl}/>
                        </div>
                    )

                }

            </div>
        </div>
    );
};

export default EditBlogImage;
