import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { BiPurchaseTagAlt, BsInboxes, BsListUl, FiUsers, IoCartOutline } from "react-icons/all";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import totalProductsImg from 'assets/dashBoard/totalProducts.png'
import addedProductsImg from 'assets/dashBoard/addedProducts.png'
import totalOrdersImg from 'assets/dashBoard/totalProducts.png'
import customersImg from 'assets/dashBoard/customers.png'
import generalCategoryImg from 'assets/dashBoard/genralCategory.png'
import specialityCategoryImg from 'assets/dashBoard/specialityCategory.png'
import { setOrders } from "../../../redux/slice/OrderSlice";
import connection from 'components/app/auth/api';

const TopCards = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is340pxBelow = useMediaQuery('(max-width:340px)')

    const useStyles = makeStyles(() => ({
        container: {
            display: 'grid',
            gridTemplateColumns: is1025pxBelow ? (is430pxBelow ? (is340pxBelow ? 'repeat(2, 1fr)' : 'repeat(2, 1fr)') : 'repeat(3, 1fr)') : 'repeat(4, 1fr)',
            gridGap: is430pxBelow ? '10px' : '15px',
            '& .card-item': {
                width: '100%',
                position: 'relative',
                '& img': {
                    maxWidth: '100%',
                },
                '& .dash-texts': {
                    position: 'absolute',
                    top: is1025pxBelow ? (is430pxBelow ? (is340pxBelow ? '20%' : '26%') : '26%') : '30%',
                    left: is430pxBelow ? (is340pxBelow ? '10px' : '15px') : '25px',
                    '& h6': {
                        color: Colors.light,
                        fontSize: is1025pxBelow ? (is430pxBelow ? (is340pxBelow ? '10px' : '12px') : '16px') : '18px',
                        marginBottom: '5px'
                    },
                    '& h5': {
                        color: Colors.light,
                        fontSize: is1025pxBelow ? (is430pxBelow ? (is340pxBelow ? '12px' : '14px') : '18px') : 'auto',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    }))

    const classes = useStyles()

    const products = useSelector((state) => state.Products.value)

    const [addedProducts, setAddedProducts] = useState([]);
    const [orders, setOrdersData] = useState([]);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [specialityCategories, setSpecialityCategories] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        Promise.all([getAddedProducts(), getOrders(), getUsers(), getCategories(), getSpecialityCategories()]).then(results => {
            setAddedProducts(results[0].data)
            setOrdersData(results[1].data)
            setUsers(results[2].data)
            setCategories(results[3].data)
            setSpecialityCategories(results[4].data)
        })
    }, [dispatch])

    function getAddedProducts() {
        return connection.get('/get/allProducts')
    }

    function getOrders() {
        return connection.get('/get/orders')
    }

    function getUsers() {
        return connection.get('/get/users')
    }

    function getCategories() {
        return connection.get('/get/generalCategories?limit=250')
    }

    function getSpecialityCategories() {
        return connection.get('/get/specialityCategories?limit=250')
    }

    return (
        <div className={classes.container}>
            <div className={'card-item'}>
                <img src={totalProductsImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>Total Products</h6>
                    <h5>{products.length}</h5>
                </div>
            </div>
            <div className={'card-item'}>
                <img src={addedProductsImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>Total Added</h6>
                    <h5>{addedProducts.length}</h5>
                </div>
            </div>
            <div className={'card-item'}>
                <img src={customersImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>Total Customer</h6>
                    <h5>{users.length}</h5>
                </div>
            </div>
            <div className={'card-item'}>
                <img src={totalOrdersImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>Total Orders</h6>
                    <h5>{orders.length}</h5>
                </div>
            </div>
            <div className={'card-item'}>
                <img src={generalCategoryImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>General Category</h6>
                    <h5>{categories.length}</h5>
                </div>
            </div>
            <div className={'card-item'}>
                <img src={specialityCategoryImg} alt={'Total Products'} />
                <div className={'dash-texts'}>
                    <h6>Speciality Category</h6>
                    <h5>{specialityCategories.length}</h5>
                </div>
            </div>
        </div>
    );
};

export default TopCards;
