import React, { useEffect, useState } from 'react';
import { createTheme, MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { ImSearch, IoMdClose } from "react-icons/all";
import CustomToast from "../../../helpers/Toast";
import { getDeliveryStatus } from "../../../helpers/HelperFunctions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory, useLocation } from "react-router-dom";

const OrdersFilter = ({ setFilteredData, orders, setPage }) => {
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .search-bar': {
                display: 'flex',
                width: is430pxBelow ? '100%' : '25%',
                margin: is430pxBelow ? '0 0 15px' : '0',
                alignItems: 'center',
                border: `1px solid ${Colors.primary}`,
                borderRadius: '5px',
                padding: '10px',
                background: Colors.primaryLight,
                '& .icon': {
                    fontSize: '22px',
                    color: Colors.darkText
                },
                '& input': {
                    width: '100%',
                    '&:focus': {
                        outline: 0
                    },
                    margin: '0 10px',
                    border: `0`,
                    background: Colors.primaryLight,
                }
            },
            '& .date-con': {
                width: '30%',
            }
        },
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // flexWrap: 'wrap',
            '& h5': {
                fontWeight: 'bold',
                display: is430pxBelow ? 'none' : 'auto',
                fontSize: is430pxBelow ? '16px' : '18px',
                margin: is430pxBelow ? '0 6px 0 0' : '10px 10px 0 0',
                color: Colors.darkBorder,
            },
            '& .date-picker': {
                padding: '0 10px',
                '& label': {
                    color: Colors.darkBorder,
                    fontWeight: 'bold',
                    fontSize: is430pxBelow ? '14px' : 'auto',
                },
                '& .MuiIconButton-root': {
                    padding: '0 !important'
                },
                '& .MuiSvgIcon-root': {
                    color: Colors.primary
                },
                '& .MuiInputBase-input': {
                    color: Colors.darkText,
                    width: is430pxBelow ? '80px' : '90px',
                    fontWeight: 'bold',
                    fontSize: is430pxBelow ? '14px' : 'auto',
                    // marginTop: '-5px'
                },
                '& .MuiFormControl-marginNormal': {
                    marginTop: is430pxBelow ? '4px !important' : '6px !important',
                    marginBottom: is430pxBelow ? '4px !important' : '6px !important',
                }
            }
        },
        buttonContainer: {
            display: 'flex',
            margin: '10px 0 25px',
            // width: '50%',
            '& button': {
                borderRadius: '5px',
                marginRight: '8px',
                cursor: 'pointer',
                fontSize: '14px',
                padding: '8px',
                border: 0,
                '&:focus': {
                    outline: 0
                },
                background: Colors.primary,
                color: Colors.light,
                // padding: '8px',
                width: '120px'
            }
        },
    }))

    const classes = useStyles()
    const [searchValue, setSearchValue] = useState('');

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isDateChanged, setIsDateChanged] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const filteredData = orders.filter(item => item.user.username.toLowerCase().includes(searchValue.toLowerCase()))
            if (filteredData.length === 0) {
                setErrorMessage('Item Not Found')
                setFilteredData([]);
                setOpenError(true)
            } else {
                setFilteredData(filteredData);
            }
        }
    }




    const onClearClick = () => {
        setFilteredData([])
        setSearchValue('')
    }

    // pending: '#FFAE00',
    //     confirmed: '#127500',
    //     pickedUp: '#00ABA2',
    //     onTheWay: '#303030',
    //     delivered: '#007A12',

    const deliveryStatus = [
        {
            name: 'pending',
            color: '#FFAE00'
        },
        {
            name: 'confirmed',
            color: '#127500'
        },
        {
            name: 'pickedUp',
            color: '#00ABA2'
        },
        {
            name: 'onTheWay',
            color: '#303030'
        },
        {
            name: 'delivered',
            color: '#007A12'
        }
    ]

    const onDeliveryFilterClick = (name) => {
        const filter = orders.filter(item => item.deliveryStatus === name)
        setFilteredData(filter)
        setPage(0)
    }

    const currentDate = new Date()
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);

    // console.log(orders[orders.length - 1].orderedAt, "oooooooo", orders);

    // const [fromDate, setFromDate] = useState(new Date(orders.length > 0 && orders[orders.length - 1].orderedAt));
    // const [toDate, setToDate] = useState(new Date(orders.length > 0 && orders[0].orderedAt));
    const [fromDate, setFromDate] = useState(yesterday.toDateString())
    const [toDate, setToDate] = useState(currentDate)


    useEffect(() => {
        if (orders.length > 0) {
            // setFromDate(new Date(orders.length > 0 && orders[orders.length - 1].orderedAt))
            // setToDate(new Date(orders.length > 0 && orders[0].orderedAt))
            setFromDate(yesterday.toDateString())
            setToDate(currentDate)
        }
    }, [orders]);

    const theme = createTheme({
        palette: {
            primary: {
                main: Colors.primary
            }
        }
    });

    const handleFromDateChange = (date) => {
        setIsDateChanged(true)
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setIsDateChanged(true)
        setToDate(date);
    };

    useEffect(() => {
        if (isDateChanged) {
            const filteredDataTemp = orders.filter(order => {
                const orderedAtDate = new Date(order.orderedAt);
                return fromDate.getTime() <= orderedAtDate.getTime() && toDate.getTime() >= orderedAtDate.getTime();
            })

            if (filteredDataTemp.length > 0) {
                setFilteredData(filteredDataTemp)
            } else {
                setFilteredData([])
            }
            setIsDateChanged(false)
        }
    }, [fromDate, isDateChanged, toDate]);

    return (
        <div>
            <div className={classes.container}>
                <div className={'search-bar'}>
                    <ImSearch className={'icon'} />
                    <input onChange={e => setSearchValue(e.target.value)} value={searchValue} onKeyDown={handleKeyDown}
                        placeholder={'Type And Enter'} />
                    <IoMdClose className={'icon'} onClick={onClearClick} />
                </div>
                <div className={'date-con'}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiThemeProvider theme={theme}>
                            <div className={classes.dateContainer}>
                                <div className={'date-picker'}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        format="dd/MM/yyyy"
                                        value={fromDate}
                                        label="From Date"
                                        // minDate={new Date(orders.length > 0 && orders[orders.length - 1].orderedAt)}
                                        onChange={handleFromDateChange}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    />
                                </div>
                                <div className={'date-picker'}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        format="dd/MM/yyyy"
                                        value={toDate}
                                        label="To Date"
                                        // maxDate={new Date(orders.length > 0 && orders[0].orderedAt)}
                                        onChange={handleToDateChange}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    />
                                </div>
                            </div>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <button onClick={() => setFilteredData(orders)}>All</button>
                {
                    deliveryStatus.map(d => (
                        <button onClick={() => onDeliveryFilterClick(d.name)} key={d.name} style={{ background: d.color }}>{getDeliveryStatus(d.name)}</button>
                    ))
                }
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
        </div>
    );
};

export default OrdersFilter;
