import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { ImSearch, IoMdClose } from "react-icons/all";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import { Colors } from "../../../helpers/Color";
import axios from "axios";
import { setProduct } from "../../../redux/slice/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/slice/IsLoginSlice";
import CustomToast from "../../../helpers/Toast";
import { useMediaQuery } from "@material-ui/core";
import { backendUrl, etabxServerUrl } from "../../../../index";

const ProductFilter = ({ setLoading, setFilteredData }) => {

    const etabxApiUrl = `${etabxServerUrl}/ShopaidService.svc`;

    const [selectedDate, setSelectedDate] = useState(new Date());

    const dispatch = useDispatch();

    const products = useSelector((state) => state.Products.value)
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const handleDateChange = (date) => {
        setLoading(true)
        setSelectedDate(date);
        const dateString = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        const bodyData = {
            Params: {
                Location: "0",
                DateFilter: dateString,
                ChannelTypeCode: "1"
            }
        }
        const token = localStorage.getItem('Access_Token')
        axios.defaults.baseURL = '';
        axios.post(etabxApiUrl + '/ProcessData', bodyData, {
            headers: {
                SERVICE_METHODNAME: 'Getproducts',
                Authorization: token
            }
        }).then(res => {
            // dispatch(setProduct(res.data.Response.Data.Product))
            dispatch(setProduct(res.data.Response.Data.ProductDetails.Products))
            setLoading(false)
            axios.defaults.baseURL = backendUrl;
        }).catch(err => {
            console.log(err.data)
            dispatch(logoutUser)
            localStorage.clear();
            setLoading(false)
            axios.defaults.baseURL = backendUrl;
        })
    };

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 25px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .search-bar': {
                display: 'flex',
                width: is430pxBelow ? '100%' : '50%',
                margin: is430pxBelow ? '0 0 15px' : '0',
                alignItems: 'center',
                border: `1px solid ${Colors.primary}`,
                borderRadius: '5px',
                padding: '10px',
                background: Colors.primaryLight,
                '& .icon': {
                    fontSize: '22px',
                    color: Colors.darkText
                },
                '& input': {
                    width: '100%',
                    '&:focus': {
                        outline: 0
                    },
                    margin: '0 10px',
                    border: `0`,
                    background: Colors.primaryLight,
                }
            },
            '& .date-picker': {
                border: `1px solid ${Colors.primary}`,
                padding: '0 10px',
                background: Colors.primaryLight,
                borderRadius: '5px',
                '& .MuiIconButton-root': {
                    padding: '0 !important'
                },
                '& .MuiSvgIcon-root': {
                    color: Colors.primary
                },
                '& .MuiFormControl-marginNormal': {
                    marginTop: is430pxBelow ? '4px !important' : '6px !important',
                    marginBottom: is430pxBelow ? '4px !important' : '6px !important',
                }
            }
        }
    }))

    const classes = useStyles()
    const [searchValue, setSearchValue] = useState('');

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setLoading(true);
            const filteredData = products.filter(item => item.ProductName.toLowerCase().includes(searchValue.toLowerCase()))
            console.log(filteredData, "filteredDatafilteredDatasafdasfsa");
            if (filteredData.length === 0) {
                setErrorMessage('Item Not Found')
                setFilteredData([]);
                setOpenError(true)
                setLoading(false);
            } else {
                setFilteredData(filteredData);
                setLoading(false);
            }
        }
    }

    const onClearClick = () => {
        setFilteredData([])
        setSearchValue('')
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.container}>
                <div className={'search-bar'}>
                    <ImSearch className={'icon'} />
                    <input onChange={e => setSearchValue(e.target.value)} value={searchValue} onKeyDown={handleKeyDown} placeholder={'Type And Enter'} />
                    <IoMdClose className={'icon'} onClick={onClearClick} />
                </div>
                <div className={'date-picker'}>
                    <KeyboardDatePicker
                        margin="normal"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        maxDate={new Date()}
                        onChange={handleDateChange}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                </div>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
        </MuiPickersUtilsProvider>
    );
};

export default ProductFilter;
