import React, { useEffect, useState } from 'react';
import {
    createTheme,
    FormControl,
    FormControlLabel,
    makeStyles,
    MuiThemeProvider, Radio,
    RadioGroup,
    useMediaQuery
} from "@material-ui/core";
import { Colors } from "../../../../helpers/Color";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { IoMdCloudUpload } from "react-icons/all";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";
import { SketchPicker } from "react-color";
import connection from 'components/app/auth/api';

const AddAdsBanner = () => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const defaultData = {
        smallImage: null,
        largeImage: null,
        name: '',
        description: '',
        offer: 0,
        color: '#4d4d4d',
        category: '',
        isGeneralCategory: true
    }

    const [banner, setBanner] = useState(defaultData);
    const [isColorVisible, setIsColorVisible] = useState(false);

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: `1px solid ${Colors.primary}`,
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                },
                '& .upload-logo': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '30px 0 0',
                    borderRadius: '5px',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon': {
                            color: Colors.primary
                        },
                        '& h6': {
                            color: Colors.primary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .logo-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '100px',
                    margin: '20px 10px 0',
                    background: Colors.light,
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            }
        },
        checkBoxContainer: {
            margin: '20px 0 15px',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
        },
        colorContainer: {
            position: 'relative',
            margin: '15px 0 10px',
            '& .color-c': {
                display: 'flex',
                alignItems: 'center',
                '& .circle': {
                    width: '50px',
                    height: '50px',
                    marginLeft: '-4px',
                    borderRadius: '100px',
                    border: '0',
                    background: banner.color
                },
                '& h6': {
                    margin: '0 10px',
                    fontSize: '14px',
                    padding: '6px',
                    background: '#f2f2f2',
                    borderRadius: '10px',
                    border: '1px solid #bfbfbf'
                }
            }
        },
        colorPicker: {
            position: 'absolute',
            zIndex: 15,
            margin: '5px 0',
        }
    }))

    const classes = useStyles();

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const [parentCategoryOptions, setParentCategoryOptions] = useState([]);
    const [specialityCategoryOptions, setSpecialityCategoryOptions] = useState([]);

    useEffect(() => {
        Promise.all([getGeneralCategory(), getSpecialityCategory()]).then(result => {
            setParentCategoryOptions(result[0].data.data)
            setSpecialityCategoryOptions(result[1].data.data)
        })
    }, [])

    function getGeneralCategory() {
        return connection.get('/get/generalCategories?limit=250');
    }

    function getSpecialityCategory() {
        return connection.get('/get/specialityCategories?limit=250');
    }

    const handleDataChange = (e) => {
        setBanner({ ...banner, [e.target.name]: e.target.value })
    }

    const onUploadSmallImageClick = (e) => {
        setBanner({ ...banner, smallImage: e.target.files[0] });
    }

    const onUploadLargeImageClick = (e) => {
        setBanner({ ...banner, largeImage: e.target.files[0] });
    }

    const history = useHistory();

    const onUploadClick = () => {
        const { name, description, smallImage, largeImage, isGeneralCategory, category, offer, color } = banner
        if (name !== '' && description !== '' && smallImage !== null && largeImage !== null && category !== '') {
            if (offer !== 0) {
                setOpen(true)
                const data = new FormData()
                data.append('smallImage', smallImage)
                data.append('largeImage', largeImage)
                data.append('data', JSON.stringify({ name, description, category, offer, isGeneralCategory, color }))
                connection.post('/post/adsBanner', data).then(res => {
                    if (res.data.key === 'success') {
                        setOpen(false)
                        history.goBack()
                    } else {
                        setErrorMessage(res.data.message)
                        setOpen(false)
                        setOpenError(true)
                    }
                }).catch(err => {
                    console.log(err)
                    setOpen(false)
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                })
            } else {
                setErrorMessage('Offer Should not be zero')
                setOpenError(true)
            }
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    const onParentCategoryChange = (e) => {
        setBanner({ ...banner, category: e.target.value })
    }

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    const onColorChange = (color) => {
        setBanner({ ...banner, color: color.hex })
        setIsColorVisible(false)
    }

    return (
        <div className={classes.container}>
            <h5>Add Ads Banner</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Name</h6>
                    <input value={banner.name} name={'name'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input value={banner.description} name={'description'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Offer</h6>
                    <input value={banner.offer} name={'offer'} type={'number'} onChange={handleDataChange} />
                </div>
                <div className={'mt-4'}>
                    <h6>Color</h6>
                    <div className={classes.colorContainer}>
                        <div className={'color-c'}>
                            <button className={'circle'} onClick={() => setIsColorVisible(prevState => !prevState)} />
                            <h6>{banner.color}</h6>
                        </div>
                        {
                            isColorVisible ? (
                                <SketchPicker color={banner.color} className={classes.colorPicker} onChangeComplete={onColorChange} />
                            ) : <></>
                        }
                    </div>
                </div>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.checkBoxContainer}>
                        <h5>Product Type</h5>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="product-type" name="product-type">
                                <FormControlLabel checked={banner.isGeneralCategory} control={<Radio />}
                                    onClick={() => setBanner({
                                        ...banner,
                                        isGeneralCategory: true,
                                        category: "",
                                    })}
                                    label="General Product" />
                                <FormControlLabel checked={!banner.isGeneralCategory}
                                    onClick={() => setBanner({
                                        ...banner,
                                        isGeneralCategory: false,
                                        category: ""
                                    })}
                                    control={<Radio />} label="Speciality Product" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </MuiThemeProvider>
                <div className={'select-container'}>
                    <h6>Category</h6>
                    <select name={'parentCategory'} value={banner.category} onChange={onParentCategoryChange}>
                        <option value="" disabled selected>Category</option>
                        {
                            (banner.isGeneralCategory ? parentCategoryOptions : specialityCategoryOptions).map((pCOptions, index) => (
                                <option value={pCOptions.name}>{pCOptions.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={'upload-logo'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'small-image-picker'}><h6>UPLOAD SMALL IMAGE</h6></label>
                        <input id={'small-image-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            multiple={false}
                            onChange={onUploadSmallImageClick} />
                    </div>
                </div>
                {
                    banner.smallImage !== null ?
                        <div className={'logo-container'}>
                            <img alt={'logo'} src={URL.createObjectURL(banner.smallImage)} />
                        </div> : <></>
                }
                <div className={'upload-logo'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'large-image-picker'}><h6>UPLOAD LARGE IMAGE</h6></label>
                        <input id={'large-image-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            multiple={false}
                            onChange={onUploadLargeImageClick} />
                    </div>
                </div>
                {
                    banner.largeImage !== null ?
                        <div className={'logo-container'}>
                            <img alt={'logo'} src={URL.createObjectURL(banner.largeImage)} />
                        </div> : <></>
                }
            </div>
            <div className={'upload'} onClick={onUploadClick}>
                Upload
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default AddAdsBanner;
