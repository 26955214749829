import React, { useState } from 'react';
import {
    createTheme,
    FormControl,
    FormControlLabel,
    FormLabel,
    MuiThemeProvider,
    Radio,
    RadioGroup,
    useMediaQuery
} from "@material-ui/core";
import { WithContext as ReactTags } from 'react-tag-input';
import { pathOr } from 'ramda'
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";

const AddProductType = ({ product, setProduct }) => {
    const [saltComposition, setSaltComposition] = useState(pathOr([], ['saltComposition'], product).map(salt => ({ id: salt, value: salt })))
    const [tags, setTags] = useState(pathOr([], ['tags'], product).map(tag => ({ id: tag, value: tag })))
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const useStyles = makeStyles(() => ({
        container: {
            margin: '30px 0 0',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
        }
    }))

    const classes = useStyles();

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });
    const handleDelete = i => {
        setSaltComposition(saltComposition.filter((tag, index) => index !== i));
        setProduct({
            ...product,
            saltComposition: product.saltComposition.filter((tag, index) => index !== i)
        })
    };

    const handleAddition = val => {
        setSaltComposition([...saltComposition, val]);
        setProduct({
            ...product,
            saltComposition: [...product.saltComposition, val.id]
        })
    };

    const handleTagDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
        setProduct({
            ...product,
            tags: product.tags.filter((tag, index) => index !== i)
        })
    };

    const handleTagAddition = val => {
        setTags([...tags, val]);
        setProduct({
            ...product,
            tags: [...product.tags, val.id]
        })
    };
    return (
        <MuiThemeProvider theme={theme}>
            <div style={{ display: 'flex', gap: '100px' }}>
                <div className={classes.container}>
                    <h5>Cold Chain Required</h5>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="product-type" name="product-type">
                            <FormControlLabel checked={product.isColdChainNeeded} control={<Radio />}
                                onClick={() => setProduct({
                                    ...product,
                                    isColdChainNeeded: true,
                                })}
                                label="Yes" />
                            <FormControlLabel checked={!product.isColdChainNeeded}
                                onClick={() => setProduct({
                                    ...product,
                                    isColdChainNeeded: false,
                                })}
                                control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.container}>
                    <h5>Is Prescription Required</h5>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="product-type" name="product-type">
                            <FormControlLabel checked={product.isPrescriptionNeeded} control={<Radio />}
                                onClick={() => setProduct({
                                    ...product,
                                    isPrescriptionNeeded: true,
                                })}
                                label="Yes" />
                            <FormControlLabel checked={!product.isPrescriptionNeeded}
                                onClick={() => setProduct({
                                    ...product,
                                    isPrescriptionNeeded: false,
                                })}
                                control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>

            </div>

            <div style={{ display: 'flex', gap: '100px' }}>
                <div className={classes.container}>
                    <h5>Salt Composition</h5>
                    <FormControl component="fieldset">
                        <ReactTags tags={saltComposition}
                            suggestions={[]}
                            labelField={'value'}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            allowUnique={true}
                            autofocus={false}
                        />
                    </FormControl>
                </div>
                <div className={classes.container}>
                    <h5>Tags</h5>
                    <FormControl component="fieldset">
                        <ReactTags tags={tags}
                            suggestions={[]}
                            labelField={'value'}
                            delimiters={delimiters}
                            handleDelete={handleTagDelete}
                            handleAddition={handleTagAddition}
                            allowUnique={true}
                            autofocus={false}
                        />
                    </FormControl>
                </div>
            </div>
        </MuiThemeProvider>
    );
};

export default AddProductType;
