import React, { useEffect, useState } from 'react';
import {
    createTheme,
    FormControl,
    FormControlLabel,
    makeStyles, MuiThemeProvider,
    Radio,
    RadioGroup,
    useMediaQuery
} from "@material-ui/core";
import { Colors } from "../../../../helpers/Color";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { IoMdCloudUpload } from "react-icons/all";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";
import connection from 'components/app/auth/api';

const AddCarouselBanner = () => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: `1px solid ${Colors.primary}`,
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                },
                '& .upload-logo': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon': {
                            color: Colors.primary
                        },
                        '& h6': {
                            color: Colors.primary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .logo-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '100px',
                    margin: '0 10px',
                    background: Colors.light,
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            }
        },
        checkBoxContainer: {
            margin: '20px 0 15px',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
        }
    }))

    const classes = useStyles();

    const defaultData = { image: null, name: '', description: '', category: '', isGeneralCategory: true }

    const [banner, setBanner] = useState(defaultData);

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const [parentCategoryOptions, setParentCategoryOptions] = useState([]);
    const [specialityCategoryOptions, setSpecialityCategoryOptions] = useState([]);

    useEffect(() => {
        Promise.all([getGeneralCategory(), getSpecialityCategory()]).then(result => {
            setParentCategoryOptions(result[0].data.data)
            setSpecialityCategoryOptions(result[1].data.data)
        })
    }, [])

    function getGeneralCategory() {
        return connection.get('/get/generalCategories?limit=250');
    }

    function getSpecialityCategory() {
        return connection.get('/get/specialityCategories?limit=250');
    }

    const handleDataChange = (e) => {
        setBanner({ ...banner, [e.target.name]: e.target.value })
    }

    const onUploadLogoClick = (e) => {
        setBanner({ ...banner, image: e.target.files[0] });
    }

    const history = useHistory();

    const onUploadClick = () => {
        const { name, description, image, isGeneralCategory, category } = banner
        if (name !== '' && description !== '' && image !== null && category !== '') {
            setOpen(true)
            const data = new FormData()
            data.append('image', image)
            data.append('data', JSON.stringify({ name, description, category, isGeneralCategory }))
            connection.post('/post/carouselBanner', data).then(res => {
                if (res.data.key === 'success') {
                    setOpen(false)
                    history.goBack()
                } else {
                    setErrorMessage(res.data.message)
                    setOpen(false)
                    setOpenError(true)
                }
            }).catch(err => {
                console.log(err)
                setOpen(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    const onParentCategoryChange = (e) => {
        setBanner({ ...banner, category: e.target.value })
    }

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    return (
        <div className={classes.container}>
            <h5>Add Carousel Banner</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Name</h6>
                    <input value={banner.name} name={'name'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input value={banner.description} name={'description'} onChange={handleDataChange} />
                </div>

                <MuiThemeProvider theme={theme}>
                    <div className={classes.checkBoxContainer}>
                        <h5>Product Type</h5>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="product-type" name="product-type">
                                <FormControlLabel checked={banner.isGeneralCategory} control={<Radio />}
                                    onClick={() => setBanner({
                                        ...banner,
                                        isGeneralCategory: true,
                                        category: "",
                                    })}
                                    label="General Product" />
                                <FormControlLabel checked={!banner.isGeneralCategory}
                                    onClick={() => setBanner({
                                        ...banner,
                                        isGeneralCategory: false,
                                        category: ""
                                    })}
                                    control={<Radio />} label="Speciality Product" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </MuiThemeProvider>
                <div className={'select-container'}>
                    <h6>Category</h6>
                    <select name={'parentCategory'} value={banner.category} onChange={onParentCategoryChange} >
                        <option value="" disabled selected>Category</option>
                        {
                            (banner.isGeneralCategory ? parentCategoryOptions : specialityCategoryOptions).map((pCOptions, index) => (
                                <option value={pCOptions.name}>{pCOptions.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={'upload-logo'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'logo-picker'}><h6>UPLOAD THUMBNAIL</h6></label>
                        <input id={'logo-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            multiple={false}
                            onChange={onUploadLogoClick} />
                    </div>
                </div>
                {
                    banner.image !== null ?
                        <div className={'logo-container'}>
                            <img alt={'logo'} src={URL.createObjectURL(banner.image)} />
                        </div> : <></>
                }
            </div>
            <div className={'upload'} onClick={onUploadClick}>
                Upload
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default AddCarouselBanner;
