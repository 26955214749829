import { createSlice } from '@reduxjs/toolkit'

export const ProductSlice = createSlice({
    name: 'Products',
    initialState: {
        value: []
    },
    reducers: {
        setProduct: (state, action) => {
            state.value = action.payload
        },
        clearProducts: (state) => {
            state.value = []
        }
    },
})

export const { setProduct, clearProducts } = ProductSlice.actions

export default ProductSlice.reducer
