import React, { useEffect, useState } from 'react';
import { Draggable } from "react-drag-reorder";
import axios from "axios";
import AllCategories from "./section/AllCategories";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import DraggingCategory from "./section/DraggingCategory";
import { Colors } from "../../helpers/Color";
import connection from '../auth/api';

const MainBarCategories = () => {

    const [categories, setCategories] = useState([]);

    const [allCategories, setAllCategories] = useState([])

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is340pxBelow = useMediaQuery('(max-width:340px)')

    useEffect(() => {
        Promise.all([getGeneralCategories(), getSpecialityCategories(), getMainBarCategories()]).then(results => {
            const generalCategories = results[0].data;
            const specialityCategories = results[1].data;
            let categoriesTemp = []
            let lastIndex = 0
            generalCategories.forEach((category, index) => {
                categoriesTemp.push({ ...category, order: index })
                lastIndex = index
            })
            lastIndex++
            specialityCategories.forEach((category, index) => {
                categoriesTemp.push({ ...category, order: lastIndex + index })
            })
            setCategories(results[2].data)
            setAllCategories(categoriesTemp)
        })
    }, []);

    function getGeneralCategories() {
        return connection.get('/get/generalCategories?limit=250')
    }

    function getSpecialityCategories() {
        return connection.get('/get/specialityCategories?limit=250')
    }

    function getMainBarCategories() {
        return connection.get('/get/mainBarCategories')
    }

    const useStyles = makeStyles(({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            '& .all-categories': {
                order: is430pxBelow ? 2 : 1,
                marginTop: is430pxBelow ? '15px' : '0',
                width: is1025pxBelow ? (is430pxBelow ? '100%' : '46%') : '49%',
                // background: Colors.primaryLight
            },
            '& .drag-category': {
                order: is430pxBelow ? 1 : 2,
                width: is1025pxBelow ? (is430pxBelow ? '100%' : '42%') : '49%',
                // background: Colors.secondaryLight
            }
        }
    }))

    const classes = useStyles()



    return (
        <div>
            <div className={classes.container}>
                <div className={'all-categories'}>
                    <AllCategories allCategories={allCategories}
                        categories={categories}
                        setCategories={setCategories} />
                </div>
                <div className={'drag-category'}>
                    <DraggingCategory setCategories={setCategories} categories={categories} />
                </div>
            </div>
        </div>
    );
};

export default MainBarCategories;
