import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../helpers/Color";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CarouselBanner from "./sections/carouselBanner";
import AdsBanner from "./sections/adsBanner";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import PageBanner from './sections/pageBanner';
import CustomToast from 'components/helpers/Toast';
import { notification } from 'antd';
import connection from '../auth/api';

const Banners = () => {

    const useStyles = makeStyles(({
        container: {
            '& .banner-container': {
                margin: '15px',
                '& .header': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& h5': {
                        margin: '0',
                        color: Colors.darkText,
                    },
                    '& .select-banner': {
                        border: `1px solid ${Colors.primary}`,
                        color: Colors.primary,
                        padding: '6px 12px',
                        fontWeight: 'bold',
                        background: Colors.light,
                        transition: 'all .3s',
                        '&:hover': {
                            transition: 'all .3s',
                            background: Colors.primary,
                            color: Colors.light,
                        },
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }
                },
            }
        }
    }))

    const classes = useStyles();

    const [adsBanners, setAdsBanners] = useState([]);
    const [carouselBanners, setCarouselBanners] = useState([]);
    const [pageBanner, setPageBanner] = useState([])

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('')
    const [openError, setOpenError] = useState(false)

    useEffect(() => {
        Promise.all([getCarouselBanners(), getAdsBanners()]).then(results => {
            setCarouselBanners(results[0].data.data)
            setAdsBanners(results[1].data.data)
            setLoading(false)
        })
        getPageBanners()
    }, [])


    function getCarouselBanners() {
        return connection.get('/get/carouselBanner');
    }

    function getAdsBanners() {
        return connection.get('/get/adsBanner');
    }

    function getPageBanners() {
        connection.get('/get/pageBanners')
            .then((res) => {
                setPageBanner(res.data.data)
            })
    }

    const onVisibleChange = (row, index, value) => {

        let tempPageBanner = pageBanner
        tempPageBanner[index] = { ...tempPageBanner[index], isEnabled: value };
        setPageBanner(tempPageBanner)

        const { title, description, _id } = row
        if (title !== '' && description !== '') {
            setLoading(true)
            let data = {
                data: {
                    pageBannerId: _id,
                    title,
                    description,
                    isEnabled: value
                }
            }
            connection.put('/update/pageBanner', data).then(res => {
                if (res.data.key === 'success') {
                    setLoading(false)
                    notification.success({
                        message: 'Changed',
                        description: value ? 'Banner Enabled' : 'Banner Disabled'
                    })
                } else {
                    setErrorMessage(res.data.message)
                    setLoading(false)
                    setOpenError(true)
                }
            }).catch(err => {
                setLoading(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    return (
        <div className={classes.container}>
            <CarouselBanner carouselBanners={carouselBanners} setCarouselBanners={setCarouselBanners} />
            <AdsBanner adsBanners={adsBanners} setAdsBanners={setAdsBanners} />
            <PageBanner PageBanner={pageBanner} setPageBanner={setPageBanner} onVisibleChange={onVisibleChange} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />

        </div>
    );
};

export default Banners;
