import React, { useMemo, useState } from 'react';
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import CustomToast from "../../../helpers/Toast";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import JoditEditor from "jodit-react";
import EditVarient from './EditVarient';
import connection from 'components/app/auth/api';

const EditShortAndLongDescription = ({ product, setProduct }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            marginBottom: '50px',
            '& .quill-con': {
                width: is430pxBelow ? '100%' : '50%',
                '& h5': {
                    color: Colors.primary,
                    fontSize: '20px',
                    margin: '60px 0 20px'
                },
                '& .quill': {
                    height: '150px',
                }
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& div': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: '90%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                }
            }
        }
    }))

    const classes = useStyles()

    const config = useMemo(() => ({
        readonly: false,
        allowTabNavigation: true,
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false
    }), [])

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleShortDescriptionChange = (value) => {
        setProduct({ ...product, shortListDescription: value })
    }

    const handleLongDescriptionChange = (value) => {
        setProduct({ ...product, longListDescription: value })
    }

    const handleInstructionsChange = (value) => {
        setProduct({ ...product, instructions: value })
    }

    const handleSideEffectsChange = (value) => {
        setProduct({ ...product, sideEffects: value })
    }

    const history = useHistory();


    const onUploadClick = () => {
        let bannerImages = []
        const {
            _id, name, thumbnailUrl, description, thumbnail, isThumbnailEdited, galleryImagesAdded, manufacturerName, galleryImages,
            purchasedPrice, departmentName, discount, generalCategory, generalSubCategory, isGeneralProduct, salesPrice,
            specialityCategory, specialitySubCategory, totalStocks, shortListDescription, pair,
            galleryImagesToBeRemoved, longListDescription, isColdChainNeeded, isPrescriptionNeeded, saltComposition, tags,
            metaDescription, metaTitle, metaAltText, keywords, brand, packageSize, stockAlertThreshold, attributes, variants, productType, group, urlSlug
        } = product

        bannerImages = [...galleryImages].map((data) => data.id)

        const variantdData = variants.map(item => ({
            ...item,
            displayValues: {
                ...item.displayValues,
                galleryImages: item.displayValues.galleryImages.map(image => image.id)
            }
        }));

        console.log(group, "popopopop");

        if (discount !== '' && manufacturerName !== '' &&
            shortListDescription !== '' && longListDescription !== '' && group) {
            setOpen(true)
            let formData = new FormData()

            if (isThumbnailEdited) {
                formData.append('thumbnail', thumbnail)
            }

            if (galleryImagesAdded.length !== 0) {
                galleryImagesAdded.forEach(gImage => {
                    formData.append('galleryImages', gImage)
                })
            }

            // formData.append('data', JSON.stringify({
            let params = {
                id: _id,
                name,
                description,
                manufacturerName,
                galleryImagesToBeRemoved,
                purchasedPrice,
                departmentName,
                discount,
                isGalleryImageAdded: galleryImagesAdded.length !== 0,
                salesPrice,
                totalStocks,
                isThumbnailEdited,
                pair,
                specialityCategory,
                specialitySubCategory,
                generalCategory,
                generalSubCategory,
                shortListDescription,
                isGeneralProduct,
                instructions: product.instructions ? product.instructions : "",
                sideEffects: product.sideEffects ? product.sideEffects : "",
                // oldThumbnailUrl: thumbnailUrl,
                thumbnailUrl: thumbnail?.id,
                galleryImages: bannerImages,
                longListDescription,
                isColdChainNeeded,
                isPrescriptionNeeded,
                saltComposition,
                tags,
                metaDescription,
                metaTitle,
                metaAltText,
                keywords,
                brand,
                packageSize,
                stockAlertThreshold: parseInt(stockAlertThreshold),
                attributes,
                variants: variantdData,
                productType,
                group,
                urlSlug
            }

            console.log(params, "koooooooooooooooooo");

            // }))


            connection.put('/update/product', { data: params }).then(res => {
                history.goBack()
                setOpen(false)
            }).then(err => {
                console.log(err)
                setOpen(false)
            })

        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    return (
        <div className={classes.container}>
            <div style={{ display: 'flex', gap: '30px' }}>

                <div className={'quill-con'}>
                    <h5>Short Description</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}} value={product.shortListDescription}*/}
                    {/*            onChange={handleShortDescriptionChange}/>*/}
                    <JoditEditor
                        value={product.shortListDescription}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleShortDescriptionChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleShortDescriptionChange(newContent)}
                    />
                </div>
                <div className={'quill-con'}>
                    <h5>Long Description</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}} value={product.longListDescription}*/}
                    {/*            onChange={handleLongDescriptionChange}/>*/}
                    <JoditEditor
                        value={product.longListDescription}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleLongDescriptionChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleLongDescriptionChange(newContent)}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', gap: '30px' }}>
                <div className={'quill-con'}>
                    <h5 >Instructions</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}}*/}
                    {/*            value={product.instructions ? product.instructions : ""}*/}
                    {/*            onChange={handleInstructionsChange}/>*/}
                    <JoditEditor
                        value={product.instructions ? product.instructions : ""}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleInstructionsChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleInstructionsChange(newContent)}
                    />
                </div>
                <div className={'quill-con'}>
                    <h5 >Side Effects</h5>
                    {/*<ReactQuill styles={{maxHeight: '200px'}}*/}
                    {/*            value={product.sideEffects ? product.sideEffects : ""}*/}
                    {/*            onChange={handleSideEffectsChange}/>*/}
                    <JoditEditor
                        value={product.sideEffects ? product.sideEffects : ""}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => handleSideEffectsChange(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => handleSideEffectsChange(newContent)}
                    />
                </div>

            </div>
            <div style={{ marignTop: '50px' }}>
                <h5>SEO</h5>
                <div className={'input-container'}>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ flex: 1 }}>
                            <h6>Meta Title</h6>
                            <input value={product.metaTitle} name={'metaTitle'} onChange={e => setProduct({ ...product, metaTitle: e.target.value })} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <h6>Meta Description</h6>
                            <input value={product.metaDescription} name={'metaDescription'} onChange={e => setProduct({ ...product, metaDescription: e.target.value })} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ flex: 1 }}>
                            <h6>Meta Alt Text</h6>
                            <input value={product.metaAltText} name={'metaAltText'} onChange={e => setProduct({ ...product, metaAltText: e.target.value })} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <h6>Keywords</h6>
                            <input value={product.keywords} name={'keywords'} onChange={e => setProduct({ ...product, keywords: e.target.value })} />
                        </div>
                    </div>
                </div>
            </div>
            <EditVarient product={product} setProduct={setProduct} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
                <div className={'upload'} onClick={onUploadClick} style={{ width: '20%' }}>
                    Upload
                </div>
            </Box>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default EditShortAndLongDescription;
