import React, { useState } from 'react';
import {
    createTheme,
    FormControl,
    FormControlLabel,
    MuiThemeProvider,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { WithContext as ReactTags } from 'react-tag-input';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { pathOr } from 'ramda';

const EditProductType = ({ product, setProduct }) => {
    const [saltComposition, setSaltComposition] = useState(pathOr([], ['saltComposition'], product).map(salt => ({ id: salt, value: salt })))
    const [tags, setTags] = useState(pathOr([], ['tags'], product).map(tag => ({ id: tag, value: tag })))
    const KeyCodes = {
        comma: 188,
        enter: 13,
        TAB: 9,
        SPACE: 32,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.TAB, KeyCodes.SPACE];

    const useStyles = makeStyles(() => ({
        container: {
            margin: '30px 0 0',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .ReactTags__tags ': {
                position: 'relative',
            },
            '& .ReactTags__clearAll': {
                cursor: 'pointer',
                padding: '10px',
                margin: '10px',
                background: '#f88d8d',
                color: '#fff',
                border: 'none',
            },

            /* Styles for the input */
            '& .ReactTags__tagInput': {
                borderRadiuss: '2px',
                display: 'inline-block',
                margin: '10px',
            },

            ' & .ReactTags__editInput ': {
                borderRadiuss: '1px',
            },
            ' &.ReactTags__editTagInput': {
                display: 'inline-flex',
            },
            /* Styles for selected tags */
            '& .ReactTags__selected span.ReactTags__tag': {
                border: '1px solid #2086CF',
                background: Colors.primary,
                color: 'white', fontSize: '15px',
                display: 'inline-block',
                padding: '5px',
                margin: '0 5px',
                borderRadius: '20px',
            },
            '& .ReactTags__selected a.ReactTags__remove': {
                color: '#aaa',
                marginLeft: '5px',
                cursor: 'pointer',
            },

            /* Styles for suggestions */
            '& .ReactTags__suggestions': {
                position: 'absolute',
                '& ul': {
                    listStyleType: 'none',
                    boxShadow: '0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2)',
                    background: 'white',
                    width: '200px',
                },
            },
            '& .ReactTags__suggestions li': {
                borderBottom: '1px solid #ddd',
                padding: '5px 10px',
                margin: '0',
            },
            '& .ReactTags__suggestions li mark': {
                textDecoration: 'underline',
                background: 'none',
                fontWeight: '600',
            },
            '& .ReactTags__suggestions ul li.ReactTags__activeSuggestion': {
                background: '#b7cfe0',
                cursor: 'pointer',
            },
            '& .ReactTags__remove': {
                //borderRadius:'15px',
                border: 'none',
                cursor: 'pointer',
                background: 'none',
                color: 'white',
                fontSize: 'small',
                margin: 'inherit'
            },
            '& .ReactTags__tagInputField': {
                border: '1px solid #2086CF',
                padding: '8px',
                borderRadius: '5px',
                '&:focus': {
                    outline: 0
                }
            },

        },
    }))

    const classes = useStyles();

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });
    const handleDelete = i => {
        setSaltComposition(saltComposition.filter((tag, index) => index !== i));
        setProduct({
            ...product,
            saltComposition: product.saltComposition.filter((tag, index) => index !== i)
        })
    };

    const handleAddition = val => {
        setSaltComposition([...saltComposition, val]);
        setProduct({
            ...product,
            saltComposition: [...product.saltComposition, val.id]
        })
    };

    const handleTagDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
        setProduct({
            ...product,
            tags: product.tags.filter((tag, index) => index !== i)
        })
    };

    const handleTagAddition = val => {
        setTags([...tags, val]);
        setProduct({
            ...product,
            tags: [...product.tags, val.id]
        })
    };
    return (
        <MuiThemeProvider theme={theme}>
            <div style={{ display: 'flex', gap: '100px' }}>

                <div className={classes.container}>
                    <h5>Cold Chain Required</h5>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="product-type" name="product-type">
                            <FormControlLabel checked={product.isColdChainNeeded} control={<Radio />}
                                onClick={() => setProduct({
                                    ...product,
                                    isColdChainNeeded: true,
                                })}
                                label="Yes" />
                            <FormControlLabel checked={!product.isColdChainNeeded}
                                onClick={() => setProduct({
                                    ...product,
                                    isColdChainNeeded: false,
                                })}
                                control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.container}>
                    <h5>Is Prescription Required</h5>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="product-type" name="product-type">
                            <FormControlLabel checked={product.isPrescriptionNeeded} control={<Radio />}
                                onClick={() => setProduct({
                                    ...product,
                                    isPrescriptionNeeded: true,
                                })}
                                label="Yes" />
                            <FormControlLabel checked={!product.isPrescriptionNeeded}
                                onClick={() => setProduct({
                                    ...product,
                                    isPrescriptionNeeded: false,
                                })}
                                control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className={classes.container}>
                <h5>Salt Composition</h5>
                <FormControl component="fieldset">
                    <ReactTags tags={saltComposition}
                        suggestions={[]}
                        labelField={'value'}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        allowUnique={true}
                        autofocus={false}
                    />
                </FormControl>
            </div>
            <div className={classes.container}>
                <h5>Tags</h5>
                <FormControl component="fieldset">
                    <ReactTags tags={tags}
                        suggestions={[]} inputFieldPosition={'bottom'}
                        labelField={'value'}
                        delimiters={delimiters}
                        handleDelete={handleTagDelete}
                        handleAddition={handleTagAddition}
                        allowUnique={true}
                        autofocus={false}
                    />
                </FormControl>
            </div>
        </MuiThemeProvider>
    );
};

export default EditProductType;
