import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import OrdersFilter from "./section/OrdersFilter";
import OrdersTable from "./section/OrdersTable";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../redux/slice/OrderSlice";
import { useLocation } from "react-router-dom";
import connection from '../auth/api';

const Orders = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))
    const classes = useStyles()
    const [filteredData, setFilteredData] = useState([]);
    const [orders, setOrdersData] = useState([])
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [totalEntries, setTotalEntries] = useState(0)

    const dbOrdersRedux = useSelector((state) => state.Orders.value)

    const dispatch = useDispatch();

    useEffect(() => {
        const orderIdsTemp = [];
        dbOrdersRedux && dbOrdersRedux?.forEach(order => {
            orderIdsTemp.push(order._id)
        })
        connection.put('/update/ordersIsSeen', { data: orderIdsTemp }).then(res => {
            connection.get(`/get/orders`).then(res => {
                dispatch(setOrders(res.data.data))
                const toBeReverse = [...res.data.data];
                setOrdersData(toBeReverse.reverse())
                setFilteredData(toBeReverse.reverse())
                setLoading(false)
                setTotalEntries(res?.data?.totalCount)
            })
        })
    }, [dispatch])

    // useEffect(() => {
    //     connection.get(`/get/orders?page=${page + 1}&limit=${rowsPerPage}`).then(res => {
    //         dispatch(setOrders(res.data.data))
    //         const toBeReverse = [...res.data.data];
    //         setOrdersData(toBeReverse.reverse())
    //         setFilteredData(toBeReverse.reverse())
    //         setLoading(false)
    //         setTotalEntries(res?.data?.totalCount)

    //     })
    // }, [])



    // useEffect(() => {
    //     if (location.state) {
    //         const filter = orders.filter(item => item.deliveryStatus === location.state.filter)
    //         setFilteredData(filter)
    //     }
    // }, [location, orders]);

    const tableProps = {
        filteredData,
        orders,
        setFilteredData,
        setOrders,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalEntries,
        setLoading
    }

    return (
        <div className={classes.container}>
            <OrdersFilter setFilteredData={setFilteredData} orders={orders} setPage={setPage} />
            <OrdersTable {...tableProps} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default Orders;
