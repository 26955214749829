import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../../helpers/Color";
import {useHistory} from "react-router-dom";
import axios from "axios";
import CarouselBannerTable from "./section/CarouselBannerTable";

const CarouselBanner = ({carouselBanners, setCarouselBanners}) => {

    const useStyles = makeStyles(({
        container: {
            margin: '15px',
            '& .banner-container': {
                margin: '15px 0',
                '& .header': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& h5': {
                        margin: '0',
                        color: Colors.darkText,
                    },
                    '& .select-banner': {
                        border: `1px solid ${Colors.primary}`,
                        color: Colors.primary,
                        padding: '6px 12px',
                        fontWeight: 'bold',
                        background: Colors.light,
                        transition: 'all .3s',
                        '&:hover':{
                            transition: 'all .3s',
                            background: Colors.primary,
                            color: Colors.light,
                        },
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }
                },
            }
        }
    }))

    const classes = useStyles();

    const history = useHistory();

    return (
        <div className={classes.container}>
            <div className={'banner-container'}>
                <div className={'header'}>
                    <h5>Carousel Banners</h5>
                    <div className={'select-banner'} onClick={() => {history.push('/banners/add-carousel-banner')}}>
                        Add Banner
                    </div>
                </div>
            </div>
            <CarouselBannerTable carouselBanner={carouselBanners} setCarouselBanner={setCarouselBanners}/>
        </div>
    );
};

export default CarouselBanner;
