import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import UserInProductsTable from "./section/UserInProductsTable";
import { makeStyles } from "@material-ui/core";
import UserDetails from "./section/UserDetails";
import connection from '../auth/api';

const UserViewPage = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        connection.get(`/get/user?id=${id}`).then(res => {
            setUser(res.data.data)
            setLoading(false)
        })
    }, [id]);

    const useStyles = makeStyles({
        container: {
            display: 'flex',
            marginTop: '15px',
            justifyContent: 'space-between',
            '& .details-con': {
                width: '48%'
            },
            '& .table-con': {
                width: '50%'
            }
        }
    })

    const classes = useStyles();

    if (!loading) {
        return (
            <div className={classes.container}>
                <div className={'details-con'}>
                    <UserDetails user={user} />
                </div>
                <div className={'table-con'}>
                    <UserInProductsTable viewedProducts={user.viewedProducts} />
                </div>
            </div>
        );
    } else {
        return (
            <FullScreenProgress open={loading} setOpen={setLoading} />
        )
    }


};

export default UserViewPage;
