import { configureStore } from '@reduxjs/toolkit'
import IsLoginReducer from './slice/IsLoginSlice'
import ProductsReducer from './slice/ProductSlice'
import OrdersReducer from './slice/OrderSlice'

export default configureStore({
    reducer: {
        IsLogin: IsLoginReducer,
        Products: ProductsReducer,
        Orders: OrdersReducer
    },
})
