import React, { useEffect, useState } from 'react';
import { makeStyles, useMediaQuery } from "@material-ui/core";
import BigBanner from "./section/BigBanner";
import DetailsCard from "./section/DetailsCard";
import axios from "axios";
import { setOrders } from "../../../../redux/slice/OrderSlice";
import { useDispatch } from "react-redux";
import connection from 'components/app/auth/api';

const TodCardsProMax = () => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles({
        container: {
            display: 'grid',
            gridTemplateColumns: is770pxBelow ? '1fr' : '1fr 1fr',
            gridGap: '15px',
        }
    })
    const classes = useStyles()

    const [orders, setOrdersL] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [todayOrders, setTodayOrders] = useState([]);
    const [pendingOrders, setPendingOrders] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        connection.get('/get/orders').then(res => {
            const ordersRes = res.data.data;
            setOrdersL(ordersRes)
            console.log(ordersRes, "ordersres");
            dispatch(setOrders([ordersRes]))
            let completedOrdersTemp = [];
            let todayOrderTemp = [];
            let pendingOrderTemp = [];
            let totalEarningsTemp = 0;
            ordersRes.length > 0 && ordersRes?.forEach(order => {
                totalEarningsTemp += order.totalPrice;
                let todayDate = new Date();
                let orderDate = new Date(order.orderedAt);
                if (order.deliveryStatus === 'delivered') {
                    completedOrdersTemp.push(order)
                }
                if (order.deliveryStatus === 'pending') {
                    pendingOrderTemp.push(order)
                }
                if (orderDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
                    todayOrderTemp.push(order)
                }
            })
            setPendingOrders(pendingOrderTemp)
            setTodayOrders(todayOrderTemp)
            setCompletedOrders(completedOrdersTemp)
            setTotalEarnings(totalEarningsTemp)
        })
    }, [dispatch]);


    return (
        <div>
            <div className={classes.container}>
                <div>
                    <BigBanner totalEarnings={totalEarnings} />
                </div>
                <div>
                    <DetailsCard completedOrders={completedOrders} pendingOrders={pendingOrders}
                        orders={orders} todayOrders={todayOrders} />
                </div>
            </div>
        </div>
    );
};

export default TodCardsProMax;
