import React, { useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { AiOutlineEye, BiCheck, IoCloseSharp, RiDeleteBin6Line } from "react-icons/all";
import TablePagination from "@material-ui/core/TablePagination";
import connection from 'components/app/auth/api';

const UsersTable = ({ filteredData, users, setUsers, setFilteredData }) => {

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Email',
            align: 'left',
        },
        {
            name: 'Username',
            align: 'center',
        },
        {
            name: 'Mobile Number',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px',
            '& .checked': {
                fontSize: '22px',
                color: Colors.success,
            },
            '& .close': {
                fontSize: '20px',
                color: Colors.error,
            },
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
            },
            '& .view-icon': {
                fontSize: is430pxBelow ? '18px' : '24px',
                color: Colors.darkText,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
        },
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    console.log(filteredData.length !== 0, users, "jsdfjshdks", filteredData);

    const displayingUsers = filteredData.length !== 0 ? filteredData : users

    const history = useHistory();

    const onEditProductClick = (id) => {

    }

    const onDeleteProductClick = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                connection.delete('/delete/deleteUser', { data: { userId: id } }).then(res => {
                    console.log(res.data)
                    setFilteredData([])
                })
                Swal.fire(
                    'Deleted!',
                    'User has been deleted.',
                    'success'
                ).then(() => {
                    connection.get('/get/users').then(res => {
                        setUsers(res.data)
                    })
                })
            }
        })
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayingUsers.length > 0 && displayingUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{index + 1}</h6>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{row.email}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 className={classes.rowTextStyle}>{row.username}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 className={classes.rowTextStyle}>{row.mobileNumber}</h6>
                                    </TableCell>
                                    <TableCell align={'right'}>

                                        <div className={classes.actionContainer}>
                                            <AiOutlineEye onClick={() => history.push(`/users/${row._id}`)} className={'view-icon'} />
                                            <RiDeleteBin6Line className={'delete-icon'} onClick={() => onDeleteProductClick(row._id)} />
                                        </div> :

                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                displayingUsers.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={displayingUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default UsersTable;
