import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BrandHeader from './BrandHeader';
import BrandTable from './BrandTable';
import FullScreenProgress from 'components/helpers/FullScreenProgress';
import connection from '../auth/api';


function Brands() {
  const [brands, setBrands] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles(() => ({
    container: {
      margin: '20px 0'
    }
  }))

  useEffect(() => {
    connection.get('/get/brands?limit=250').then(res => {
      setBrands(res.data.data)
      setLoading(false)
    })
  }, [])

  const classes = useStyles()


  return (
    <div className={classes.container}>
      <BrandHeader brands={brands} setFilteredData={setFilteredData} />
      <BrandTable brands={brands} setBrands={setBrands}
        filteredData={filteredData} setFilteredData={setFilteredData} />
      <FullScreenProgress open={loading} setOpen={setLoading} />

    </div>
  );
};

export default Brands