import React, { useEffect, useState } from 'react';
import CategoriesHeader from "./section/CategoriesHeader";
import { makeStyles } from "@material-ui/core";
import CategoriesTable from "./section/CategoriesTable";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const Categories = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [categories, setCategories] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/generalCategories?limit=250').then(res => {
            setCategories(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <CategoriesHeader categories={categories} setFilteredData={setFilteredData} />
            <CategoriesTable categories={categories} setCategories={setCategories}
                filteredData={filteredData} setFilteredData={setFilteredData} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default Categories;
