import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../helpers/Color";

const OrderDetails = ({ order }) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            padding: is430pxBelow ? '20px 10px' : '20px',
            display: 'flex',
            justifyContent: 'space-between',
            '& .details': {
                '& .item': {
                    display: 'flex',
                    marginBottom: '15px',
                    '& .label': {
                        width: '140px',
                        margin: 0
                    },
                    '& .value': {
                        width: '300px',
                        color: Colors.primary,
                        margin: 0,
                    },
                },
            },
            '& .address': {
                width: '300px',
                '& h6': {

                },
            },
        }
    })

    const classes = useStyles();

    const { shippingAddress } = order

    const address = shippingAddress === "random address" ? "-" : `${shippingAddress?.doorNumber}, ${shippingAddress?.streetName}, ${shippingAddress?.landMark},${shippingAddress?.cityName} - ${shippingAddress?.pincode}, ${shippingAddress?.stateName}`
    console.log(shippingAddress, "shippingAddress");
    return (
        <div className={classes.container}>
            <div className={'details'}>
                <div className={'item'}>
                    <h6 className={'label'}>Name: </h6>
                    <h6 className={'value'}>{shippingAddress.name}</h6>
                </div>
                <div className={'item'}>
                    <h6 className={'label'}>Contact Number: </h6>
                    <h6 className={'value'}>{shippingAddress.contactNumber}</h6>
                </div>
                <div className={'item'}>
                    <h6 className={'label'}>Email: </h6>
                    <h6 className={'value'}>{shippingAddress.email}</h6>
                </div>
                <div className={'item'}>
                    <h6 className={'label'}>Address: </h6>
                    <h6 className={'value'}>{address}</h6>
                </div>
            </div>
            <div className={'address'}>
                {/*<h6>{address}</h6>*/}
            </div>
        </div>
    );
};

export default OrderDetails;
