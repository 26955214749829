import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { Dialog, Slide, useMediaQuery } from "@material-ui/core";
import { IoCloseCircle, IoSearch } from "react-icons/all";
import axios from "axios";
import CustomToast from "../../../helpers/Toast";
import EditPairProductTable from "./EditPairProductTable";
import connection from 'components/app/auth/api';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditPairProduct = ({ product, setProduct }) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)');
    const is770pxBelow = useMediaQuery('(max-width:770px)');
    const is430pxBelow = useMediaQuery('(max-width:430px)');
    const is380pxBelow = useMediaQuery('(max-width:380px)')
    const is325pxBelow = useMediaQuery('(max-width:325px)')

    const [open, setOpen] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [products, setProducts] = useState([]);

    const [loading, setLoading] = useState(true);

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const useStyles = makeStyles(() => ({
        container: {
            margin: '30px 0 0',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .select-product-btn': {
                outline: 0,
                background: Colors.primary,
                border: 0,
                color: Colors.light,
                borderRadius: 5,
                padding: '5px 10px',
                margin: '15px 0 0',
                fontSize: '14px'
            }
        },
        dialogContainer: {
            margin: '20px',
            '& .search-bar': {
                border: `1px solid ${Colors.darkBorder}`,
                display: 'flex',
                alignItems: 'center',
                padding: '6px',
                borderRadius: 5,
                '& .icon': {
                    fontSize: 20,
                    color: Colors.darkText
                },
                '& input': {
                    width: '90%',
                    border: 0,
                    marginLeft: '8px',
                    '&:focus': {
                        outline: 0
                    }
                }
            },
            '& .action-container': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '15px',
                '& .cancel': {
                    color: Colors.light,
                    background: Colors.error,
                    padding: '4px 12px',
                    cursor: 'pointer',
                    // fontWeight: 'bold',
                    borderRadius: 5,
                },
                '& .okay': {
                    color: Colors.light,
                    background: Colors.primary,
                    padding: '4px 12px',
                    cursor: 'pointer',
                    // fontWeight: 'bold',
                    marginLeft: '10px',
                    borderRadius: 5,
                },
            }
        },
        pairContainer: {
            border: `1px solid ${Colors.darkBorder}`,
            display: 'inline-block',
            width: '240px',
            margin: '15px 0 0',
            position: 'relative',
            borderRadius: 5,
            padding: '10px',
            '& .img-container': {
                width: '100px',
                margin: '0 auto 10px',
                height: '100px',
                // border: `1px solid ${Colors.darkBorder}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%'
                }
            },
            '& .description': {
                fontSize: '14px',
                color: Colors.darkText,
                fontWeight: 'bold'
            },
            '& .product-type': {
                fontSize: '14px',
                color: Colors.darkText,
                fontWeight: 'bold',
            },
            '& .category': {
                fontSize: '14px',
                color: Colors.darkText,
                fontWeight: 'bold',
                '& span': {
                    color: Colors.primary
                }
            },
            '& .sub-category': {
                fontSize: '14px',
                color: Colors.darkText,
                fontWeight: 'bold',
                '& span': {
                    color: Colors.secondary
                }
            },
            '& .delete': {
                fontSize: 24,
                color: Colors.error,
                position: 'absolute',
                top: -10,
                right: -10
            },
        }
    }))

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        connection.get('/get/allProducts').then(res => {
            setProducts(res.data)
            setLoading(false)
        })
    }, [])

    const onSearchChange = (e) => {
        if (!loading) {
            const filteredData = products.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))
            if (filteredData.length === 0) {
                setErrorMessage('Item Not Found')
                setFilteredData([]);
                setOpenError(true)
            } else {
                setFilteredData(filteredData);
            }
        }
    }

    const ProductCard = ({ p }) => {

        const { _id, thumbnailUrl, name, description, galleryImages, isGeneralProduct, generalCategory, generalSubCategory,
            specialityCategory, specialitySubCategory, purchasedPrice, totalStocks, discount, departmentName, salesPrice,
            shortListDescription, longListDescription } = p

        return (
            <div className={classes.pairContainer}>
                <div className={'img-container'}>
                    <img src={typeof thumbnailUrl === "string" ? thumbnailUrl : thumbnailUrl?.url} alt={name} />
                </div>
                <div>
                    <div className={'description'}>
                        {name}
                    </div>
                    <div className={'general-details'}>
                        <div className={'product-type'}>
                            Product Type : <span>{isGeneralProduct ? 'General Product' : 'Speciality Product'}</span>
                        </div>
                        <div className={'category'}>
                            Category : <span>{isGeneralProduct ? generalCategory : specialityCategory}</span>
                        </div>
                        <div className={'sub-category'}>
                            Sub Category : <span>{isGeneralProduct ?
                                (generalSubCategory !== '' && generalSubCategory !== null && generalSubCategory ? generalSubCategory : "Not Available") :
                                (specialitySubCategory !== "" && specialitySubCategory !== null && specialitySubCategory ? specialitySubCategory : "Not Available")}</span>
                        </div>
                    </div>
                </div>
                <IoCloseCircle onClick={() => {
                    setProduct({ ...product, pair: null })
                }} className={'delete'} />
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <h5>Paired With</h5>
            {
                !product.pair ?
                    <button onClick={() => setOpen(true)} className={'select-product-btn'}>Select Product</button> :
                    <ProductCard p={product.pair} />
            }
            <Dialog
                fullWidth={true}
                maxWidth={is1025pxBelow ? "xl" : "md"}
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={classes.dialogContainer}>
                    <div className={'search-bar'}>
                        <IoSearch className={'icon'} />
                        <input placeholder={'Search Product'} onChange={onSearchChange} contentEditable={false} />
                    </div>
                    <EditPairProductTable products={products} filteredData={filteredData} setProduct={setProduct} product={product} />
                    <div className={'action-container'}>
                        <div className={'cancel'} onClick={() => {
                            setProduct({ ...product, pair: null })
                            handleClose();
                        }}>
                            CANCEL
                        </div>
                        <div className={'okay'} onClick={handleClose}>
                            OKAY
                        </div>
                    </div>
                </div>
            </Dialog>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
        </div>
    );
};

export default EditPairProduct;
