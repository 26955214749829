import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AddProductInformation from "./section/AddProductInformation";
import AddProductImages from "./section/AddProductImages";
import AddProductPriceAndStock from "./section/AddProductPriceAndStock";
import AddShortAndLongDescription from "./section/AddShortAndLongDescription";
import AddCategoriesAndSubCategories from "./section/AddCategoriesAndSubCategories";
import AddProductType from "./section/AddProductType";
import AddSpecialityCategoriesAndSubCategories from "./section/AddSpecialityCategoriesAndSubCategories";
import AddPairProduct from "./section/AddPairProduct";
import AddPrescriptionNeeded from "./section/AddPrescriptionNeeded";

const AddProducts = () => {

    const { name } = useParams();

    const products = useSelector((state) => state.Products.value)

    const [product, setProduct] = useState({ pair: null });
    const history = useHistory();

    useEffect(() => {
        const filteredProduct = products.filter(item => item.ProductName === name);
        if (filteredProduct.length !== 0) {
            const productTemp = filteredProduct[0]

            const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
            const finalName = productTemp.ProductName ? productTemp.ProductName.replace(regex, '') : ""
            console.log("prodName", finalName)
            setProduct({
                name: finalName,
                description: productTemp.ProductDescription,
                purchasedPrice: productTemp.MRP,
                departmentName: productTemp.DepartmentName,
                manufacturerName: '',
                thumbnail: null,
                galleryImages: [],
                isGeneralProduct: true,
                pair: null,
                generalCategory: "",
                generalSubCategory: "",
                specialityCategory: "",
                specialitySubCategory: "",
                discount: 0,
                salesPrice: productTemp.MRP,
                shortListDescription: '',
                longListDescription: '',
                totalStocks: productTemp.TotalStock,
                instructions: '',
                sideEffects: '',
                isPrescriptionNeeded: false,
                saltComposition: [],
                isColdChainNeeded: false,
                tags: [],
                metaDescription: '',
                metaTitle: '',
                metaAltText: '',
                keywords: '',
                lowStock: 0,
                brand: null,
                packageSize: '',
                productType: '',
                attributes: [],
                variants: [],
                group: ''

            })
        } else {
            setProduct({
                name: "",
                description: "",
                purchasedPrice: "",
                departmentName: null,
                manufacturerName: '',
                thumbnail: null,
                galleryImages: [],
                isGeneralProduct: true,
                pair: null,
                generalCategory: "",
                generalSubCategory: "",
                specialityCategory: "",
                specialitySubCategory: "",
                discount: 0,
                salesPrice: "",
                shortListDescription: '',
                longListDescription: '',
                totalStocks: "",
                instructions: '',
                sideEffects: '',
                saltComposition: [],
                isPrescriptionNeeded: false,
                isColdChainNeeded: false,
                tags: [],
                metaDescription: '',
                metaTitle: '',
                metaAltText: '',
                keywords: '',
                lowStock: 0,
                brand: null,
                packageSize: '',
                productType: '',
                attributes: [],
                variants: [],
                group: ''

            })
        }
    }, [history, name, products])

    // {"ProductName":"ZILZOX 40 TAB 10'S","ProductDescription":"ZILZOX 40 TAB 10'S","DepartmentName":null,"MRP":"78.50","TotalStock":"54"}

    return (
        <div>
            <AddProductInformation product={product} setProduct={setProduct} />
            <AddProductImages product={product} setProduct={setProduct} />
            <AddProductType product={product} setProduct={setProduct} />
            {
                product.isGeneralProduct ?
                    <AddCategoriesAndSubCategories product={product} setProduct={setProduct} /> :
                    <AddSpecialityCategoriesAndSubCategories product={product} setProduct={setProduct} />

            }
            {/* <AddPrescriptionNeeded product={product} setProduct={setProduct} /> */}
            <AddProductPriceAndStock product={product} setProduct={setProduct} />
            <AddPairProduct product={product} setProduct={setProduct} />
            <AddShortAndLongDescription product={product} setProduct={setProduct} />
        </div>
    );
};

export default AddProducts;
