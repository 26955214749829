import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import axios from "axios";
import { Select, Space } from "antd";
import connection from "components/app/auth/api";

const EditProductInformation = ({ product, setProduct }) => {
  const is430pxBelow = useMediaQuery("(max-width:430px)");

  const useStyles = makeStyles(() => ({
    container: {
      width: "90%",
      "& h5": {
        color: Colors.primary,
        fontSize: "20px",
      },
      "& .input-container": {
        display: "flex",
        margin: "20px 0",
        flexDirection: "column",
        "& div": {
          "& h6": {
            color: Colors.darkText,
            fontSize: "16px",
            margin: "5px 0",
          },
          "& input": {
            border: `1px solid ${Colors.primary}`,
            "&:focus": {
              outline: 0,
            },
            width: "90%",
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "5px 0",
          },
        },
      },
    },
  }));

  const classes = useStyles();
  const [brandOpt, setBrandOpt] = useState([]);

  useEffect(() => {
    fetchBrand();
  }, []);

  let BrandOpts = [];

  const fetchBrand = () => {
    connection.get(`/get/brands?limit=250`).then((res) => {
      BrandOpts = res?.data?.data?.map((val) => {
        return { label: val?.name, value: val?.id ?? val?._id };
      });
      setBrandOpt(BrandOpts);
    });
  };

  return (
    <div className={classes.container}>
      <h5>Product Information</h5>
      <div className={"input-container"}>
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ flex: 1 }}>
            <h6>Name</h6>
            <input
              value={product.name}
              name={"name"}
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
            />
          </div>
          <div style={{ flex: 1 }}>
            <h6>Brand Name</h6>
            <Select
              options={brandOpt}
              onChange={(e) => setProduct({ ...product, brand: e })}
              value={product?.brand?.name ?? product?.brand}
              style={{ width: "90%" }}
            />

          </div>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ flex: 1 }}>
            <h6>Description</h6>
            <input
              value={product.description}
              name={"description"}
              onChange={(e) =>
                setProduct({ ...product, description: e.target.value })
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <h6>Manufacturer Name</h6>
            <input
              value={product.manufacturerName}
              onChange={(e) =>
                setProduct({ ...product, manufacturerName: e.target.value })
              }
              name={"manufacturerName"}
            />
          </div>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ flex: 1 }}>
            <h6>Slug Url</h6>
            <input
              style={{ width: "45%" }}
              value={product.urlSlug}
              name={"urlSlug"}
              onChange={(e) =>
                setProduct({ ...product, urlSlug: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductInformation;
