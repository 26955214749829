import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import axios from "axios";
import OrderViewHeader from "./section/OrderViewHeader";
import OrderViewTable from "./section/OrderViewTable";
import OrderDetails from "./section/OrderDetails";
import connection from '../auth/api';

const OrderView = () => {

    const { id } = useParams()
    const [order, setOrder] = useState({})
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get(`/get/order?id=${id}`).then(res => {
            setOrder(res.data.data);
            console.log(res.data);
            setLoading(false)
        })
    }, [id])

    if (loading) {
        return (
            <FullScreenProgress setOpen={setLoading} open={loading} />
        )
    } else {
        return (
            <div>
                <OrderDetails order={order} />
                <OrderViewHeader order={order} setOrder={setOrder} />
                <OrderViewTable products={order.products} />
            </div>
        );
    }
};

export default OrderView;
