import React from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";

const EditProductPriceAndStocks = ({ product, setProduct }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px',
                marginTop: '55px',
                marginBottom: '20px'
            },
            '& .input-container': {
                display: 'flex',
                width: is430pxBelow ? '100%' : '50%',
                justifyContent: 'space-between',
                gap: '10px',
                '& div': {
                    width: '48%',
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: '100%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                }
            }
        }
    }))

    const classes = useStyles();

    const onDiscountChange = (e) => {
        const value = e.target.value;
        if (value < 100) {
            const discountPrice = value / 100 * product.purchasedPrice;
            const salesPrice = discountPrice !== 0 ? (product.purchasedPrice - discountPrice) : product.purchasedPrice
            setProduct({ ...product, discount: value, salesPrice })
        }
    }

    const onSalesChange = (e) => {
        const value = e.target.value;
        if (value <= parseInt(product.purchasedPrice)) {
            const discount = (100 - (value / product.purchasedPrice * 100)).toFixed(2)
            setProduct({ ...product, salesPrice: value, discount })
        } else {
            setProduct({ ...product, salesPrice: product.salesPrice, discount: product.discount })
        }
    }

    const onPurchasedPriceChange = (e) => {

        const value = e.target.value;
        setProduct({ ...product, purchasedPrice: value })
    }

    return (
        <div className={classes.container}>
            <h5>Product Price And Stocks</h5>
            <div className={'input-container'}>
                <div>
                    <h6>MRP Price</h6>
                    <input value={product.purchasedPrice} name={'purchasedPrice'} onChange={onPurchasedPriceChange} />
                </div>
                <div>
                    <h6>Discount</h6>
                    <input type={'number'} value={product.discount} onChange={onDiscountChange} name={'discount'} />
                </div>
                <div>
                    <h6>Pack Size</h6>
                    <input value={product.packageSize} onChange={e => setProduct({ ...product, packageSize: e.target.value })} name={'packageSize'} />
                </div>
            </div>
            <div className={'input-container'}>
                <div>
                    <h6>Sales Price</h6>
                    <input type={'number'} value={product.salesPrice} onChange={onSalesChange} name={'salesPrice'} />
                </div>
                <div>
                    <h6>Stocks</h6>
                    <input type={'number'} value={product.totalStocks} name={'totalStocks'} onChange={(e) => setProduct({ ...product, totalStocks: e.target.value })} />
                </div>
                <div>
                    <h6>Low Stock Size</h6>
                    <input type={'number'} value={product.stockAlertThreshold} name={'totalStocks'} onChange={e => setProduct({ ...product, stockAlertThreshold: e.target.value })} />
                </div>
            </div>
        </div>
    );
};

export default EditProductPriceAndStocks;
