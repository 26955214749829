import React from 'react';
import MuiAlert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";

const CustomToast = ({openToast, setOpenToast, message, type}) => {
    return (
        <Snackbar open={openToast} autoHideDuration={2000} onClose={() => setOpenToast(false)}>
            <MuiAlert elevation={6} variant="filled" severity={type} >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default CustomToast;
