import React, { useState } from 'react';
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../../helpers/Color";
import { IoCloseCircle, IoMdCloudUpload } from "react-icons/all";
import ImageUploading from "react-images-uploading";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";
import axios from "axios";
import { useHistory } from "react-router-dom";
import connection from 'components/app/auth/api';

const AddNewNotification = ({ setNotifications }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            width: '70%',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: '100%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '70%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '20px' : '20px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            },
        }
    }))

    const classes = useStyles();

    const defaultValue = {
        title: '',
        description: '',
        link: ''
    }

    const [notification, setNotification] = useState(defaultValue);

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleDataChange = (e) => {
        setNotification({ ...notification, [e.target.name]: e.target.value })
    }

    function validURL(str) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const onUploadClick = () => {
        const { title, description, link } = notification;
        if (title !== '' && description !== '' && link !== '') {
            if (validURL(link)) {
                setOpen(true)
                connection.post('/post/pushNotification', { data: notification }).then(res => {
                    if (res.data.key === 'success') {
                        connection.get('/get/pushNotifications').then(res => {
                            setNotifications(res.data)
                            setNotification(defaultValue)
                            setOpen(false)
                        })
                    } else {
                        setOpen(false)
                        setErrorMessage(res.data.message)
                        setOpenError(true)
                    }
                })
            } else {
                setErrorMessage('Enter a valid Url')
                setOpenError(true)
            }
        } else {
            setErrorMessage('All Fields are required')
            setOpenError(true)
        }
    }

    return (
        <div className={classes.container}>
            <h5>Add New Notification</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Title</h6>
                    <input name={'title'} value={notification.title} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input name={'description'} value={notification.description} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Link</h6>
                    <input name={'link'} value={notification.link} onChange={handleDataChange} />
                </div>
            </div>
            <div className={'upload'} onClick={onUploadClick}>
                Upload
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default AddNewNotification;
