export const Colors = {
    primary: '#2086CF',
    secondary: '#F7AD00',
    primaryLight: '#e5eef5',
    secondaryLight: '#fff7e6',
    light: '#fff',
    light1: '#ebeef4',
    dark: '#000',
    lightBorder: '#e6e6e6',
    darkBorder: '#bfbfbf',
    darkText: '#4d4d4d',
    error: '#da2126',
    errorLight: '#ff3333',
    success: '#5cb85c',
    temp1: '#1f6bff',
    temp2: '#0f357f',
    pending: '#FFAE00',
    confirmed: '#127500',
    pickedUp: '#00ABA2',
    onTheWay: '#303030',
    delivered: '#007A12',
}
