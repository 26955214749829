import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { createTheme, Switch, useMediaQuery } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { BiEdit, RiDeleteBin6Line } from "react-icons/all";
import Paper from "@material-ui/core/Paper";
import { Colors } from "../../../helpers/Color";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import { pathOr } from 'ramda';
import connection from 'components/app/auth/api';
// import slugify from 'slugify';


const Row = ({
    row, index, classes, dealsOfTheDayProducts,
    setLoading, setDealsOfTheDayProducts, setFilteredData, setProducts, setProductCount, currentPage, setCurrentPage, limit, setLimit, searchValue
}) => {

    const [isDealsAdded, setIsDealsAdded] = useState(false);

    const history = useHistory();

    const onEditProductClick = (id) => {
        history.push(`/edit-product/${id}`)

    }

    const onDeleteProductClick = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                connection.delete('/delete/product', { data: { id } }).then(res => {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    ).then(() => {
                        connection.get(`/get/allProducts?currentPage=${currentPage}&limit=${limit}&searchText=${searchValue}`).then(res => {
                            setFilteredData([])
                            setProducts(pathOr([], ['data'], res.data))
                            setProductCount(pathOr(0, ['data', 'metadata', '0', 'total'], res))
                        })
                    })
                })
            }
        })
    }

    useEffect(() => {
        let isDealsAddedTemp = false;
        dealsOfTheDayProducts?.forEach(item => {
            if (item.product._id === row._id) {
                isDealsAddedTemp = true
            }
        })
        setIsDealsAdded(isDealsAddedTemp)
    }, [dealsOfTheDayProducts, row._id])

    const onDealsOfTheDayChange = (product) => {
        setLoading(true)
        if (isDealsAdded) {
            connection.delete('/delete/dealsOfTheDay', { data: { id: product._id } }).then(res => {
                if (res.data.key === 'success') {
                    connection.get('/get/dealsOfTheDay').then(res => {
                        setDealsOfTheDayProducts(res.data)
                        setLoading(false)
                    })
                }
            })
        } else {
            connection.post('/post/dealsOfTheDay', { data: product }).then(res => {
                if (res.data.key === 'success') {
                    connection.get('/get/dealsOfTheDay').then(res => {
                        setDealsOfTheDayProducts(res.data)
                        setLoading(false)
                    })
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
        }
    }

    const onEnableChange = () => {

        setLoading(true)
        connection.put('/update/changeProductAvailability', { data: { id: row._id, value: !row.isEnabled } }).then(() => {
            connection.get(`/get/allProducts?currentPage=${currentPage}&limit=${limit}&searchText=${searchValue}`).then(res => {
                setFilteredData([])
                setProducts(pathOr([], ['data'], res.data))
                setProductCount(pathOr(0, ['data', 'metadata', '0', 'total'], res))
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{index + 1}</h6>
            </TableCell>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{row.name}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <div className={classes.tableImage}>
                    <img src={typeof row.thumbnailUrl === "string" ? row.thumbnailUrl : row?.thumbnailUrl?.url} alt={row.name} />
                </div>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? 'General Product' : 'Speciality Product'}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? row.generalCategory : row.specialityCategory}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.totalStocks}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{parseFloat(row.salesPrice).toFixed(2)}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <Switch checked={isDealsAdded} onClick={() => onDealsOfTheDayChange(row)} />
            </TableCell>
            <TableCell align={'center'}>
                <Switch checked={row.isEnabled} onClick={onEnableChange} />
            </TableCell>
            <TableCell align={'right'}>
                <div className={classes.actionContainer}>
                    <RiDeleteBin6Line onClick={() => onDeleteProductClick(row._id)} className={'delete-icon'} />
                    <BiEdit className={'edit-icon'} onClick={() => onEditProductClick(row.name)} />
                </div>
            </TableCell>
        </TableRow>
    );
}


const AddedProductsTable = ({
    filteredData, products,
    setProducts, setFilteredData, dealsOfTheDayProducts, setDealsOfTheDayProducts, currentPage, setCurrentPage, limit, setLimit, productCount, setProductCount, searchValue
}) => {

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Thumbnail',
            align: 'center',
        },
        {
            name: 'Product Type',
            align: 'center',
        },
        {
            name: 'Category',
            align: 'center',
        },
        {
            name: 'Total Stocks',
            align: 'center',
        },
        {
            name: 'Sales Price',
            align: 'center',
        },
        {
            name: 'DD',
            align: 'center',
        },
        {
            name: 'Status',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setCurrentPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(+event.target.value);
        setCurrentPage(1);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px'
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
            '& .edit-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const displayingProducts = filteredData.length !== 0 ? filteredData : products

    const [loading, setLoading] = useState(false);

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map(col => (
                                        <StyledTableCell align={col.align}>
                                            <h6 className={classes.colTextStyle}>{col.name}</h6>
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row, index) => (
                                <Row row={row} index={index} classes={classes} setLoading={setLoading}
                                    setFilteredData={setFilteredData}
                                    setProducts={setProducts}
                                    dealsOfTheDayProducts={dealsOfTheDayProducts}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    limit={limit}
                                    productCount={productCount}
                                    setProductCount={setProductCount}
                                    searchValue={searchValue}
                                    setLimit={setLimit}
                                    setDealsOfTheDayProducts={setDealsOfTheDayProducts} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                {
                    displayingProducts.length === 0 ?
                        <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data
                            Found</h6> : <></>
                }
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={productCount}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <FullScreenProgress open={loading} setOpen={setLoading} />
            </Paper>
        </MuiThemeProvider>
    );
};

export default AddedProductsTable;
