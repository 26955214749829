import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import BlogHeader from "./section/BlogHeader";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import BlogTable from "./section/BlogTable";
import connection from '../auth/api';

const BlogsPage = () => {

    const useStyles = makeStyles({
        container: {
            margin: '20px 0'
        }
    })

    const classes = useStyles();

    const [blogs, setBlogs] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/blogs').then(res => {
            setBlogs(res.data.data)
            setLoading(false)
        })
    }, []);


    return (
        <div className={classes.container}>
            <BlogHeader blogs={blogs} setFilteredData={setFilteredData} />
            <BlogTable blogs={blogs} setBlogs={setBlogs} filteredData={filteredData} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default BlogsPage;
