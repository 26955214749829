import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { createTheme, Switch, useMediaQuery } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { BiEdit, FiCheck, MdAdd, RiDeleteBin6Line } from "react-icons/all";
import Paper from "@material-ui/core/Paper";
import { Colors } from "../../../helpers/Color";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FullScreenProgress from "../../../helpers/FullScreenProgress";


const Row = ({ row, index, classes, product, setProduct }) => {

    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if (product.pair) {
            if (product.pair._id === row._id) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [product.pair, row._id])

    return (
        <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{index + 1}</h6>
            </TableCell>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{row.name}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <div className={classes.tableImage}>
                    <img src={typeof row.thumbnailUrl === "string" ? row.thumbnailUrl : row?.thumbnailUrl?.url} alt={row.name} />
                </div>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? 'General Product' : 'Speciality Product'}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? row.generalCategory : row.specialityCategory}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.totalStocks}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{parseInt(row.salesPrice).toFixed(2)}</h6>
            </TableCell>
            <TableCell align={'right'}>
                <div className={classes.actionContainer}>
                    {
                        isSelected ?
                            <FiCheck className={'check-icon'} />
                            :
                            <MdAdd onClick={() => { setProduct({ ...product, pair: row }) }} className={'add-icon'} />
                    }
                </div>
            </TableCell>
        </TableRow>
    );
}


const EditPairProductTable = ({ filteredData, products, product, setProduct }) => {

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Thumbnail',
            align: 'center',
        },
        {
            name: 'Product Type',
            align: 'center',
        },
        {
            name: 'Category',
            align: 'center',
        },
        {
            name: 'Total Stocks',
            align: 'center',
        },
        {
            name: 'Sales Price',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
            margin: '15px 0 0'
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px'
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .add-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary,
                cursor: 'pointer'
            },
            '& .check-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.success,
                cursor: 'pointer'
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const displayingProducts = filteredData?.length !== 0 ? filteredData.data : products?.data

    const [loading, setLoading] = useState(false);

    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map(col => (
                                        <StyledTableCell align={col.align}>
                                            <h6 className={classes.colTextStyle}>{col.name}</h6>
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayingProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <Row row={row} index={index} classes={classes} product={product} setProduct={setProduct} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    displayingProducts.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={displayingProducts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <FullScreenProgress open={loading} setOpen={setLoading} />
            </Paper>
        </MuiThemeProvider>
    );
};

export default EditPairProductTable;
