import React, { useEffect, useState } from 'react';
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../helpers/Color";
import {
    BiLogOutCircle,
    BsBag, BsBoxArrowInDown,
    BsInboxes,
    BsListUl,
    FiCode, FiSettings, FiUsers, HiOutlineShoppingCart, BiBorderAll, IoLocate, IoMdNotificationsOutline,
    IoSpeedometerOutline,
    MdKeyboardArrowLeft, RiArrowLeftSLine, RiDashboardLine,
    GoFileMedia
} from "react-icons/all";
import logo from 'assets/logo.png'
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/slice/IsLoginSlice";
import { Badge } from "antd";
import axios from "axios";
import { clearProducts } from "../../../redux/slice/ProductSlice";


const CategoriesAccordion = ({ setDrawerOpen, location }) => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const useStyles = makeStyles(() => ({
        accordion: {
            position: 'relative',
            '& .item': {
                display: 'flex',
                alignItems: 'center',
                padding: '12px 15px',
                '& h6': {
                    color: Colors.darkText,
                    margin: '0 0 0 10px',
                    fontSize: '14px'
                },
                '& .arrow': {
                    marginLeft: 'auto',
                    color: Colors.darkText,
                    fontSize: 22,
                    transform: isMenuVisible ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transition: 'rotate .4s'
                },
                '& .icon': {
                    color: Colors.primary,
                    fontSize: 22,
                },
                '&:hover': {
                    '& .icon': {
                        color: Colors.primary,
                        fontSize: 22,
                    },
                    '& h6': {
                        color: Colors.primary,
                        transition: 'color .3s',
                    },
                    '& .arrow': {
                        color: Colors.primary,
                        fontSize: 22,
                    },
                }
            },
            '& .sub-item-con': {
                visibility: isMenuVisible ? 'visible' : 'hidden',
                '& .sub-item': {
                    transition: 'height .2s linear',
                    height: isMenuVisible ? '35px' : '0',
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        '& h6': {
                            color: Colors.primary,
                        },
                    },
                    '& h6': {
                        color: Colors.darkText,
                        margin: '0 0 0 10px',
                        fontSize: '14px'
                    },
                },
                '& .active-sub-item': {
                    transition: 'height .2s linear',
                    height: isMenuVisible ? '35px' : '0',
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '& h6': {
                        color: Colors.primary,
                        margin: '0 0 0 10px',
                        fontSize: '14px'
                    },
                }
            }
        }
    }))

    const classes = useStyles();

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const history = useHistory()

    const onLinkClick = (path) => {
        history.push(path)
        if (is770pxBelow) {
            setDrawerOpen(false)
        }
    }

    return (
        <div className={classes.accordion}>

            <div className={'item'} onClick={() => setIsMenuVisible(prevState => !prevState)}>
                <BsListUl className={'icon'} />
                <h6>Categories</h6>
                <RiArrowLeftSLine className={'arrow'} />
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/groups')}>
                <div className={location.pathname.includes('/groups') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Groups</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/brands')}>
                <div className={location.pathname.includes('/brands') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Brands</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/general-categories')}>
                <div className={location.pathname.includes('/general-categories') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>General Categories</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/general-sub-categories')}>
                <div className={location.pathname.includes('/general-sub-categories') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>General Sub Categories</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/speciality-categories')}>
                <div className={location.pathname.includes('/speciality-categories') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Speciality Categories</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/speciality-sub-categories')}>
                <div
                    className={location.pathname.includes('/speciality-sub-categories') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Speciality Sub Categories</h6>
                </div>
            </div>
        </div>
    )
}

const SettingsAccordion = ({ setDrawerOpen, location }) => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const useStyles = makeStyles(() => ({
        accordion: {
            position: 'relative',
            '& .item': {
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                padding: '12px 15px',
                '& h6': {
                    color: Colors.darkText,
                    margin: '0 0 0 10px',
                    fontSize: '14px'
                },
                '& .arrow': {
                    marginLeft: 'auto',
                    color: Colors.darkText,
                    fontSize: 22,
                    transform: isMenuVisible ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transition: 'rotate .4s'
                },
                '& .icon': {
                    color: Colors.primary,
                    fontSize: 22,
                },
                '&:hover': {
                    '& .icon': {
                        color: Colors.primary,
                        fontSize: 22,
                    },
                    '& h6': {
                        color: Colors.primary,
                        transition: 'color .3s',
                    },
                    '& .arrow': {
                        color: Colors.primary,
                        fontSize: 22,
                    },
                }
            },
            '& .sub-item-con': {
                visibility: isMenuVisible ? 'visible' : 'hidden',
                '& .sub-item': {
                    transition: 'height .2s linear',
                    height: isMenuVisible ? '35px' : '0',
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        '& h6': {
                            color: Colors.primary,
                        },
                    },
                    '& h6': {
                        color: Colors.darkText,
                        margin: '0 0 0 10px',
                        fontSize: '14px'
                    },
                },
                '& .active-sub-item': {
                    transition: 'height .2s linear',
                    height: isMenuVisible ? '35px' : '0',
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '& h6': {
                        color: Colors.primary,
                        margin: '0 0 0 10px',
                        fontSize: '14px'
                    },
                }
            }
        }
    }))

    const classes = useStyles();

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const history = useHistory()

    const onLinkClick = (path) => {
        history.push(path)
        if (is770pxBelow) {
            setDrawerOpen(false)
        }
    }

    return (
        <div className={classes.accordion}>
            <div className={'item'} onClick={() => setIsMenuVisible(prevState => !prevState)}>
                <FiSettings style={{ padding: '1.5px' }} className={'icon'} />
                <h6>Settings</h6>
                <RiArrowLeftSLine className={'arrow'} />
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/banners')}>
                <div className={location.pathname.includes('/banners') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Banner Settings</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/main-bar-category')}>
                <div className={location.pathname.includes('/main-bar-category') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Menu Categories</h6>
                </div>
            </div>
            <div className={'sub-item-con'} onClick={() => onLinkClick('/blog')}>
                <div className={location.pathname.includes('/blog') ? 'active-sub-item' : 'sub-item'}>
                    <BsListUl fontSize={22} style={{ visibility: 'hidden' }} color={Colors.darkText} />
                    <h6>Blogs</h6>
                </div>
            </div>
        </div>
    )
}

const HomeSideDrawer = ({ drawerOpen, setDrawerOpen }) => {

    const useStyles = makeStyles(() => ({
        container: {
            width: '100%',
            height: '100%',
            background: Colors.light,
            display: 'flex',
            borderRight: `1px solid ${Colors.lightBorder}`,
            flexDirection: 'column',
            '& .footer': {
                background: Colors.primary,
                justifySelf: 'flex-end',
                display: 'flex',
                marginTop: 'auto',
                justifyContent: 'flex-end',
                padding: '10px 15px',
                '& .close-icon': {
                    color: Colors.light,
                    fontSize: '25px',
                    margin: 0
                }
            },
            '& .logo-con': {
                // boxShadow: '0 0px 5px rgb(0 0 0 / 0.2)',
                '& img': {
                    width: '100px',
                    cursor: 'pointer',
                    margin: '15px',
                }
            },
            '& .items-container': {
                overflowY: 'auto',
                padding: '10px 0',
                height: '100%',
                '& .title': {
                    marginLeft: '15px',
                    marginTop: '30px',
                    fontSize: '16px',
                    color: Colors.darkText,
                },
                '&::-webkit-scrollbar': {
                    width: '3px',
                    display: 'none',
                },
                '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.primary,
                    borderRadius: '10px',
                    height: '20%'
                },
                '& .item': {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 15px',
                    width: '100%',
                    cursor: 'pointer',
                    '& h6': {
                        color: Colors.darkText,
                        margin: '0 0 0 10px',
                        transition: 'color .3s',
                        fontSize: '14px'
                    },
                    '& .batch-con': {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    },
                    '& .icon': {
                        color: Colors.darkText,
                        transition: 'color .3s',
                        fontSize: 22,
                    },
                    '&:hover': {
                        '& .icon': {
                            color: Colors.primary,
                            fontSize: 22,
                        },
                        '& h6': {
                            color: Colors.primary,
                            transition: 'color .3s',
                        },
                    }
                },
                '& .active-item': {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 15px',
                    width: '100%',
                    cursor: 'pointer',
                    '& h6': {
                        color: Colors.primary,
                        margin: '0 0 0 10px',
                        transition: 'color .3s',
                        fontSize: '14px'
                    },
                    '& .icon': {
                        color: Colors.primary,
                        transition: 'color .3s',
                        fontSize: 22,
                    },
                    '& .batch-con': {
                        width: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }
                }
            },
        },
    }))

    const classes = useStyles()

    const history = useHistory();
    const location = useLocation();

    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const dispatch = useDispatch();

    const onLogoutClick = () => {
        localStorage.clear();
        dispatch(clearProducts())
        dispatch(logoutUser())
    }

    const [orders, setOrders] = useState([])

    const dbOrdersRedux = useSelector((state) => state?.Orders?.value)

    useEffect(() => {
        const ordersTemp = []
        dbOrdersRedux && dbOrdersRedux?.forEach(order => {
            if (!order.isSeen) {
                ordersTemp.push(order)
            }
        })
        setOrders(ordersTemp)
    }, [dbOrdersRedux])

    return (
        <div className={classes.container}>
            <div className={'logo-con'}>
                <img src={logo} alt={'Logo'} onClick={() => {
                    history.push('/')
                    if (is770pxBelow) {
                        setDrawerOpen(false)
                    }
                }} />
            </div>
            <div className={'items-container'}>
                <h6 className={'title'} style={{ marginTop: 0 }}>Navigation</h6>
                <div className={location.pathname === '/' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <RiDashboardLine className={'icon'} />
                    <h6>Dashboard</h6>
                </div>
                <div className={location.pathname === '/users' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/users')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <FiUsers style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Users</h6>
                </div>
                <div className={location.pathname === '/dynamic-categories' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/dynamic-categories')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <BiBorderAll style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Dynamic Categories</h6>
                </div>
                <div className={location.pathname === '/push-notification' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/push-notification')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <IoMdNotificationsOutline style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Push Notification</h6>
                </div>
                <CategoriesAccordion setDrawerOpen={setDrawerOpen} location={location} />
                <h6 className={'title'}>Products</h6>
                <div className={location.pathname === '/all-products' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/all-products')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <BsInboxes style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>All Products</h6>
                </div>
                <div className={location.pathname === '/added-products' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/added-products')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <BsBoxArrowInDown className={'icon'} />
                    <h6>Added Products</h6>
                </div>
                <div className={location.pathname === '/media' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/media')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <GoFileMedia className={'icon'} />
                    <h6>Add Media</h6>
                </div>
                <h6 className={'title'}>Product Requests</h6>
                <div className={location.pathname === '/product-requests' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/product-requests')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <IoMdNotificationsOutline style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Stock Requests</h6>
                </div>
                <div className={location.pathname === '/new-product-requests' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/new-product-requests')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <IoMdNotificationsOutline style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>New Product Requests</h6>
                </div>
                <h6 className={'title'}>Sales</h6>
                <div className={location.pathname === '/orders' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/orders')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <HiOutlineShoppingCart className={'icon'} />
                    <h6 style={{ marginLeft: '14px' }}>Orders </h6>
                    <div className={'batch-con'}>
                        <Badge className={'batch-icon'} count={orders.length} />
                    </div>
                </div>
                <div className={location.pathname === '/sales' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/sales')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <BsBag style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Reports</h6>
                </div>
                <div className={location.pathname === '/promo-code' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/promo-code')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <FiCode style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Promo Code</h6>
                </div>
                <h6 className={'title'}>Area | Pincode</h6>
                <div className={location.pathname === '/pincode' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/pincode')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <IoLocate style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>Pincode</h6>
                </div>
                <div className={location.pathname === '/state' ? 'active-item' : 'item'}
                    onClick={() => {
                        history.push('/state')
                        if (is770pxBelow) {
                            setDrawerOpen(false)
                        }
                    }}>
                    <IoLocate style={{ padding: '1.5px' }} className={'icon'} />
                    <h6>State</h6>
                </div>
                <h6 className={'title'}>General | Banner Settings</h6>
                <SettingsAccordion setDrawerOpen={setDrawerOpen} location={location} />
                {/*<div className={location.pathname === '/blog' ? 'active-item' : 'item'}*/}
                {/*     onClick={() => {*/}
                {/*         history.push('/blog')*/}
                {/*         if (is770pxBelow) {*/}
                {/*             setDrawerOpen(false)*/}
                {/*         }*/}
                {/*     }}>*/}
                {/*    <FiCode style={{padding: '1.5px'}} className={'icon'}/>*/}
                {/*    <h6>Blog</h6>*/}
                {/*</div>*/}
                <div className={'item'} onClick={onLogoutClick}>
                    <BiLogOutCircle className={'icon'} />
                    <h6>Logout</h6>
                </div>
            </div>
            <div className={'footer'}>
                <MdKeyboardArrowLeft onClick={() => setDrawerOpen(false)} className={'close-icon'} />
            </div>
        </div>
    );
};

export default HomeSideDrawer;
