import React, {useState} from 'react';
import AddBlogImage from "./section/AddBlogImage";
import AddBlogContent from "./section/AddBlogContent";

const AddBlogPage = () => {

    const defaultData = {
        name: '',
        blogContent: '',
        image: null,
        writtenBy: ''
    }

    const [blog, setBlog] = useState(defaultData);

    return (
        <div>
            <AddBlogImage blog={blog} setBlog={setBlog} />
            <AddBlogContent blog={blog} setBlog={setBlog} />
        </div>
    );
};

export default AddBlogPage;
