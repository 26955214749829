import React, { useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import { AiOutlinePrinter, FiDownload, IoMdClose } from "react-icons/all";
import axios from "axios";
import { notification } from "antd";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import { Dialog, useMediaQuery } from "@material-ui/core";
import ModalImage from "@jaon/react-modal-image";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import OrderInvoice from "./OrderInvoice";
import connection from 'components/app/auth/api';

const OrderViewHeader = ({ order, setOrder }) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const [dialogOpen, setDialogOpen] = useState(false);

    const useStyles = makeStyles(({
        container: {
            padding: is430pxBelow ? '20px 10px' : '20px',
            '& .payment-delivery-container': {
                display: 'flex',
                flexWrap: 'wrap',
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '0 0 25px' : '0 25px 0 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: '1px solid #bfbfbf',
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                }
            },
            '& .user-details-container': {
                display: 'flex',
                alignItems: 'center',
                margin: '20px 0 0',
                justifyContent: 'space-between',
                '& .customer-name': {
                    color: Colors.darkText,
                    fontSize: is430pxBelow ? '16px' : 'auto',
                    margin: 0
                },
                '& .total-amount': {
                    color: Colors.success,
                    fontSize: is430pxBelow ? '16px' : 'auto',
                    margin: 0
                },
                '& .print-icon': {
                    color: Colors.darkText,
                    background: Colors.lightBorder,
                    padding: '8px',
                    display: is770pxBelow ? 'none' : 'auto',
                    fontSize: 40
                }
            },
            '& .prescription-container': {
                margin: '25px 0 0',
                '& .pres-title': {
                    color: Colors.darkText
                },
                '& .images-container': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '20px 0',
                    '& .single-image': {
                        border: '1px solid #d9d9d9',
                        borderRadius: '5px',
                        padding: '10px',
                        position: 'relative',
                        width: is380pxBelow ? '96px' : '110px',
                        height: is380pxBelow ? '96px' : '110px',
                        margin: '0 10px 10px 0',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .img-view': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                        '& .__react_modal_image__header': {
                            background: 'transparent'
                        },
                        '& .__react_modal_image__icon_menu': {
                            position: 'fixed',
                            top: 0,
                            right: 0
                        },
                        '& div': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        },
                    }
                }
            },
        },
        dialogStyle: {
            width: '750px !important',
            margin: '0 auto',
            height: '100vh !important',
            overflow: 'hidden',
            '& .MuiDialog-paperFullWidth': {
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            }
        },
        actionStyle: {
            position: "fixed",
            right: is1025pxBelow ? '5%' : '20%',
            top: 40,
            zIndex: 9999999999,
            display: dialogOpen ? 'auto' : 'none',
            '& .icon': {
                fontSize: '30px',
                margin: '0 10px',
                color: Colors.light
            }
        },
        dialogContentStyle: {
            padding: '0 !important',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
        }
    }))
    const classes = useStyles()

    const [loading, setLoading] = useState(false);
    const invoiceRef = useRef();

    const onDeliveryDataChange = (e) => {
        setLoading(true);

        let tempProducts = order

        const products = tempProducts?.products?.map(product => ({
            ...product,
            thumbnailUrl: product._id
        }));

        const tempCart = tempProducts?.user?.cart?.map(val => ({
            ...val,
            thumbnailUrl: val._id
        }))

        tempProducts.products = products;
        tempProducts = { ...tempProducts, user: { ...tempProducts.user, cart: tempCart } }

        connection.put('/update/order', {
            data: {
                orderId: order._id,
                mobileNumber: order.user.mobileNumber,
                order: {
                    ...tempProducts,
                    [e.target.name]: e.target.value,
                    deliveryStatusTiming: { ...order.deliveryStatusTiming, [e.target.value]: new Date() },
                    isDeliveryStatusChange: e.target.name !== 'paymentStatus',
                }
            }
        }).then(res => {
            if (res.data.key === 'success') {
                connection.get(`/get/order?id=${order._id}`).then(res => {
                    setOrder(res.data.data)
                    notification.success({
                        message: 'Changed',
                        description: 'Status Updated'
                    })
                })
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong'
                })
            }
            setLoading(false)
        })
    }

    const onPaymentDataChange = (e) => {
        setLoading(true)

        let tempProducts = order

        const products = tempProducts?.products?.map(product => ({
            ...product,
            thumbnailUrl: product._id
        }));

        const tempCart = tempProducts?.user?.cart?.map(val => ({
            ...val,
            thumbnailUrl: val._id
        }))

        tempProducts.products = products;
        tempProducts = { ...tempProducts, user: { ...tempProducts.user, cart: tempCart } }

        connection.put('/update/order', {
            data: {
                orderId: order._id,
                mobileNumber: order.user.mobileNumber,
                order: { ...tempProducts, [e.target.name]: e.target.value }
            }
        }).then(res => {
            if (res.data.key === 'success') {
                connection.get(`/get/order?id=${order._id}`).then(res => {
                    setOrder(res.data.data)
                    notification.success({
                        message: 'Changed',
                        description: 'Status Updated'
                    })
                })
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong'
                })
            }
            setLoading(false)
        })
    }

    const onDownloadClick = () => {

        setLoading(true)

        toPng(invoiceRef.current).then(dataUrl => {
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(dataUrl);

            console.log('Img Props Height ' + imgProps.height)
            console.log('Img Props Width ' + imgProps.width)

            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = imgProps.height * imgWidth / imgProps.width;
            console.log('Img Height ' + imgHeight)
            let heightLeft = imgHeight;
            const doc = new jsPDF('p', 'mm', [295, 210]);

            let position = 0;

            doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            console.log('Height Left ' + heightLeft)

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            doc.save(`InvoiceNo-${order._id}`);

            setLoading(false)

        })
    }


    return (
        <>
            <div className={classes.container}>
                <div className={'payment-delivery-container'}>
                    <div className={'select-container'}>
                        <h6>Payment Status</h6>
                        <select name={'paymentStatus'} value={order.paymentStatus} onChange={onPaymentDataChange}>
                            <option value="" disabled selected>Change Payment Status</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Un Paid</option>
                        </select>
                    </div>
                    <div className={'select-container'}>
                        <h6>Delivery Status</h6>
                        <select name={'deliveryStatus'} value={order.deliveryStatus} onChange={onDeliveryDataChange}>
                            <option value="" disabled selected>Change Delivery Status</option>
                            <option value="pending">Pending</option>
                            <option value="confirmed">Confirmed</option>
                            <option value="pickedUp">Picked Up</option>
                            {/* <option value="onTheWay">On The Way</option> */}
                            <option value="delivered">Delivered</option>
                        </select>
                    </div>
                </div>
                <div className={'prescription-container'}>
                    <h6 className={'pres-title'}>
                        Prescriptions
                    </h6>
                    {/* <div className={'images-container'}>
                        {
                            order.prescriptions.map(pres => (
                                <div id={pres.id} className={'single-image'}>
                                    <ModalImage
                                        className={'img-view'}
                                        small={pres?.prescriptionImageUrl}
                                        large={pres?.prescriptionImageUrl}
                                        hideZoom={true}
                                        imageBackgroundColor={'transparent'}
                                    />
                                </div>
                            ))
                        }
                    </div> */}
                </div>
                <div className={'user-details-container'}>
                    <h5 className={'customer-name'}>Customer Name : {order.shippingAddress.name}</h5>
                    <h5 className={'total-amount'}>
                        Total Amount : {parseInt(order.totalPrice) + parseInt(order.deliveryCharge)}
                        <br />
                        <span style={{ fontSize: '13px', color: 'black' }} >Inclusive of delivery charge. (₹{order.deliveryCharge})</span>
                    </h5>
                    <AiOutlinePrinter className={'print-icon'} onClick={() => setDialogOpen(true)} />
                </div>
                <FullScreenProgress setOpen={setLoading} open={loading} />
                <Dialog
                    open={dialogOpen}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth={'md'}
                    // scroll={'body'}
                    className={classes.dialogStyle}
                    onClose={() => setDialogOpen(false)}
                >
                    <OrderInvoice order={order} invoiceRef={invoiceRef} />
                </Dialog>
                <div className={classes.actionStyle}>
                    <FiDownload className={'icon'} onClick={onDownloadClick} />
                    <IoMdClose className={'icon'} onClick={() => setDialogOpen(false)} />
                </div>
            </div>
        </>
    );
};

export default OrderViewHeader;
