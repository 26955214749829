import React, { useEffect, useState } from 'react';
import { Dialog, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FiCheck, IoClose, MdAdd } from "react-icons/all";
import { Colors } from "../../../helpers/Color";
import axios from "axios";
import connection from 'components/app/auth/api';

const SingleCategory = ({ category, classes, setLoading, dynamicCategories, setOpen, setCategories }) => {
    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        const filteredCat = dynamicCategories.filter(item => item.category.name === category.name)
        if (filteredCat.length !== 0) {
            setIsAdded(true)
        } else {
            setIsAdded(false)
        }
    }, [category.name, dynamicCategories]);


    const onAddIconClick = () => {
        setLoading(true)
        connection.post('/post/dynamicCategory', { data: category }).then(() => {
            connection.get('/get/dynamicCategories').then(res => {
                setCategories(res.data)
                setLoading(false)
                setOpen(false)
            })
        })
    }

    return (
        <div className={classes.singleCategory}>
            <h6>{category.name}</h6>
            <div>
                {
                    isAdded ?
                        <FiCheck className={'check-icon'} />
                        :
                        <MdAdd className={'add-icon'} onClick={onAddIconClick} />
                }
            </div>
        </div>
    )
}

const SingleSpecialityCategory = ({ category }) => {
    return (
        <div>
            <h6>{category.name}</h6>
        </div>
    )
}

const DynamicCategoriesModel = ({ open, setOpen, specialityCategories, setLoading,
    dynamicCategories, generalCategories, setCategories }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(({
        container: {
            display: 'flex',
            width: '100%',
            padding: '20px',
            position: 'relative',
            justifyContent: 'space-between',
            '& .categories': {
                width: '45%',
            },
        },
        closeIcon: {
            position: 'absolute',
            top: 10,
            right: 10,
            fontSize: '22px'
        },
        singleCategory: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 0',
            '& h6': {
                margin: 0
            },
            '& div': {
                '& .add-icon': {
                    fontSize: is430pxBelow ? '18px' : '22px',
                    color: Colors.primary,
                    cursor: 'pointer'
                },
                '& .check-icon': {
                    fontSize: is430pxBelow ? '18px' : '22px',
                    color: Colors.success,
                    cursor: 'pointer'
                },
            },
        }
    }))

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles()

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            maxWidth={'sm'}
        >
            <div className={classes.container}>
                <div className={'categories'}>
                    <h5>General Categories</h5>
                    <div>
                        {
                            generalCategories.map(category => (
                                <SingleCategory key={category._id} dynamicCategories={dynamicCategories} setOpen={setOpen}
                                    category={category} classes={classes} setLoading={setLoading} setCategories={setCategories} />
                            ))
                        }
                    </div>
                </div>
                <div className={'categories'}>
                    <h5>Speciality Categories</h5>
                    <div>
                        {
                            specialityCategories.map(category => (
                                <SingleCategory key={category._id} dynamicCategories={dynamicCategories} setOpen={setOpen}
                                    category={category} classes={classes} setLoading={setLoading} setCategories={setCategories} />
                            ))
                        }
                    </div>
                </div>
                <IoClose className={classes.closeIcon} onClick={handleClose} />
            </div>
        </Dialog>
    );
};

export default DynamicCategoriesModel;
