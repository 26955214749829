import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../helpers/Color";
import PageBannerTable from './section/PageBannerTable';

function PageBanner({ PageBanner, setPageBanner, onVisibleChange }) {

    const useStyles = makeStyles(({
        container: {
            margin: '15px',
            '& .banner-container': {
                margin: '15px 0',
                '& .header': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& h5': {
                        margin: '0',
                        color: Colors.darkText,
                    },
                    '& .select-banner': {
                        border: `1px solid ${Colors.primary}`,
                        color: Colors.primary,
                        padding: '6px 12px',
                        fontWeight: 'bold',
                        background: Colors.light,
                        transition: 'all .3s',
                        '&:hover': {
                            transition: 'all .3s',
                            background: Colors.primary,
                            color: Colors.light,
                        },
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }
                },
            }
        }
    }))
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={'banner-container'}>
                <div className={'header'}>
                    <h5>Page Banners</h5>

                </div>
            </div>
            <PageBannerTable PageBanner={PageBanner} setPageBanner={setPageBanner} onVisibleChange={onVisibleChange} />
        </div>
    )
}

export default PageBanner