import React, { useEffect, useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../helpers/Color";
import { IoCloseCircle, IoMdCloudUpload } from "react-icons/all";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { Select, Space } from 'antd';
import connection from 'components/app/auth/api';


const AddCategory = () => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& .text-field': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: `1px solid ${Colors.primary}`,
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                },
                '& .upload-logo': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    alignItems: 'center',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.primary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.primary}`,
                        '& .top-icon': {
                            color: Colors.primary
                        },
                        '& h6': {
                            color: Colors.primary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.primary,
                    border: `2px solid ${Colors.primary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .logo-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '100px',
                    margin: '0 10px',
                    background: Colors.light,
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            },
            '& .gallery': {
                display: 'flex',
                flexWrap: 'wrap',
                '& .gallery-container': {
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '5px',
                    position: 'relative',
                    margin: '20px 10px 0',
                    height: '100px',
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .update-delete': {
                        position: 'absolute',
                        display: 'flex',
                        top: -10,
                        right: -10,
                        '& .delete': {
                            fontSize: '18px',
                            color: Colors.error,
                            margin: '0 3px'
                        },
                        '& .update': {
                            fontSize: '18px',
                            color: Colors.primary,
                            margin: '0 3px'
                        },
                    },
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                }
            },
            '& .upload-gallery': {
                padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                margin: '15px 0',
                borderRadius: '5px',
                display: 'flex',
                cursor: 'pointer',
                width: '150px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                transition: 'all .4s',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.secondary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.secondary}`,
                    '& .top-icon': {
                        color: Colors.secondary
                    },
                    '& h6': {
                        color: Colors.secondary
                    }
                },
                '& .top-icon': {
                    color: Colors.light
                },
                background: Colors.secondary,
                border: `2px solid ${Colors.secondary}`,
                '& input': {
                    display: 'none'
                },
                '& label': {
                    margin: 0,
                    cursor: 'pointer',
                    '& h6': {
                        margin: '0 0 0 7px',
                        fontSize: is770pxBelow ? '10px' : '12px',
                        color: Colors.light,
                    }
                },
            },
        }
    }))

    const classes = useStyles();

    const [category, setCategory] = useState({ logo: null, banners: [], name: '', description: '', parentGroup: '' });
    const [bannerImageUrl, setBannerImageUrl] = useState(null);

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [logoUrl, setLogoUrl] = useState(null)
    const [galleryImageUrl, setGalleryImageUrl] = useState([])
    const [groups, setGroups] = useState([])

    let galleryImg = []

    useEffect(() => {
        connection.get('/get/groups?limit=250').then(res => {
            setGroups(res?.data?.data)
        })
    }, [])

    console.log(groups, "groupsgroups");


    const handleDataChange = (e) => {
        setCategory({ ...category, [e.target.name]: e.target.value })
    }

    const onFilesChange = (e) => {
        setOpen(true)
        let formData = new FormData()
        formData.append('media', e.target.files[0])
        connection.post(`/post/fileUpload`, formData)
            .then((res) => {
                setCategory({ ...category, logo: res?.data.id });
                setLogoUrl(res?.data?.url)
                setOpen(false)
            })
    }

    const history = useHistory();

    const onUploadClick = () => {
        const { name, description, logo, banners, parentGroup } = category
        console.log(category, "sasa")
        if (name !== '' && description !== '' && logo !== null && banners.length !== 0, parentGroup != null) {
            setOpen(true)
            // const data = new FormData()
            // data.append('logo', logo)
            // banners.forEach(banner => {
            //     data.append('banners', banner)
            // })

            // data.append('data', JSON.stringify({ name, description }))

            let params = {
                data: {
                    name,
                    description,
                    logoUrl: logo,
                    bannerUrls: banners,
                    parentGroup

                }
            }

            connection.post('/post/generalCategory', params).then(res => {
                if (res.data.key === 'success') {
                    setOpen(false)
                    history.goBack()
                } else {
                    setOpen(false)
                    setErrorMessage(res.data.message)
                    setOpenError(true)
                }
            }).catch(err => {
                setOpen(false)
                console.log(err)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    const onChange = (imageList) => {
        // const tempArr = [];
        // imageList.forEach(data => {
        //     tempArr.push(data.file);
        // });
        // setBannerImageUrl(imageList)
        // setCategory({ ...category, banners: tempArr });

        setOpen(true)
        const tempArr = [];
        imageList.forEach(data => {
            tempArr.push(data.file);
        });
        let formData = new FormData()
        tempArr.length > 0 && tempArr?.map((val) => {
            formData.append('media', val)
        })

        connection.post(`/post/fileUpload`, formData)
            .then((res) => {
                galleryImg = galleryImageUrl
                galleryImg.push(res?.data)
                setGalleryImageUrl([...galleryImg])
                setCategory({ ...category, banners: [...galleryImg]?.map((data) => data?.id) });
                setOpen(false)
            })

    };

    return (
        <div className={classes.container}>
            <h5>General Category Information</h5>
            <div className={'input-container'}>
                <div className={'text-field'}>
                    <h6>Name</h6>
                    <input value={category.name} name={'name'} onChange={handleDataChange} />
                </div>
                <div className={'text-field'}>
                    <h6>Description</h6>
                    <input value={category.description} name={'description'} onChange={handleDataChange} />
                </div>
                <div className={classes.container}>
                    <div className={'input-container'} style={{ display: 'flex', }}>

                        <div className={'select-container'}>
                            <h6>Group</h6>
                            <select name={'parentCategory'} value={category?.parentGroup} onChange={(e) => setCategory({ ...category, parentGroup: e.target.value })} >
                                <option value="" disabled selected>Groups</option>
                                {
                                    groups.map((pCOptions, index) => (
                                        <option key={index} value={pCOptions?.name}>{pCOptions?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className={'upload-logo'}>
                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                    <div>
                        <label htmlFor={'logo-picker'}><h6>UPLOAD THUMBNAIL</h6></label>
                        <input id={'logo-picker'} type={'file'} size={'60'}
                            accept={'image/*'}
                            name={'logo'}
                            multiple={false}
                            onChange={onFilesChange} />
                    </div>
                </div>
                {
                    logoUrl !== null ?
                        <div className={'logo-container'}>
                            <img alt={'logo'} src={logoUrl} />
                        </div> : <></>
                }
                <ImageUploading
                    multiple
                    value={galleryImageUrl}
                    onChange={onChange}
                    maxNumber={6}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        dragProps,
                    }) => (
                        <div>
                            <div className={'upload-gallery'} onClick={onImageUpload} {...dragProps}>
                                <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                                <div>
                                    <label><h6>UPLOAD BANNERS</h6></label>
                                </div>
                            </div>
                            <div className={'gallery'}>
                                {imageList.map((image, index) => (
                                    <div key={index} className={'gallery-container'}>
                                        <img alt={'Thumbnail'} src={image['url']} />
                                        <div className={'update-delete'}>
                                            {/*<BsArrowRepeat onClick={() => onImageUpdate(index)} className={'update'}/>*/}
                                            <IoCloseCircle onClick={() => { setGalleryImageUrl(galleryImageUrl.filter(val => val.id !== image?.id)); }} className={'delete'} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </ImageUploading>
            </div>
            <div className={'upload'} onClick={onUploadClick}>
                Upload
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={open} setOpen={setOpen} />
        </div>
    );
};

export default AddCategory;
