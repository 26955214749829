import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import connection from '../auth/api'
import GroupHeader from './GroupHeader'
import FullScreenProgress from 'components/helpers/FullScreenProgress'
import GroupTable from './GroupTable'

function Groups() {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [groups, setGroups] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/groups?limit=250').then(res => {
            setGroups(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <GroupHeader groups={groups} setFilteredData={setFilteredData} />
            <GroupTable groups={groups} setGroups={setGroups}
                filteredData={filteredData} setFilteredData={setFilteredData} />
            <FullScreenProgress open={loading} setOpen={setLoading} />

        </div>
    );
};

export default Groups