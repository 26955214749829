import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import AddBlogContent from "../addBlog/section/AddBlogContent";
import EditBlogImage from "./section/EditBlogImage";
import EditBlogContent from "./section/EditBlogContent";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const EditBlogPage = () => {

    const defaultData = {
        name: '',
        blogContent: '',
        image: null,
        writtenBy: ''
    }

    const [blog, setBlog] = useState(defaultData);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        connection.get(`/get/blog?id=${id}`).then(res => {
            setBlog(res.data)
            setLoading(false)
        })
    }, [id]);

    if (loading) {
        return (
            <FullScreenProgress open={loading} setOpen={setLoading} />
        )
    } else {
        return (
            <div>
                <EditBlogImage blog={blog} setBlog={setBlog} />
                <EditBlogContent blog={blog} setBlog={setBlog} />
            </div>
        );
    }
};

export default EditBlogPage;
