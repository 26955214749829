import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import SpecialityCategoriesHeader from "./section/SpecialityCategoriesHeader";
import SpecialityCategoriesTable from "./section/SpecialityCategoriesTable";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import connection from '../auth/api';

const SpecialityCategory = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))

    const classes = useStyles()

    const [categories, setCategories] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        connection.get('/get/specialityCategories?limit=250').then(res => {
            setCategories(res.data.data)
            setLoading(false)
        })
    }, [])

    return (
        <div className={classes.container}>
            <SpecialityCategoriesHeader categories={categories} setFilteredData={setFilteredData} />
            <SpecialityCategoriesTable categories={categories} setFilteredData={setFilteredData}
                filteredData={filteredData} setCategories={setCategories} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default SpecialityCategory;
