import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import axios from "axios";
import connection from 'components/app/auth/api';

const EditCategoriesAndSubCategories = ({ product, setProduct }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            margin: '30px 0 0',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                '& .select-container': {
                    width: is430pxBelow ? '100%' : '35%',
                    margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                    '& h6': {
                        margin: '0 0 8px',
                        fontSize: '14px',
                        color: Colors.darkText
                    },
                    '& select': {
                        width: '100%',
                        border: `1px solid ${Colors.primary}`,
                        padding: '8px',
                        '&:focus': {
                            outline: 0
                        },
                        borderRadius: '5px',
                    },
                },
            }
        }
    }))

    const classes = useStyles();

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [groups, setGroups] = useState([])

    useEffect(() => {
        getCategories()
            .then(({ data }) => {
                setCategoryOptions(data.data)
            })
        connection.get('/get/groups?limit=250').then(res => {
            setGroups(res.data.data)
        })
    }, [])

    useEffect(() => {
        if (product.generalCategory) {
            getSubCategory(product.generalCategory)
                .then(({ data }) => {
                    setSubCategoryOptions(data?.data)
                })
        }
    }, [product.generalCategory])

    function getCategories() {
        return connection.get('/get/generalCategories?limit=250')
    }

    function getSubCategory(categoryName) {
        return connection.post(`/get/generalSubCategoriesByCategoryName`, { categoryName })
    }

    const onCategoryChange = (e) => {
        setProduct({ ...product, generalCategory: e.target.value, subCategory: "" })
        connection.post(`/get/generalSubCategoriesByCategoryName`, { categoryName: e.target.value }).then(res => {
            setSubCategoryOptions(res.data.data)
        })

    }
    console.log(product?.group, "asdasd");
    const onSubCategoryChange = (e) => {
        setProduct({ ...product, generalSubCategory: e.target.value })
    }

    const onGroupChange = (e) => {
        setProduct({ ...product, group: e.target.value, generalSubCategory: '', generalSubCategory: '' })
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={'input-container'} style={{ display: 'flex', }}>
                    <div className={'select-container'}>
                        <h6>Group</h6>
                        <select name={'parentCategory'} value={product.group} onChange={(e) => onGroupChange(e)} >
                            <option value="" disabled selected>Groups</option>
                            {
                                groups.map((pCOptions, index) => (
                                    <option key={index} value={pCOptions.name}>{pCOptions.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className={classes.container}>
                <h5>Product Type</h5>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="product-type" name="product-type">
                        <FormControlLabel checked={product.isGeneralProduct} control={<Radio />}
                            onClick={() => setProduct({
                                ...product,
                                isGeneralProduct: true,
                                generalCategory: "",
                                generalSubCategory: "",
                                specialityCategory: "",
                                specialitySubCategory: "",
                            })}
                            label="General Product" />
                        <FormControlLabel checked={!product.isGeneralProduct}
                            onClick={() => setProduct({
                                ...product,
                                isGeneralProduct: false,
                                generalCategory: "",
                                generalSubCategory: "",
                                specialityCategory: "",
                                specialitySubCategory: "",
                            })}
                            control={<Radio />} label="Speciality Product" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={classes.container}>
                <h5>Product Information</h5>
                <div className={'input-container'} style={{ display: 'flex' }}>
                    <div className={'select-container'}>
                        <h6>Parent category</h6>
                        <select name={'parentCategory'} value={product.generalCategory} onChange={onCategoryChange}>
                            <option value="" disabled selected>Parent category</option>
                            {
                                categoryOptions.map((arr, index) => {
                                    if (product?.group === arr?.parentGroup) {
                                        return <option key={index} value={arr.name}>{arr.name}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    <div className={'select-container'}>
                        <h6>Sub category</h6>
                        <select name={'subCategory'} value={product.generalSubCategory !== null ? product.generalSubCategory : ""}
                            onChange={onSubCategoryChange}>
                            <option value="" disabled selected>Sub category</option>
                            {
                                subCategoryOptions.map((sCOptions, index) => (
                                    <option key={index} value={sCOptions.name}>{sCOptions.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategoriesAndSubCategories;
