
import React, { useEffect, useState } from 'react'
import { Colors } from "../../helpers/Color";
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TablePagination, Typography } from '@material-ui/core';
import axios from 'axios';
import FullScreenProgress from 'components/helpers/FullScreenProgress';
import { Divider, Pagination } from 'antd';
import { RiDeleteBin6Line, IoMdDocument } from "react-icons/all";
import Swal from 'sweetalert2';
import CustomToast from 'components/helpers/Toast';
import connection from '../auth/api';

// import IconButton from "@mui/material/IconButton";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
// import DeleteIcon from "@mui/icons-material/Delete";


function AddMedia() {
    const [data, setData] = useState({ data: [], total_count: 0 })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [loader, setLoader] = useState(true)
    const [openToast, setOpenToast] = useState(false)
    const [toastMsg, setToastMsg] = useState('')
    const [openMdl, setOpenMdl] = useState(false)
    const [UploadFile, setUploadFile] = useState([])

    const handleCopyClick = (text, type) => {
        navigator.clipboard.writeText(text);
        setToastMsg(`Your Image ${type} is Copied`)
        setOpenToast(true)
    };

    useEffect(() => {
        fetchImages()
    }, [page, limit])

    const handleChangePage = (event, newPage) => {
        setLoader(true)
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setLoader(true)
        setLimit(+event.target.value);
        setPage(1);
    };

    const onDeleteClick = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            // setLoader(true)
            if (result.isConfirmed) {
                connection.delete('/delete/file', { data: { imageId: id } }).then(res => {
                    console.log(res?.data, "resres");
                    // console.log(res.data)
                    // setFilteredData([])
                    Swal.fire({
                        title: "Media Delete",
                        text: res.data?.message, // Use the dynamically generated message here
                        icon: res.data?.key === "success" ? "success" : "warning",
                    });
                    connection.get(`/get/files?page=${page}&limit=${limit}`).then(res => {
                        // setLoader(false)
                        setData({ data: res.data.data, total_count: res.data.totalCount })
                    })

                })

            }
        })

    }

    const DataCard = ({ item }) => {
        return (
            <Card style={{ alignItems: 'center' }}
            >
                {/* <div style={{ textAlign: 'center', padding: '5px' }}>
                    <img src={item?.url} alt={item?.name} style={{ maxWidth: "100%", justifyContent: 'center' }} height={200} />
                </div> */}
                <div class="image-container" style={{ width: '100%', textAlign: 'center' }}>

                    <img src={item?.url} alt={item?.name} style={{ maxWidth: "100%", justifyContent: 'center' }} height={200} />
                </div>

                <Divider />

                <CardActions style={{ justifyContent: 'space-between' }}>
                    <Button variant='outlined'
                        onClick={() => handleCopyClick(item._id, 'Id')}
                    >
                        Copy ID
                    </Button>
                    <Button variant='outlined'
                        onClick={() => handleCopyClick(item.url, 'URL')}
                    >
                        Copy URL
                    </Button>

                </CardActions>
            </Card>
        )
    }
    const fetchImages = () => {
        connection.get(`/get/files?page=${page}&limit=${limit}`)
            .then(res => {
                setData({ data: res.data?.data, total_count: res.data.totalCount })
                setLoader(false)
            })
    }

    const onChange = (e) => {
        setUploadFile(e.target.files)
    }

    const handleClose = () => {
        setOpenMdl(false)
        setUploadFile([])
    }

    const handleSubmit = () => {
        const form = new FormData()
        console.log(UploadFile[0], "UploadFile[0]", UploadFile);
        UploadFile.length > 0 && form.append('media', UploadFile[0])
        connection.post(`/post/fileUpload`, form)
            .then(res => {
                setToastMsg(res.data.message)
                setOpenToast(true)
                fetchImages()
                handleClose()
            })
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{
                    color: Colors.primary,
                    fontSize: '20px'
                }} variant='h6'>Media</h5>
                <Button variant="contained" color='primary' component="label" onClick={() => setOpenMdl(true)}>
                    Upload
                </Button>

            </div>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                {data?.data?.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                        <DataCard item={item} />
                    </Grid>
                ))}
            </Grid>
            {data?.data?.length > 1 && <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={data?.total_count}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
            <Dialog
                open={openMdl}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Upload Image
                </DialogTitle>
                <DialogContent>
                    <div style={{ marginTop: '30px' }}>
                        <Typography>Image Upload:</Typography>
                        <Button variant="contained" color='primary' fullWidth component="label">
                            Upload
                            <input hidden type="file" accept=".csv, .jpeg, .jpg, .png" onChange={(e) => onChange(e)} />
                        </Button>
                        {UploadFile.length > 0 &&

                            <div style={{ display: 'flex', justifyContent: "space-between", border: '1px solid #EBEEF5', padding: '10px', borderRadius: '8px', marginTop: '5px' }}>
                                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                    <IoMdDocument color='#2086CF' fontSize='20px' />
                                    <Typography color={'#5369f8'}>{UploadFile[0].name}</Typography>
                                </div>

                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: '30px' }}>
                    <Button onClick={handleClose} variant='outlined' color='primary'>Close</Button>
                    <Button onClick={handleSubmit} variant='contained' color='primary' >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            <FullScreenProgress open={loader} setOpen={setLoader} />
            <CustomToast type={'success'} openToast={openToast} setOpenToast={setOpenToast} message={toastMsg} />

        </div>
    )
}

export default AddMedia