import React, {useState} from 'react';
import {useMediaQuery} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Colors} from "../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

const UserInProductsTable = ({viewedProducts}) =>  {

    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Product Name',
            align: 'center',
        },
        {
            name: 'Viewed',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow:{
            '&:hover': {
                backgroundColor: Colors.primaryLight+' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px',
            '& .checked':{
                fontSize: '22px',
                color: Colors.success,
            },
            '& .close':{
                fontSize: '20px',
                color: Colors.error,
            },
        },
        tableImage:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img':{
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon':{
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
            },
            '& .view-icon':{
                fontSize: is430pxBelow ? '18px' : '24px',
                color: Colors.darkText,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
        },
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);


    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(col => (
                                    <StyledTableCell align={col.align}>
                                        <h6 className={classes.colTextStyle}>{col.name}</h6>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
                                    <TableCell align={'left'}>
                                        <h6 className={classes.rowTextStyle}>{index + 1}</h6>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <h6 className={classes.rowTextStyle} style={{margin: '0 auto'}}>{row.name}</h6>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <h6 className={classes.rowTextStyle}>{row.count}</h6>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                viewedProducts.length === 0 ? <h6 style={{textAlign: 'center', marginTop: '25px', color: Colors.darkText}}>No Data Found</h6> : <></>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={viewedProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default UserInProductsTable;
