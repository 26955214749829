import Home from "./components/shared/home";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PageLoader from "./components/shared/PageLoader";
import { etabxServerUrl } from "./index";
import AuthPageTwo from "./components/app/auth/AuthPageTwo";

function App() {

    const isLogin = useSelector((state) => state.IsLogin.value)

    const [loading, setLoading] = useState(false);

    if (loading) {
        return (
            <PageLoader />
        )
    } else {
        if (isLogin) {
            return (
                <Router>
                    <Home setLoading={setLoading} />
                </Router>
            )
        } else {
            return (
                <Router>
                    <AuthPageTwo />
                </Router>
            )
        }
    }
}

export default App;
