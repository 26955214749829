import React, { useState } from 'react';
import axios from "axios";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Colors } from "../../../helpers/Color";
import connection from 'components/app/auth/api';

const Category = ({ boxNumber, isDragging, category, handleDrag, index, handleDrop }) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')

    const useStyles = makeStyles(({
        categoryContainer: {
            backgroundColor: Colors.primaryLight,
            // border: `1px solid ${Colors.darkBorder}`,
            cursor: isDragging ? 'grabbing' : 'grab',
            borderRadius: '5px',
            '& :hover': {
                backgroundColor: Colors.secondaryLight,
                // border: `1px solid ${Colors.darkBorder}`,
            },
            marginBottom: '10px',
            '& .details': {
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                '& .data': {
                    '& h6': {
                        fontSize: is1025pxBelow ? '13px' : 'auto',
                        color: Colors.darkText,
                        margin: '5px 10px'
                    }
                },
                '& .image-container': {
                    width: '70px',
                    height: '70px',
                    padding: '6px',
                    borderRadius: '5px',
                    border: `1px solid ${Colors.darkBorder}`,
                    '& img': {
                        maxWidth: '100%',
                        maxHeight: '100%'
                    }
                }
            },
        }
    }))

    const classes = useStyles()

    return (
        <div
            draggable={true}
            id={boxNumber}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
            className={classes.categoryContainer}
        // style={{
        //     backgroundColor: '#bfbfbf',
        //     border: "1px solid",
        //     borderRadius: "5px",
        //     width: "30%",
        //     cursor: isDragging ? 'grabbing' : 'grab',
        //     height: "100px"
        // }}
        >
            <div className={'details'}>
                <div className={'image-container'}
                    style={{ backgroundColor: category.isGeneralCategory ? Colors.light : Colors.primary }}>
                    <img alt={category.name} src={category.logoUrl} />
                </div>
                <div className={'data'}>
                    <h6>{category.name}</h6>
                    <h6>{category.isGeneralCategory ? 'General Category' : 'Speciality Category'}</h6>
                </div>
            </div>
        </div>
    );
};

const DraggingCategory = ({ categories, setCategories }) => {

    const [isDragging, setIsDragging] = useState(false);

    const [dragId, setDragId] = useState();

    const handleDrag = (ev) => {
        setIsDragging(true)
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        const dragBox = categories.find((box) => box._id === dragId);
        const dropBox = categories.find((box) => box._id === ev.currentTarget.id);

        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;

        const newBoxState = categories.map((category) => {
            if (category._id === dragId) {
                category.order = dropBoxOrder;
            }
            if (category._id === ev.currentTarget.id) {
                category.order = dragBoxOrder;
            }
            return category;
        });
        connection.put('/update/mainBarCategories', { data: newBoxState }).then(res => {
            setCategories(res.data);
        }).catch(err => {
            console.log(err)
        })
        setIsDragging(false)
    };



    return (
        <div>
            {categories
                .sort((a, b) => a.order - b.order)
                .map((category, index) => (
                    <Category
                        key={category._id}
                        // boxColor={box.color}
                        boxNumber={category._id}
                        handleDrag={handleDrag}
                        isDragging={isDragging}
                        index={index}
                        category={category}
                        handleDrop={handleDrop}
                    />
                ))}
        </div>
    );
};

export default DraggingCategory;
