import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../../helpers/Color";
import {useHistory} from "react-router-dom";
import axios from "axios";
import AdsBannerTable from "./section/AdsBannerTable";
import {notification} from "antd";

const AdsBanner = ({adsBanners, setAdsBanners}) => {
    const useStyles = makeStyles(({
        container: {
            margin: '40px 15px',
            '& .banner-container': {
                margin: '15px 0',
                '& .header': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& h5': {
                        margin: '0',
                        color: Colors.darkText,
                    },
                    '& .select-banner': {
                        border: `1px solid ${Colors.primary}`,
                        color: Colors.primary,
                        padding: '6px 12px',
                        fontWeight: 'bold',
                        background: Colors.light,
                        transition: 'all .3s',
                        '&:hover':{
                            transition: 'all .3s',
                            background: Colors.primary,
                            color: Colors.light,
                        },
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }
                },
            }
        }
    }))

    const classes = useStyles();

    const history = useHistory();

    const onAddBannerClick = () => {
      if (adsBanners.length < 3){
          history.push('/banners/add-ads-banner')
      } else {
          notification.warning({
              message: 'Warning',
              description: 'Maximum Limit Reached'
          })
      }
    }

    return (
        <div className={classes.container}>
            <div className={'banner-container'}>
                <div className={'header'}>
                    <h5>Ads Banners</h5>
                    <div className={'select-banner'} onClick={onAddBannerClick}>
                        Add Banner
                    </div>
                </div>
            </div>
            <AdsBannerTable adsBanner={adsBanners} setAdsBanner={setAdsBanners}/>
        </div>
    );
};

export default AdsBanner;
