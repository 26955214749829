import React, { useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import ReactQuill from "react-quill";
import CustomToast from "../../../helpers/Toast";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import axios from "axios";
import { useHistory } from "react-router-dom";
import connection from 'components/app/auth/api';

const EditBlogContent = ({ blog, setBlog }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')

    const useStyles = makeStyles(() => ({
        container: {
            marginTop: '50px',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px'
            },
            '& .input-container': {
                display: 'flex',
                margin: '20px 0',
                flexDirection: 'column',
                '& div': {
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: is430pxBelow ? '100%' : '50%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                }
            },
            '& .quill-con': {
                width: is430pxBelow ? '100%' : '50%',
                '& h5': {
                    color: Colors.primary,
                    fontSize: '20px',
                    margin: '60px 0 20px'
                },
                '& .quill': {
                    height: '150px',
                }
            },
            '& .upload': {
                width: is430pxBelow ? '100%' : '50%',
                background: Colors.primary,
                padding: '8px',
                marginTop: is770pxBelow ? '80px' : '60px',
                textAlign: 'center',
                borderRadius: '5px',
                color: Colors.light,
                border: `2px solid ${Colors.primary}`,
                transition: 'all .4s',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                    background: Colors.light,
                    color: Colors.primary,
                    transition: 'all .4s',
                    border: `2px solid ${Colors.primary}`,
                },
            }
        }
    }))

    const classes = useStyles();

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBlogContentChange = (value) => {
        setBlog({ ...blog, blogContent: value })
    }

    const onDataChange = (e) => {
        setBlog({ ...blog, [e.target.name]: e.target.value })
    }

    const history = useHistory();

    const onUpdateClick = () => {
        const { _id, name, writtenBy, blogContent, image, imageUrl } = blog
        if (name !== '' && writtenBy !== '' && blogContent !== '') {
            setLoading(true);
            const data = new FormData()
            if (image !== null) {
                data.append('image', image)
            }
            data.append('data', JSON.stringify({ _id, name, writtenBy, blogContent, imageUrl }))
            connection.put('/update/blog', data).then(res => {
                if (res.data.key === 'success') {
                    setLoading(false)
                    history.goBack()
                } else {
                    setLoading(false)
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                }
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    return (
        <div className={classes.container}>
            <h5>Blog Details</h5>
            <div className={'input-container'}>
                <div>
                    <h6>Name</h6>
                    <input value={blog.name} onChange={onDataChange} name={'name'} />
                </div>
                <div>
                    <h6>Written By</h6>
                    <input value={blog.writtenBy} onChange={onDataChange} name={'writtenBy'} />
                </div>
            </div>
            <div className={'quill-con'}>
                <h5>Blog Content</h5>
                <ReactQuill styles={{ maxHeight: '200px' }}
                    value={blog.blogContent}
                    onChange={handleBlogContentChange} />
            </div>
            <div className={'upload'} onClick={onUpdateClick}>
                Update
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default EditBlogContent;
