import React, { useEffect, useState } from 'react';
import { createTheme, Switch, useMediaQuery } from "@material-ui/core";
import { makeStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../../helpers/Color";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BiEdit, RiDeleteBin6Line } from "react-icons/all";
import connection from 'components/app/auth/api';


const Row = ({ row, index, classes, id, user, setFilteredData, setProducts }) => {

    const onDeleteProductClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                connection.delete('/delete/productRequest', { data: { id } }).then(() => {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    ).then(() => {
                        connection.get('/get/productRequests').then(res => {
                            setFilteredData([])
                            setProducts(res.data)
                        })
                    })
                })
            }
        })
    }

    return (
        <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row._id}>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{index + 1}</h6>
            </TableCell>
            <TableCell align={'left'}>
                <h6 className={classes.rowTextStyle}>{row.name}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <div className={classes.tableImage}>
                    <img src={typeof row.thumbnailUrl === "string" ? row.thumbnailUrl : row?.thumbnailUrl?.url} alt={row.name} />
                </div>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? 'General Product' : 'Speciality Product'}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{row.isGeneralProduct ? row.generalCategory : row.specialityCategory}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{user.username}</h6>
            </TableCell>
            <TableCell align={'center'}>
                <h6 className={classes.rowTextStyle}>{user.email}</h6>
            </TableCell>
            <TableCell align={'right'}>
                <div className={classes.actionContainer}>
                    <RiDeleteBin6Line className={'delete-icon'} onClick={onDeleteProductClick} />
                </div>
            </TableCell>
        </TableRow>
    );
}


const ProductRequestTable = ({
    filteredData,
    products,
    setProducts,
    setFilteredData,
    params,
    totalCount,
    handleChangeRowsPerPage,
    handleChangePage
}) => {
    const columns = [
        {
            name: 'No',
            align: 'left',
        },
        {
            name: 'Name',
            align: 'left',
        },
        {
            name: 'Thumbnail',
            align: 'center',
        },
        {
            name: 'Product Type',
            align: 'center',
        },
        {
            name: 'Category',
            align: 'center',
        },
        {
            name: 'Username',
            align: 'center',
        },
        {
            name: 'Email',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const is430pxBelow = useMediaQuery('(max-width:430px)')



    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 650,
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            fontSize: is430pxBelow ? '11px' : '14px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.darkText,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px'
        },
        tableImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            border: `1px solid ${Colors.lightBorder}`,
            margin: '0 auto',
            height: is430pxBelow ? '65px' : '80px',
            width: is430pxBelow ? '65px' : '80px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .delete-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.error,
                marginRight: is430pxBelow ? '5px' : '8px'
            },
            '& .edit-icon': {
                fontSize: is430pxBelow ? '18px' : '22px',
                color: Colors.primary
            },
        },
        noImageFound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .icon': {
                fontSize: '24px',
                color: Colors.darkText
            },
            '& h6': {
                fontSize: '10px',
                maxWidth: '100px',
                margin: '8px 0 0',
                color: Colors.darkText
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: Colors.primary,
        },
    }))(TableCell);

    const displayingProducts = filteredData.length !== 0 ? filteredData : products

    const [loading, setLoading] = useState(false);
    const theme = createTheme({
        palette: {
            secondary: {
                main: Colors.primary,
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map(col => (
                                        <StyledTableCell align={col.align}>
                                            <h6 className={classes.colTextStyle}>{col.name}</h6>
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayingProducts?.length > 0 && displayingProducts?.map((row, index) => (
                                <Row id={row._id} row={row.product} user={row.user} index={index} classes={classes} setFilteredData={setFilteredData}
                                    setProducts={setProducts} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



                {
                    displayingProducts.length === 0 ? <h6 style={{ textAlign: 'center', marginTop: '25px', color: Colors.darkText }}>No Data Found</h6> : <></>
                }
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={params?.limit}
                    page={params?.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <FullScreenProgress open={loading} setOpen={setLoading} />
            </Paper>
        </MuiThemeProvider>
    );
};

export default ProductRequestTable;
