import React, { useState } from 'react'
import { Colors } from "../../../helpers/Color";
import { Accordion, AccordionDetails, AccordionSummary, capitalize, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from 'antd';
import ImageUploading from "react-images-uploading";
import axios from 'axios';
import { IoCloseCircle, IoMdCloudUpload } from "react-icons/all";
import { ImCircleDown } from "react-icons/im";
import connection from 'components/app/auth/api';



function VarientPage({ product, setProduct }) {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')


    const useStyles = makeStyles(() => ({
        container: {
            width: '100%',
            '& h5': {
                color: Colors.primary,
                fontSize: '20px',
                marginTop: '55px',
                marginBottom: '20px'
            },
            '& .input-container': {
                display: 'flex',
                width: '80%',
                // gap: '10px',
                '& div': {
                    // width: '48%',
                    '& h6': {
                        color: Colors.darkText,
                        fontSize: '16px',
                        margin: '5px 0'
                    },
                    '& input': {
                        border: `1px solid ${Colors.primary}`,
                        '&:focus': {
                            outline: 0
                        },
                        width: '70%',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0'
                    }
                },

                '& .upload-gallery': {
                    padding: is770pxBelow ? '2px 6px 4px' : '4px 8px 6px',
                    margin: '15px 0',
                    borderRadius: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '150px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    transition: 'all .4s',
                    '&:hover': {
                        background: Colors.light,
                        color: Colors.secondary,
                        transition: 'all .4s',
                        border: `2px solid ${Colors.secondary}`,
                        '& .top-icon': {
                            color: Colors.secondary
                        },
                        '& h6': {
                            color: Colors.secondary
                        }
                    },
                    '& .top-icon': {
                        color: Colors.light
                    },
                    background: Colors.secondary,
                    border: `2px solid ${Colors.secondary}`,
                    '& input': {
                        display: 'none'
                    },
                    '& label': {
                        margin: 0,
                        cursor: 'pointer',
                        '& h6': {
                            margin: '0 0 0 7px',
                            fontSize: is770pxBelow ? '10px' : '12px',
                            color: Colors.light,
                        }
                    },
                },
                '& .thumb-container': {
                    height: '100px',
                    width: '100px',
                    border: `1px solid ${Colors.lightBorder}`,
                    borderRadius: '5px',
                    margin: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%'
                    }
                },
                '& .gallery': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& .gallery-container': {
                        border: `1px solid ${Colors.lightBorder}`,
                        borderRadius: '5px',
                        position: 'relative',
                        margin: '20px 10px 0',
                        height: '100px',
                        width: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .update-delete': {
                            position: 'absolute',
                            display: 'flex',
                            top: -10,
                            right: -10,
                            '& .delete': {
                                fontSize: '18px',
                                color: Colors.error,
                                margin: '0 3px'
                            },
                            '& .update': {
                                fontSize: '18px',
                                color: Colors.primary,
                                margin: '0 3px'
                            },
                        },
                        '& img': {
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }
                    }
                }
            },
            '& .select-container': {
                width: is430pxBelow ? '100%' : '30%',
                margin: is430pxBelow ? '15px 0 15px' : '15px 25px 5px 0',
                '& h6': {
                    margin: '0 0 8px',
                    fontSize: '14px',
                    color: Colors.darkText
                },
                '& select': {
                    width: '100%',
                    border: `1px solid ${Colors.primary}`,
                    padding: '8px',
                    '&:focus': {
                        outline: 0
                    },
                    borderRadius: '5px',
                },
            },

        }
    }))

    const [primary, setPrimary] = useState({ termsOpt1: [{ terms: '' }], primaryAtr1: '' })
    const [secondary, setSecondary] = useState({ termsOpt2: [{ terms: '' }], primaryAtr2: '' })
    let galleryImg = []


    const classes = useStyles();

    let Type = [{ label: 'Standard', value: 'standard' }, { label: 'Variant', value: 'variant' }]

    const addPrimaryTerms1 = () => {
        let options = primary?.termsOpt1
        options.push({ terms: '' })
        setPrimary({ ...primary, termsOpt1: options })

    }



    const RemoveTerms1 = (i) => {
        let options = primary?.termsOpt1
        options.splice(i, 1)
        setPrimary({ ...primary, termsOpt1: options })

    }

    const onTermsChange = (value, index) => {
        let opt = primary?.termsOpt1
        opt[index].terms = value
        setPrimary({ ...primary, termsOpt1: opt })
    }

    const addPrimaryTerms2 = () => {
        let options = secondary?.termsOpt2
        options.push({ terms: '' })
        setSecondary({ ...secondary, termsOpt2: options })
    }

    const RemoveTerms2 = (i) => {
        let options = secondary?.termsOpt2
        options.splice(i, 1)
        setSecondary({ ...secondary, termsOpt2: options })

    }

    const onTermsChange2 = (value, index) => {
        let opt = secondary?.termsOpt2
        opt[index].terms = value
        setSecondary({ ...secondary, termsOpt2: opt })
    }

    const handleGenrate = () => {
        const temp = [];
        primary?.termsOpt1.forEach(({ terms: primaryValue, }) => {
            secondary?.termsOpt2.forEach(({ terms: secondaryValue }) => {
                temp.push({
                    data: {
                        primaryValue,
                        secondaryValue,
                        primaryType: primary?.primaryAtr1,
                        secondaryType: secondary?.primaryAtr2
                    },
                    displayValues: {
                        title: '',
                        description: '',
                        galleryImages: [],
                        salesPrice: '',
                        urlSlug: '',
                        totalStocks: '',
                    }
                });
            });
        });
        setProduct({
            ...product,
            variants: temp,
            attributes: [{
                name: primary?.primaryAtr1,
                values: primary?.termsOpt1?.map((data) => data?.terms)
            },
            {
                name: secondary?.primaryAtr2,
                values: secondary?.termsOpt2?.map((data) => data?.terms)
            }
            ]
        })
    }

    const ongallerChange = async (imageList, index) => {
        const tempArr = [];
        imageList.forEach(data => {
            tempArr.push(data.file);
        });
        let formData = new FormData()
        tempArr.length > 0 && tempArr?.map((val) => {
            formData.append('media', val)
        })
        const { variants } = product
        let tempImage = [...variants]
        connection.post(`/post/fileUpload`, formData)
            .then(async (res) => {

                galleryImg = variants[index]?.displayValues?.galleryImages

                galleryImg.push(res?.data)
                tempImage[index] = {
                    ...tempImage[index],
                    displayValues: {
                        ...tempImage[index].displayValues,
                        ['galleryImages']: [...galleryImg]
                    }
                }
                setProduct({ ...product, variants: tempImage });

            })

    };


    const onDeleteImage = (imageId, index) => {
        const { variants } = product
        let tempImage = [...variants]
        const galleryImages = [...tempImage[index].displayValues.galleryImages];

        const imageIndex = galleryImages.findIndex(image => image.id === imageId);

        if (imageIndex !== -1) {
            galleryImages.splice(imageIndex, 1);
            tempImage[index].displayValues.galleryImages = galleryImages;
            setProduct({ ...product, variants: tempImage })
        }

    }

    const varientOnchange = (name, value, index) => {
        const { variants } = product
        let temProduct = [...variants]

        temProduct[index] = {
            ...temProduct[index],
            displayValues: {
                ...temProduct[index].displayValues,
                [name]: value
            }
        }
        setProduct({ ...product, variants: temProduct })

    }

    const onDiscountChange = (name, value, index) => {
        const { variants } = product
        let temProduct = [...variants]

        if (value < 100) {

            const discountPrice = value / 100 * variants[index]?.displayValues.purchasedPrice;
            const salesPrice = discountPrice !== 0 ? (variants[index]?.displayValues.purchasedPrice - discountPrice) : variants[index]?.displayValues.purchasedPrice
            temProduct[index] = {
                ...temProduct[index],
                displayValues: {
                    ...temProduct[index].displayValues,
                    [name]: value,
                    salesPrice
                }
            }
            setProduct({ ...product, variants: temProduct })
        }
    }

    const onSalesChange = (name, value, index) => {
        const { variants } = product
        let temProduct = [...variants]



        if (value <= parseInt(variants[index]?.displayValues.purchasedPrice)) {
            const discount = (100 - (value / variants[index]?.displayValues.purchasedPrice * 100)).toFixed(2)

            temProduct[index] = {
                ...temProduct[index],
                displayValues: {
                    ...temProduct[index].displayValues,
                    [name]: value,
                    discount
                }
            }
            setProduct({ ...product, variants: temProduct })
        }
    }

    return (
        <div className={classes.container}>
            <div>
                <h5>Product Type</h5>
                <div className={'select-container'}>
                    <h6>Product Type</h6>
                    <select name={'parentCategory'}
                        value={product.productType}
                        onChange={(e) => {
                            setProduct({ ...product, productType: e.target.value, variants: [], attributes: [] })
                            setPrimary({ termsOpt1: [{ terms: '' }], primaryAtr1: '', id: '' })
                            setSecondary({ termsOpt2: [{ terms: '' }], primaryAtr2: '', id: '' })
                        }}
                    >
                        <option value="" disabled selected>Product Type</option>
                        {
                            Type.map((pCOptions, index) => (
                                <option key={index} value={pCOptions.value}>{pCOptions.label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {product?.productType === "variant" && <div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>Attribute & Terms</h5>
                    <Button style={{ marginRight: '150px' }} onClick={() => handleGenrate()} type='primary'>Generate Variants</Button>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                    <div style={{ display: 'flex' }} className={'input-container'}>
                        <div >
                            <h6>Primary Attribute</h6>
                            <input value={primary?.primaryAtr1} name={'name'} onChange={e => setPrimary({ ...primary, primaryAtr1: e.target.value })} />
                        </div>

                        <div className={'input-container'}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%' }}>
                                <h6>Primary Terms</h6>
                                <Button type='primary' onClick={() => addPrimaryTerms1()}>Add Terms</Button>

                            </div>
                            <div style={{ display: 'flex', gap: '2px', width: '100%', flexDirection: 'column' }}>
                                {primary?.termsOpt1?.map((val, i) => {
                                    return (
                                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center', width: '100%' }}>
                                            <input value={primary?.termsOpt1?.[i].terms} name={'name'} onChange={e => onTermsChange(e.target.value, i)} />
                                            {primary?.termsOpt1.length !== 1 && <Button danger onClick={() => RemoveTerms1(i)}>Remove</Button>}
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </div>
                    </div>
                    <div style={{ display: 'flex' }} className={'input-container'}>
                        <div >
                            <h6>Secondary Attribute</h6>
                            <input value={secondary?.primaryAtr2} name={'name'} onChange={e => setSecondary({ ...secondary, primaryAtr2: e.target.value })} />
                        </div>

                        <div className={'input-container'}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%' }}>
                                <h6>Secondary Terms</h6>
                                <Button type='primary' onClick={() => addPrimaryTerms2()}>Add Terms</Button>

                            </div>
                            <div style={{ display: 'flex', gap: '2px', width: '100%', flexDirection: 'column' }}>
                                {secondary?.termsOpt2?.map((val, i) => {
                                    return (
                                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center', width: '100%' }}>
                                            <input value={secondary?.termsOpt2?.[i].terms} name={'name'} onChange={e => onTermsChange2(e.target.value, i)} />
                                            {secondary?.termsOpt2.length !== 1 && <Button danger onClick={() => RemoveTerms2(i)}>Remove</Button>}
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {product?.variants?.length > 0 && <h5>Product Variants</h5>}


                {product?.variants?.map((val, i) => (
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ImCircleDown />}
                            aria-controls={`panel${i}-content`}
                            id={`panel${i}-header`}
                        >
                            <h6>
                                {`${capitalize(val?.data?.primaryValue)}${val?.data?.secondaryValue ? "-" : ''}${capitalize(val?.data?.secondaryValue)}`}

                            </h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ display: 'flex', gap: '30px', width: '100%' }} >
                                <div style={{ display: 'flex', gap: "20px" }} className='input-container'>

                                    <div>
                                        <h6>Title</h6>
                                        <input name={'name'}
                                            onChange={(e) => varientOnchange('title', e.target.value, i)}
                                            value={val?.displayValues?.title}
                                        />
                                    </div>
                                    <div>
                                        <h6>Description</h6>
                                        <input name={'name'}
                                            value={val?.displayValues?.description}
                                            onChange={(e) => varientOnchange('description', e.target.value, i)}
                                        />
                                    </div>

                                    <div>
                                        <h6>MRP Price</h6>
                                        <input name={'name'}
                                            value={val?.displayValues?.purchasedPrice}
                                            onChange={(e) => varientOnchange('purchasedPrice', e.target.value, i)}

                                        />
                                    </div>
                                    <ImageUploading
                                        multiple
                                        value={val.displayValues?.galleryImages}
                                        onChange={(e) => { ongallerChange(e, i) }}
                                        maxNumber={6}
                                        dataURLKey="url"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            dragProps,
                                        }) => (
                                            <div>


                                                <div className={'upload-gallery'} onClick={onImageUpload} {...dragProps}>
                                                    <IoMdCloudUpload className={'top-icon'} fontSize={19} />
                                                    <div>
                                                        <label><h6>UPLOAD GALLERY</h6></label>
                                                    </div>
                                                </div>
                                                <div className={'gallery'}>
                                                    {imageList?.map((image, index) => (
                                                        <div key={index} className={'gallery-container'}>
                                                            <img alt={'Thumbnail'} src={image['url']} />
                                                            <div className={'update-delete'}>
                                                                <IoCloseCircle onClick={() => onDeleteImage(image?.id, i)} className={'delete'} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                                <div style={{ display: 'flex', gap: "20px" }} className='input-container'>
                                    <div>
                                        <h6>Slug Url</h6>
                                        <input name={'name'}
                                            onChange={(e) => varientOnchange('urlSlug', e.target.value, i)}
                                            value={val?.displayValues?.urlSlug}
                                        />
                                    </div>
                                    <div>
                                        <h6>Total Stock</h6>
                                        <input name={'name'}
                                            value={val?.displayValues?.totalStocks}
                                            onChange={(e) => varientOnchange('totalStocks', e.target.value, i)}
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                        <div>
                                            <h6>Discount</h6>
                                            <input name={'name'}
                                                value={val?.displayValues?.discount}
                                                onChange={(e) => onDiscountChange('discount', e.target.value, i)}
                                                type='number'
                                            />
                                        </div>
                                        <div>
                                            <h6>Sales Price</h6>
                                            <input name={'name'}
                                                value={val?.displayValues?.salesPrice}
                                                onChange={(e) => onSalesChange('salesPrice', e.target.value, i)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>}


        </div >
    )
}

export default VarientPage