import React, { useEffect, useState } from 'react';
import ProductsTable from "./sections/ProductsTable";
import { makeStyles } from "@material-ui/core";
import ProductFilter from "./sections/ProductFilter";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { setProduct } from 'components/redux/slice/ProductSlice';
import connection from '../auth/api';

const Products = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))
    const classes = useStyles()
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [dbProducts, setDbProducts] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);

    const dispatch = useDispatch()
    useEffect(() => {
        fetchProduct()
    }, [currentPage, limit, filteredData])
    const fetchProduct = async () => {
        setLoading(true)
        try {
            await connection.post(`/post/getProducts?page=${currentPage}&limit=${limit}`).then(res => {
                dispatch(setProduct(res.data.data.Response.Data.ProductDetails.Products))
                setLoading(false)
            })
        } catch (e) {
            setLoading(false)
            console.log(e.message)
        }

    }

    const tableProps = {
        filteredData,
        dbProducts,
        currentPage,
        setCurrentPage,
        limit,
        setLimit,
        setLoading
    }

    return (
        <div className={classes.container}>
            <ProductFilter setFilteredData={setFilteredData} setLoading={setLoading} loading={loading} />
            <ProductsTable {...tableProps} />
            <FullScreenProgress setOpen={setLoading} open={loading} />
        </div>
    );
};

export default Products;
