import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import AddedProductsFilter from "./section/AddedProductsFilter";
import AddedProductsTable from "./section/AddedProductsTable";
import axios from "axios";
import { pathOr } from 'ramda';
import connection from '../auth/api';

const AddedProducts = () => {

    const useStyles = makeStyles(() => ({
        container: {
            margin: '20px 0'
        }
    }))
    const classes = useStyles()
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [products, setProducts] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [dealsOfTheDayProducts, setDealsOfTheDayProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterData, setFilterData] = useState('')

    useEffect(() => {
        if (filterData == '') {
            setLoading(true)
            Promise.all([getAllProducts(), getAllDealsOfTheDayProducts()]).then(results => {
                setProducts(pathOr([], ['data'], results[0].data))
                setProductCount(pathOr(0, ['data', 'metadata', '0', 'total'], results[0]))
                setDealsOfTheDayProducts(results[1].data.data)
                setLoading(false)
            })
        } else {
            setLoading(true)
            Promise.all([getLowStockProducts(), getAllDealsOfTheDayProducts()]).then(results => {
                setProducts(results[0].data.data)
                setProductCount(pathOr(0, ['data', 'metadata', '0', 'total'], results[0]))
                setDealsOfTheDayProducts(results[1].data.data)
                setLoading(false)
            })
        }
    }, [currentPage, limit, searchValue, filterData])

    function getLowStockProducts() {
        const token = localStorage.getItem('Access_Token')
        return connection.get(`/get/lowStockProducts?currentPage=${currentPage}&limit=${limit}&searchText=${searchValue}`,
            {
                headers: {
                    Authorization: `JWT ${token}`
                }
            }
        );
    }

    function getAllProducts() {
        return connection.get(`/get/allProducts?page=${currentPage}&limit=${limit}&searchText=${searchValue}`);
    }

    function getAllDealsOfTheDayProducts() {
        return connection.get('/get/dealsOfTheDay');
    }

    return (
        <div className={classes.container}>
            <AddedProductsFilter setSearchValue={setSearchValue} setCurrentPage={setCurrentPage} setFilteredData={setFilterData} />
            <AddedProductsTable filteredData={filteredData}
                setFilteredData={setFilteredData}
                products={products} setProducts={setProducts}
                dealsOfTheDayProducts={dealsOfTheDayProducts}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                productCount={productCount}
                setProductCount={setProductCount}
                searchValue={searchValue}
                setLimit={setLimit}
                setDealsOfTheDayProducts={setDealsOfTheDayProducts} />
            <FullScreenProgress open={loading} setOpen={setLoading} />
        </div>
    );
};

export default AddedProducts;
