import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { ImSearch, IoMdClose, IoMdDocument } from "react-icons/all";
import { Colors } from "../../../helpers/Color";
import CustomToast from "../../../helpers/Toast";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import connection from 'components/app/auth/api';

const deliveryStatus = [
    {
        name: 'Low Stock',
        color: '#127500'
    },
]

const AddedProductsFilter = ({ setSearchValue, setCurrentPage, setFilteredData }) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState('')
    const [importMdl, setImportMdl] = useState(false)

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 25px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .search-bar': {
                display: 'flex',
                width: is430pxBelow ? '100%' : '50%',
                margin: is430pxBelow ? '0 0 15px' : '0',
                alignItems: 'center',
                border: `1px solid ${Colors.primary}`,
                borderRadius: '5px',
                padding: '10px',
                background: Colors.primaryLight,
                '& .icon': {
                    fontSize: '22px',
                    color: Colors.darkText
                },
                '& input': {
                    width: '100%',
                    '&:focus': {
                        outline: 0
                    },
                    margin: '0 10px',
                    border: `0`,
                    background: Colors.primaryLight,
                }
            },
            '& .create-product-btn': {
                outline: 0,
                background: Colors.primary,
                border: 0,
                color: Colors.light,
                borderRadius: 5,
                padding: '5px 10px',
                margin: '15px 0 0',
                fontSize: '14px'
            },
            '& .date-picker': {
                border: `1px solid ${Colors.primary}`,
                padding: '0 10px',
                background: Colors.primaryLight,
                borderRadius: '5px',
                '& .MuiIconButton-root': {
                    padding: '0 !important'
                },
                '& .MuiFormControl-marginNormal': {
                    marginTop: is430pxBelow ? '4px !important' : '6px !important',
                    marginBottom: is430pxBelow ? '4px !important' : '6px !important',
                }
            }
        },
        buttonContainer: {
            display: 'flex',
            margin: '10px 0 25px',
            // width: '50%',
            '& button': {
                borderRadius: '5px',
                marginRight: '8px',
                cursor: 'pointer',
                fontSize: '14px',
                padding: '8px',
                border: 0,
                '&:focus': {
                    outline: 0
                },
                background: Colors.primary,
                color: Colors.light,
                // padding: '8px',
                width: '120px'
            }
        },
    }))

    const classes = useStyles()


    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openMdl, setOpenMdl] = useState(false)
    const [UploadFile, setUploadFile] = useState([])
    const [openToast, setOpenToast] = useState(false)
    const [toastMsg, setToastMsg] = useState('')

    const token = localStorage.getItem('Access_Token')

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(searchTerm)
            setCurrentPage(1)
        }
    }

    const onClearClick = () => {
        setSearchTerm('')
        setSearchValue('')
        setCurrentPage(1)
    }

    const downloadCSVStandard = () => {
        const csvContent = generateCSV();
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'data.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    };

    const downloadCSVVariant = () => {
        const csvContent = generateCSVVariant();
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'data.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const handleClose = () => {
        setOpenMdl(false)
        setUploadFile([])
    }


    const onChange = (e) => {
        setUploadFile(e.target.files)
    }

    const handleSubmit = () => {

        const form = new FormData()
        UploadFile[0] && form.append("file", UploadFile[0])
        connection.post(`/post/bulkImport`, form)
            .then(res => {
                setToastMsg(res?.data?.message)
                setOpenToast(true)
                handleClose()
            })

    }

    const handleExport = () => {
        connection.get(`/get/bulkExport`, {
            headers: {
                Authorization: `JWT ${token}`
            }
        })
            .then(res => {
                setToastMsg(res?.data?.message)
                setOpenToast(true)
            })
    }


    return (
        <div>
            <div className={classes.container}>
                <div className={'search-bar'}>
                    <ImSearch className={'icon'} />
                    <input onChange={e => setSearchTerm(e.target.value)} value={searchTerm} onKeyDown={handleKeyDown} placeholder={'Type And Enter'} />
                    <IoMdClose className={'icon'} onClick={onClearClick} />
                </div>
                <div style={{ display: 'flex', gap: '15px' }}>
                    <button onClick={() => { history.push('/add-product/new') }} className={'create-product-btn'}> Create + </button>
                    <button onClick={() => setOpenMdl(true)} className={'create-product-btn btnColor'}>Import Products</button>
                    <button onClick={handleExport} className={'create-product-btn'}>Export Products</button>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <button onClick={() => setFilteredData('')}>All</button>
                {
                    deliveryStatus.map(d => (
                        <button onClick={() => setFilteredData(d.name)} key={d.name} style={{ background: d.color }}>{d.name}</button>
                    ))
                }
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage} />

            <Dialog
                open={openMdl}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Import Products
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', gap: '25px', alignItems: 'center' }}>
                        <Typography>Do You Want Sample File?</Typography>
                        <Button onClick={downloadCSVStandard} variant='contained' color='primary'>Standard</Button>
                        <Button onClick={downloadCSVVariant} variant='contained' color='primary'>Variant</Button>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <Typography>Import Products:</Typography>
                        <Button variant="contained" color='primary' fullWidth component="label">
                            Import
                            <input hidden type="file" accept=".csv" onChange={(e) => onChange(e)} />
                        </Button>
                        {UploadFile.length > 0 &&

                            <div style={{ display: 'flex', justifyContent: "space-between", border: '1px solid #EBEEF5', padding: '10px', borderRadius: '8px', marginTop: '5px' }}>
                                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                    <IoMdDocument color='#2086CF' fontSize='20px' />
                                    <Typography color={'#5369f8'}>{UploadFile[0].name}</Typography>
                                </div>

                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: '30px' }}>
                    <Button onClick={handleClose} variant='outlined' color='primary'>Close</Button>
                    <Button onClick={handleSubmit} variant='contained' color='primary' >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomToast type={'success'} openToast={openToast} setOpenToast={setOpenToast} message={toastMsg} />

        </div>
    );
};

export default AddedProductsFilter;


const generateCSV = () => {
    const data = [
        [
            'id',
            'name',
            'description',
            'urlSlug',
            'brand',
            'manufacturerName',
            'thumbnailUrl',
            'galleryImages',
            'isColdChainNeeded',
            'isPrescriptionNeeded',
            'saltComposition',
            'tags',
            'group',
            'isGeneralProduct',
            'generalCategory',
            'generalSubCategory',
            'specialityCategory',
            'specialitySubCategory',
            'purchasedPrice',
            'discount',
            'packageSize',
            'salesPrice',
            'totalStocks',
            'stockAlertThreshold',
            'pair',
            'shortListDescription',
            'longListDescription',
            'instructions',
            'sideEffects',
            'metaTitle',
            'metaDescription',
            'metaAltText',
            'keywords',
            'productType',
            'departmentName',
            'isEnabled'
        ],
        [
            '1',
            '6MP 50MG TAB 10 S',
            '6MP 50MG TAB 10 S',
            '6MP-50MG-TAB-10-S',
            'accuCheck',
            'BioTech',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'FALSE',
            'TRUE',
            'Mercaptopurine(50mg)',
            '',
            'Diabetes',
            'TRUE',
            'HEALTHCARE DEVICES',
            'GLUCOMETER',
            'HIV or AIDS',
            '',
            '72.5',
            '37.93',
            'pack of 10',
            '45',
            '225',
            '3',
            '',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer. Blood cancer also known as leukemia, is the cancer of blood cells. It causes excessive production of immature white blood cells by the bone marrow.',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            '',
            '',
            '',
            'standard',
            'medicine',
            'true'
        ],
        [
            '2',
            '6MP 50MG TAB 10 S',
            '6MP 50MG TAB 10 S',
            '6MP-50MG-TAB-10-S',
            'accuCheck',
            'BioTech',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'FALSE',
            'TRUE',
            'Mercaptopurine(50mg)',
            '',
            'Diabetes',
            'TRUE',
            'HEALTHCARE DEVICES',
            'GLUCOMETER',
            'HIV or AIDS',
            '',
            '72.5',
            '37.93',
            'pack of 10',
            '45',
            '225',
            '3',
            '',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer. Blood cancer also known as leukemia, is the cancer of blood cells. It causes excessive production of immature white blood cells by the bone marrow.',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            '',
            '',
            '',
            'standard',
            'medicine',
            'true'
        ],
        // Add more data rows as needed
    ];

    // Convert data to CSV format
    const csvContent = data.map((row) => row.join(',')).join('\n');

    return csvContent;
};

const generateCSVVariant = () => {
    const data = [
        [
            '_id',
            'name',
            'description',
            'urlSlug',
            'brand',
            'manufacturerName',
            'thumbnailUrl',
            'galleryImages',
            'isColdChainNeeded',
            'isPrescriptionNeeded',
            'saltComposition',
            'tags',
            'group',
            'isGeneralProduct',
            'generalCategory',
            'generalSubCategory',
            'specialityCategory',
            'specialitySubCategory',
            'purchasedPrice',
            'discount',
            'packageSize',
            'salesPrice',
            'totalStocks',
            'stockAlertThreshold',
            'pair',
            'shortListDescription',
            'longListDescription',
            'instructions',
            'sideEffects',
            'metaTitle',
            'metaDescription',
            'metaAltText',
            'keywords',
            'productType',
            'primaryType',
            'primaryValue',
            'secondaryType',
            'secondaryValue',
            'departmentName',
            'isEnabled'
        ],
        [
            '1',
            'test medician',
            '6MP 50MG TAB 10 S',
            '6MP-50MG-TAB-10-S',
            'accuCheck',
            'BioTech',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'https://assets.etabx.com/images/products/1642406832301-1642406832301.jpg',
            'FALSE',
            'TRUE',
            'Mercaptopurine(50mg)',
            '',
            'Diabetes',
            'TRUE',
            'HEALTHCARE DEVICES',
            'GLUCOMETER',
            'HIV or AIDS',
            '',
            '72.5',
            '37.93',
            'pack of 10',
            '45',
            '225',
            '3',
            '',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer. Blood cancer also known as leukemia, is the cancer of blood cells. It causes excessive production of immature white blood cells by the bone marrow.',
            '6-MP Tablet 10s belongs to the class of medication called anticancer drugs used in the treatment of blood cancer.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            '',
            '',
            '',
            'variant',
            '',
            '',
            '',
            '',
            'medicine',
            'true'
        ],
        [
            '1',
            '6MP small white',
            '6MP 50MG TAB 10 S small white',
            '6mp-small-white',
            '',
            '',
            'https://assets.etabx.com/images/products/1681808727650-1681808727650.jpg',
            'https://assets.etabx.com/images/products/1681728073375-1681728073375.jpg',
            '',
            '',
            'Mercaptopurine(50mg)',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '60',
            '175',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'variant',
            'size',
            'small',
            'color',
            'white',
            '',
            'true'
        ],
        [
            '1',
            '6MP small dark',
            '6MP 50MG TAB 10 S small dark',
            '6mp-small-dark',
            '',
            '',
            'https://assets.etabx.com/images/products/1675863978022-1675863978023.jpg',
            'https://assets.etabx.com/images/products/1675834561169-1675834561169.jpg',
            '',
            '',
            'Mercaptopurine(50mg)',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '50',
            '200',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'variant',
            'size',
            'small',
            'color',
            'dark',
            '',
            'true'
        ],
        [
            '1',
            '6MP medium white',
            '6MP 50MG TAB 10 S medium white',
            '6mp-medium-white',
            '',
            '',
            'https://assets.etabx.com/images/products/1678450168534-1678450168534.jpg',
            'https://assets.etabx.com/images/products/1679816163158-1679816163158.jpg',
            '',
            '',
            'Mercaptopurine(50mg)',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '60',
            '100',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'variant',
            'size',
            'medium',
            'color',
            'white',
            '',
            'true'
        ],
        [
            '1',
            '6MP medium dark',
            '6MP 50MG TAB 10 S medium dark',
            '6mp-medium-dark',
            '',
            '',
            'https://assets.etabx.com/images/products/1679727825491-1679727825491.jpg',
            'https://assets.etabx.com/images/products/1679727825491-1679727825491.jpg',
            '',
            '',
            'Mercaptopurine(50mg)',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '60',
            '100',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'variant',
            'size',
            'medium',
            'color',
            'dark',
            '',
            'true'
        ]
    ];
    const csvContent = data.map((row) => row.join(',')).join('\n');

    return csvContent;
}