import React, {useState} from 'react';
import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../helpers/Color";
import {useHistory} from "react-router-dom";
import {ImSearch, IoMdClose} from "react-icons/all";
import CustomToast from "../../../helpers/Toast";

const SpecialityCategoriesHeader = ({setFilteredData, categories}) => {

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 25px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .search-bar':{
                display: 'flex',
                width: is430pxBelow ? '100%' : '50%',
                margin: is430pxBelow ? '0 0 15px' : '0',
                alignItems: 'center',
                border: `1px solid ${Colors.primary}`,
                borderRadius: '5px',
                padding: '10px',
                background: Colors.primaryLight,
                '& .icon':{
                    fontSize: '22px',
                    color: Colors.darkText
                },
                '& input':{
                    width: '100%',
                    '&:focus':{
                        outline: 0
                    },
                    margin: '0 10px',
                    border: `0`,
                    background: Colors.primaryLight,
                }
            },
            '& .add-button':{
                background: Colors.primary,
                color: Colors.light,
                padding: '7px 15px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '5px'
            }
        }
    }))

    const classes = useStyles()
    const [searchValue, setSearchValue] = useState('');

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const filteredData = categories.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            if (filteredData.length === 0){
                setErrorMessage('Item Not Found')
                setFilteredData([]);
                setOpenError(true)
            }else {
                setFilteredData(filteredData);
            }
        }
    }

    const onClearClick = () => {
        setFilteredData([])
        setSearchValue('')
    }

    const history = useHistory();

    return (
        <div>
            <div className={classes.container}>
                <div className={'search-bar'}>
                    <ImSearch className={'icon'}/>
                    <input onChange={e => setSearchValue(e.target.value)} value={searchValue} onKeyDown={handleKeyDown} placeholder={'Type And Enter'}/>
                    <IoMdClose className={'icon'} onClick={onClearClick}/>
                </div>
                <div className={'add-button'} onClick={() => history.push('/speciality-categories/add-category')}>
                    Add Category
                </div>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage}/>
        </div>
    );
};

export default SpecialityCategoriesHeader;
